import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles({
    fullpage : {
        margin: '0px',
        backgroundColor : '#806BD0',
        height :'100vh',
        width:'100%',
        overflow:'hidden',
        position : 'fixed',
    },
    
    design : { 
        display: 'inline',
        float: 'right',
        width: '400px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '60px',
        position: 'absolute',
        top : '0px',
        right: '0px',
        borderRadius: '60px 0px 0px 60px'
    },
    design2 : { 
        display: 'inline',
        float: 'right',
        width: '200px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '40px',
        position: 'absolute',
        top: '30px',
        borderRadius: '0px 20px 20px 0px'
    },
    heading: {
        textAlign:'center',
        color:'white',
        fontSize:'4vw',
        fontWeight:'bold',
        fontStyle:'spartan',
        marginTop: '10px',
        padding: '15px',
        fontFamily:'spartan'
    },
    heading2:{
        textAlign:'center',
        color:'white',
        // fontSize:'3vw',
        fontWeight:'bold',
        marginBottom: '10px',
        padding: '15px',
        width:'50%',
        margin:'auto',
        
    },
    bottomline:{
        color: 'white',
        textAlign: 'center',
        padding : '10px',
        marginTop: '10px',
        marginBottom: '10px'
        
    },
    bottomline2:{
        color: 'white',
        textAlign: 'center',
        padding : '10px',
        width:'50%',
        margin:'auto',
        
    },

    nextbtn : {
        width : '35%',
        height : '60px',
        backgroundColor : '#8DFFCE',
        textAlign : 'center',
        border : 'none',
        fontSize :'2.0vw',
        fontWeight :'bold',
        cursor : 'pointer',
        fontFamily:'spartan',
        outline:'none'
    },

    quarter:{
        float:'right',
        width:'150px',
        height:'150px',
        borderTop:'40px solid #AB9FE1',
        borderLeft:'40px solid #AB9FE1',
        borderRadius:'100% 0 0 0',
    },

    btnDiv:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'20px'
    },
    modalbtns:{
        margin:'10px',
        padding:'20px',
        width:'25%',
        color:'black',
        backgroundColor:'#8DFFCE',
        border:'none',
        outline:'none',
        cursor:'pointer',
        fontSize:'1.5vw',
        fontFamily:'spartan'
    },
    '@media(min-width: 1000px) and (max-width: 1200px)': {
        fullpage: {
            overflow: 'scroll',
            marginTop: '0px',
        },
        design: {
            width: '20%',
            height: '40px',
            opacity : '0.5'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.5',
        },
    },

    '@media(min-width: 700px) and (max-width: 1000px)' : {
        fullpage :{
            overflow: 'scroll',
            marginTop: '0px'
        },
        design: {
            width: '25%',
            height: '40px',
            opacity : '0.5'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.5',
        },
    },

    '@media(max-width: 700px)' : {
        fullpage :{
            overflow: 'scroll',
            marginTop: '0'
        },
        design: {
            width: '8%',
            height: '40px',
            opacity : '0.2'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.2',
        },
        nextbtn : {
            fontSize : '4vw',
            width:'80%'
        },
        heading : {
            marginTop: '20px',
            fontSize : '8vw'
        },
        bottomline : {
            fontSize : '4vw'
        },
        bottomline2 : {
            fontSize : '4vw',
            width:'80%'
        },
        heading2:{
            width:'90%',
            fontSize:'4.5vw'
        },
        modalbtns:{
            fontSize:'3vw'
        }
    }
})

export {useStyles}
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  // InputBase,
  // Card,
  // CardMedia,
  // TextareaAutosize,
} from "@material-ui/core";
import axios from "axios";
import Video from "./video";
import { CircularProgress } from "@mui/material";

const nl2br = require("react-nl2br");
const DOMAIN = process.env.REACT_APP_DOMAIN;

const Courses = () => {
  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(false);

  // const [imgCollection, setImgCollection] = useState(null);

  const [img1, setImage1] = useState("");
  const [img2, setImage2] = useState("");
  const [img3, setImage3] = useState("");
  const [img4, setImage4] = useState("");

  // const [title, setTitle] = useState("");
  // const [subTitle, setSubTitle] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [description, setDescription] = useState("");
  // const [poster, setPoster] = useState(null);
  const [previewLink, setPreviewLink] = useState("");
  const [enrollLink, setEnrollLink] = useState("");

  const result = nl2br(description);
  console.log(result[0]);

  // const [data1, setData1] = useState("");
  // const [data2, setData2] = useState("");
  // const [data3, setData3] = useState("");
  // const [data4, setData4] = useState("");

  // const [edit, setEdit] = useState(false);

  const [testimonials, setTestimonials] = useState([]);

  // Course request
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${DOMAIN}/courses/63045711d1e7668480fdc568`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then(async (res) => {
        setCourse(res.data);
        // setTitle(res.data.title);
        // setSubTitle(res.data.subTitle);
        setVideoLink(res.data.videoLink);
        setPreviewLink(res.data.previewLink);
        setEnrollLink(res.data.enrollLink);
        // setData1(res.data.data1);
        // setData2(res.data.data2);
        // setData3(res.data.data3);
        // setData4(res.data.data4);
        setDescription(res.data.description);
        // await setImgCollection(res.data.imgCollection);

        if (res.data.imgCollection.length <= 4) {
          await setImage1(res.data.imgCollection[0].name);
          await setImage2(res.data.imgCollection[1].name);
          await setImage3(res.data.imgCollection[2].name);
          await setImage4(res.data.imgCollection[3].name);
        } else {
          // await setPoster(res.data.imgCollection[0].name);
          await setImage1(res.data.imgCollection[1].name);
          await setImage2(res.data.imgCollection[2].name);
          await setImage3(res.data.imgCollection[3].name);
          await setImage4(res.data.imgCollection[4].name);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // ALl Testimonials request
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${DOMAIN}/course/testimonial/`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTestimonials(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(course);
  return (
    <div style={container}>
      {loading ? (
        <CircularProgress style={loader} size={45} />
      ) : (
        <Box>
          <Box>
            <Typography style={heading}>{course.title}</Typography>
          </Box>
          <Box>
            <Typography style={{ fontSize: 20 }}>{course.subTitle}</Typography>
          </Box>
          <Video link={videoLink} />

          <Box display="flex" justifyContent="center" marginTop="10px">
            <Button size="large">
              <a
                href={previewLink}
                target="_blank"
                rel="noopener noreferrer"
                style={previewBtn}
                // className="navbar_item"
              >
                About the Speaker
              </a>
            </Button>
            <Button size="large">
              <a
                href={enrollLink}
                target="_blank"
                rel="noopener noreferrer"
                style={enrollBtn}
                // className="navbar_item"
              >
                Link
              </a>
            </Button>
          </Box>

          <Box display="flex" justifyContent="center" marginTop="10px">
            <Box style={downbox}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  <img
                    src={`${DOMAIN}/courses/${img1}`}
                    alt=".."
                    style={iconsImage}
                  ></img>
                  <Typography style={text}>{course.data1}</Typography>
                </Box>

                <Box display="flex">
                  <img
                    src={`${DOMAIN}/courses/${img2}`}
                    alt=".."
                    style={iconsImage}
                  ></img>
                  <Typography style={text}>{course.data2}</Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  <img
                    src={`${DOMAIN}/courses/${img3}`}
                    alt=".."
                    style={iconsImage}
                  ></img>
                  <Typography style={text}>{course.data3}</Typography>
                </Box>

                <Box display="flex">
                  <img
                    src={`${DOMAIN}/courses/${img4}`}
                    alt=".."
                    style={iconsImage}
                  ></img>
                  <Typography style={text}>{course.data4}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" marginTop={5}>
            <Typography variant="h5" fontWeight="bold">
              DESCRIPTION:-
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            marginTop={1}
            textAlign="left"
          >
            <Typography fontSize="25px">{nl2br(course.description)}</Typography>
          </Box>

          <Box marginTop="5px" marginBottom="15px">
            <Box
              display="flex"
              justifyContent="space-between"
              textAlign="left"
              marginTop={2}
            >
              <Typography variant="h5" fontWeight="bold">
                WHAT OUR COMMUNITY IS SAYING :-
              </Typography>
            </Box>
            {testimonials.map((t) => {
              return (
                <Box style={testimonialBox}>
                  <Box>
                    <img
                      src={`${DOMAIN}/courses/${t.avtar.name}`}
                      alt="..."
                      style={avtarImg}
                    ></img>
                  </Box>
                  <Box style={testimonial}>
                    <Typography fontSize="20px">{t.testimonial}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </div>
  );
};

const loader = {
  // backgroundColor: 'rgba(52, 52, 52, 0.8)',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
};

const container = {
  padding: "10px",
  backgroundColor: "#000",
  color: "#fff",
  textAlign: "center",
  minHeight: "100vh",
};

const inputs = {
  backgroundColor: "#ddd",
  color: "#000",
  width: 300,
  margin: "10px",
};

const text = {
  color: "#fff",
  fontSize: "14px",
  fontWeight: "bold",
  paddingRight: "5px",
  marginTop: "auto",
  marginBottom: "auto",
};

const previewBtn = {
  backgroundColor: "#7424BB",
  padding: "5px",
  margin: "5px",
  color: "#fff",
  width: 150,
  textDecoration: "none",
};

const enrollBtn = {
  backgroundColor: "#EDC10C",
  padding: "5px",
  margin: "5px",
  width: 150,
  textDecoration: "none",
};

const heading = {
  fontSize: 40,
  fontWeight: 600,
};

const downbox = {
  // backgroundColor: '#b2c2db',
  backgroundColor: "#6A58c6",
  border: "none",
  borderRadius: "10px",
  justifyContent: "center",
  flexDirection: "row",
  width: 500,
  padding: 15,
  color: "#000",
  margin: "10px",
};

const avtarImg = {
  width: "35px",
  objectFit: "cover",
  marginRight: "5px",
};

const iconsImage = {
  width: "35px",
  objectFit: "cover",
  marginTop: "auto",
  marginBottom: "auto",
};

const testimonialBox = {
  display: "flex",
  margin: "10px",
  borderRadius: "10px",
};

const testimonial = {
  backgroundColor: "#6A58c6",
  width: "90%",
  borderLeft: "3px solid white",
  // margin:'15px',
  textAlign: "left",
  paddingLeft: "5px",
  color: "#eee",
  whiteSpace: "normal",
  // padding: '5px',
};

export default Courses;

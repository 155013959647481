import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    color: "black",
  },
  below_div: {
    display: "flex",
    flexDirection: "column",
    // boxShadow:'rgba(56, 32, 32, 0.35) 0px 5px 15px',
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  below_btn_div: {
    width: "100%",
    display: "flex",
    marginTop: "5px",
    justifyContent: "center",
    alignItems: "center",
    // border : '1px solid black'
  },
  below_btn: {
    width: "95%",
    padding: "8px",
    margin: "5px",
    background: "linear-gradient(to top left, #52efda 0%, #8dffcc 100%)",
    border: "none",
    cursor: "pointer",
    outline: "none",
    fontFamily: "spartan",
    fontSize: "4vw",
    color: "black",
    // border : '1px solid blue',
    "&:hover": {
      boxShadow: "rgba(56, 32, 32, 0.35) 0px 5px 15px",
    },
  },
  below_btn_link: {
    // border : '1px solid red',
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    alignItems: "center",
  },
  announcement_div: {
    color: "black",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});
const drawerBleeding = 5;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const id = window.localStorage.getItem("id");
// console.log(`id is ${id}`)
function UpwardDrawer(props) {
  const classes = useStyles();
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <Box
        sx={{
          textAlign: "center",
          pt: 1,
          backgroundColor: "black",
          color: "white",
        }}
      >
        <ExpandLessIcon
          onClick={toggleDrawer(true)}
          style={{
            color: "#52efda",
            height: "46px",
            width: "46px",
            marginTop: "-10px",
          }}
        />
      </Box>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            right: 0,
            left: 0,
            visibility: "visible",
          }}
        >
          <Puller />
          {/* <Typography sx={{ p: 3.5, color: 'text.secondary' }}></Typography> */}
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <div className={classes.below_div}>
            <div className={classes.below_btn_div}>
              <Link to="/" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Home
                </button>
              </Link>
              <Link to={`/profile/${id}`} className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  My Profile
                </button>
              </Link>
            </div>

            <div className={classes.below_btn_div}>
              <Link to="/willcomesoon" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Message
                </button>
              </Link>
              <Link to="/willcomesoon" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Notification
                </button>
              </Link>
            </div>

            <div className={classes.below_btn_div}>
              <Link to="/willcomesoon" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Experience
                </button>
              </Link>
              <Link to="/willcomesoon" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  My Applications
                </button>
              </Link>
            </div>

            <div className={classes.below_btn_div}>
              <Link to="/willcomesoon" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Ideations
                </button>
              </Link>
              <Link
                to={`getcertificategenerate_certificate/:${id}`}
                className={classes.below_btn_link}
              >
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Community
                </button>
              </Link>
            </div>
            <div className={classes.below_btn_div}>
              <div className={classes.below_btn_link}>
                <a
                  href="https://calendly.com/markoknow_/30-min?month=2022-07"
                  className={classes.below_btn_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={classes.below_btn}>Book Mentorship</div>
                </a>
              </div>
              <Link to="/member" className={classes.below_btn_link}>
                <button
                  className={classes.below_btn}
                  onClick={() => setOpen(!open)}
                >
                  Membership
                </button>
              </Link>
            </div>
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

export default UpwardDrawer;

import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormGroup } from "@mui/material";
// import { FormControlLabel, FormLabel } from "@mui/material";
import { InputBase } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import Checkbox from '@mui/material/Checkbox';
import { CircularProgress } from "@mui/material";
// import ErrorIcon from '@mui/icons-material/Error';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from "axios";
// import { Modal } from '@material-ui/core';
import MessagePopup from "../MessagePopup";
import { validateEmail } from "../auth/validateEmail";

const DOMAIN = process.env.REACT_APP_DOMAIN;

// const Membership = ({ isAuth }) => {
const Membership = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [designation, setDesignation] = useState("");

  const [resp, setResp] = useState("");
  const [loading, setLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  // const [success, setSuccess] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [designationError, setDesignationError] = useState("");

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!contactNo) {
      setContactNoError("");
    } else {
      if (contactNo.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [contactNo]);

  useEffect(() => {
    if (!designation) {
      setDesignationError("");
    } else {
      if (
        designation === "Student" ||
        designation === "Proffesional" ||
        designation === "Other"
      ) {
        setDesignationError("");
      } else {
        setDesignationError("Please enter only Student / Proffesional / Other");
      }
    }
  }, [designation]);

  const handleAddMember = async (req, res) => {
    if (!name || !email || !contactNo || !designation) {
      setResp("All fields are required");
      setOpenPopUp(true);
    } else if (emailError.length > 0) {
      setResp(emailError);
      setOpenPopUp(true);
    } else if (contactNoError.length > 0) {
      setResp(contactNoError);
      setOpenPopUp(true);
    } else if (designationError.length > 0) {
      setResp(designationError);
      setOpenPopUp(true);
    } else {
      const data = {
        name,
        email,
        contactNo,
        designation,
      };
      console.log(data);
      setLoading(true);
      axios
        .post(`${DOMAIN}/new/membership`, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // window.alert(res.data.message);
          setResp(res.data.message);
          setOpenPopUp(true);
          // setSuccess(true);
          setName("");
          setEmail("");
          setDesignation("");
          setContactNo("");
          setLoading(false);
        })
        .catch((err) => {
          // window.alert(err);
          setResp("some error occured");
          setOpenPopUp(true);
          setLoading(false);
        });
    }
  };

  return (
    <Box style={fullPage}>
      {loading ? (
        <CircularProgress style={loader} size={45} />
      ) : (
        <Box width={450}>
          <Box display="flex" justifyContent="center">
            <Typography
              variant="h5"
              component="h2"
              fontWeight="650"
              color="#fff"
            >
              Apply for Membership
            </Typography>
            <Box marginTop="auto" marginLeft="5px">
              <ArrowForwardIcon style={{ color: "#68F5D4" }} />
            </Box>
          </Box>
          <Box sx={style}>
            <FormGroup>
              <InputBase
                placeholder="Full Name"
                style={inputs}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span className="error">{emailError}</span>
              <InputBase
                placeholder="Email"
                style={inputs}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="error">{contactNoError}</span>
              <InputBase
                placeholder="Contact no."
                style={inputs}
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
              />
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="h5"
                  component="h5"
                  fontWeight="650"
                  color="#fff"
                >
                  You are
                </Typography>
              </Box>
              <span className="error">{designationError}</span>
              <InputBase
                placeholder="Student/Proffesional/other"
                style={inputs}
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </FormGroup>
            <Box display="flex" justifyContent="center">
              <Button style={btn} size="large" onClick={handleAddMember}>
                Submit
              </Button>
            </Box>
          </Box>
          <MessagePopup
            open={openPopUp}
            message={resp}
            handleAlertClose={() => setOpenPopUp(!openPopUp)}
          />
        </Box>
      )}
    </Box>
  );
};

const style = {
  border: "none",
  boxShadow: 25,
  textAlign: "center",
  color: "black",
  boxSizing: "content-box",
  background: "#000",
  p: 2,
  borderRadius: 7,
};

// const stylePopup = {
//   display: "flex",
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   bgcolor: "background.paper",
//   border: "none",
//   borderRadius: "5px",
//   boxShadow: 25,
//   textAlign: "center",
//   color: "black",
//   p: 4,
// };

const inputs = {
  padding: 8,
  marginTop: 7,
  marginBottom: 7,
  borderRadius: 5,
  background: "#eee",
  border: "1px solid #aaa",
};

const fullPage = {
  width: "100%",
  height: "100vh",
  backgroundColor: "black",
  display: "flex",
  justifyContent: "center",
  paddingTop: "2rem",
};

const btn = {
  color: "#000",
  background: "#68F5D4",
  marginTop: "10px",
  fontWeight: "650",
};

const loader = {
  // backgroundColor: 'rgba(52, 52, 52, 0.8)',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
};
export default Membership;

import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  Box,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Contact from "../Components/Contact";

const useStyles = makeStyles({
  container: {
    paddingRight: 0,
    borderRight: "0.1px solid",
    height: "100vh",
  },
  searchbox: {
    width: "95%",
    border: "none",
    margin: 5,
    backgroundColor: "#E8E8E8",
    borderRadius: "10rem",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
  },
  contactbox: {
    width: "100%",
    overflowY: "scroll",
    height: "100vh",
  },
});

const ChatContacts = ({
  recentChat,
  setConversationId,
  setOppuser,
  setCheckchat,
}) => {
  const classes = useStyles();
  const [searchterm,setSearchterm]=useState("");
  console.log(searchterm);

  return (
    <Container className={classes.container}>
      <Typography variant="h4">Messages</Typography>
      <TextField
        placeholder="Search"
        className={classes.searchbox}
        onChange={(e)=>setSearchterm(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <Box className={classes.contactbox}>
        {recentChat.filter((val)=>{
          console.log(val.members[0].name);
          console.log("ahhahaa")
          if(searchterm==""){
            return val
          }else if(val.members[0].name.toLowerCase().includes(searchterm.toLowerCase())){
            return val
          }
        }).map((conv) => {
          //console.log(conv);
          return (
            <Contact
              key={conv._id}
              conv={conv}
              setConversationId={setConversationId}
              setOppuser={setOppuser}
              setCheckchat={setCheckchat}
            />
          );
          })}
      </Box>
    </Container>
  );
};

export default ChatContacts;

import React, { useState } from "react";
import axios from "axios";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import SampleIdeation from "../../images/Sample.png";
import ShareIcon from "../../images/share_icon.png";
// import CommentIcon from "../../images/feedback_icon.png";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "../../stylesheets/questionaire.css";
import Grid from "@material-ui/core/Grid";
import { Box, Button } from "@material-ui/core";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  left_grid: {
    ["@media only screen and (max-width: 420px)"]: {
      width: "100%",
    },
  },
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 250,
      marginLeft: 0, // secondary
    },
  },
  sample: {
    position: "absolute",
    width: "80%",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 250,
      marginLeft: 0, // secondary
    },
  },
  btnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'red',
  },
  btn: {
    backgroundColor: "#8dffcc",
    marginRight: "5%",
  },
}));

export default function IdeationQuestionaire({ isAuth }) {
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [copiedModalOpen, setCopiedModalOpen] = React.useState(false);
  const isAuthUser = isAuth;
  const userId = window.localStorage.getItem("id");
  const path = "/profile/" + userId;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function linkCopied() {
    setCopiedModalOpen(true);
    setTimeout(() => {
      setCopiedModalOpen(false);
    }, 2000);
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">You are not Logged In</h2>
      <p className="simple-modal-desc" id="simple-modal-description">
        TO VIEW THIS PAGE, YOU NEED TO BE LOGGED IN TO THE SITE
      </p>
      <div className="buttons">
        <NavLink
          to="/login"
          onClick={handleClose}
          style={{ textDecoration: "none", color: "black" }}
        >
          <button className="btn btn-primary btn-lg">Login</button>
        </NavLink>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (isAuthUser) {
      handleClose();
    } else {
      setTimeout(function () {
        handleOpen();
      }, 2000);
    }
  });

  const sample = (
    <div style={modalStyle} className={classes.sample}>
      <img
        src={SampleIdeation}
        alt="sample-ideation"
        className="sample-ideation"
      />
    </div>
  );

  const copied = (
    <div style={modalStyle} className={classes.paper}>
      <p>Link Copied!</p>
    </div>
  );

  const [step, setStep] = useState(0);
  const [inputs, setInputs] = useState([{ key: "", value: "" }]);
  const [inputs2, setInputs2] = useState([{ key: "", value: "" }]);
  const [ques1, setQues1] = useState("");
  const [ques2, setQues2] = useState("");
  const [finStr, setFinStr] = useState("");
  const [ques3, setQues3] = useState("");
  const [ques4, setQues4] = useState("");
  const [ques5, setQues5] = useState("");
  const [ques6, setQues6] = useState("");
  const [ques7, setQues7] = useState("");
  const [ques8, setQues8] = useState("");
  const [ques9, setQues9] = useState("");
  const [ques10, setQues10] = useState("");
  const [finStr2, setFinStr2] = useState("");
  const [productName, setProductName] = useState("");
  const [sharableLink, setSharableLink] = useState("");
  const [sampleOpen, setSampleOpen] = useState(false);

  function setSkills(preVal, inputs) {
    // console.log(inputs[0].value)
    var finstr = preVal;
    var strlen = inputs.length;
    console.log(strlen);
    if (strlen === 1) {
      finstr = finstr.concat(" and ");
      finstr = finstr.concat(inputs[0].value);
    } else {
      for (let i = 0; i < strlen - 1; i++) {
        finstr = finstr.concat(",");
        finstr = finstr.concat(inputs[i].value);
      }
      finstr = finstr.concat(" and ");
      finstr = finstr.concat(inputs[strlen - 1].value);
    }
    return finstr;
  }

  const addHandler = () => {
    const _inputs = [...inputs];
    _inputs.push({ key: "", value: "" });
    setInputs(_inputs);
  };

  const deleteHandler = (key) => {
    const _inputs = inputs.filter((input, index) => index != key);
    setInputs(_inputs);
  };

  const inputHandler = (text, key) => {
    const _inputs = [...inputs];
    _inputs[key].value = text;
    _inputs[key].key = key;
    setInputs(_inputs);
    setFinStr(setSkills(ques2, inputs));
    console.log(finStr);
  };

  const addHandler2 = () => {
    const _inputs = [...inputs2];
    _inputs.push({ key: "", value: "" });
    setInputs2(_inputs);
  };

  const deleteHandler2 = (key) => {
    const _inputs = inputs2.filter((input, index) => index != key);
    setInputs2(_inputs);
  };

  const inputHandler2 = (text, key) => {
    const _inputs = [...inputs2];
    _inputs[key].value = text;
    _inputs[key].key = key;
    setInputs2(_inputs);
    setFinStr2(setSkills(ques10, inputs2));
    console.log(finStr2);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const submitInfo = async () => {
    console.log("insdie submit info");
    const answers = [];
    answers.push(ques1);
    answers.push(finStr);
    answers.push(ques3);
    answers.push(ques4);
    answers.push(ques5);
    answers.push(ques6);
    answers.push(ques7);
    answers.push(ques8);
    answers.push(ques9);
    answers.push(finStr2);
    answers.push(productName);
    console.log(answers);

    const data = {
      userId: userId,
      answers: answers,
    };
    console.log(data);
    await axios
      .post(`${DOMAIN}/ideation/questionaire`, data, {
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res) => {
        let link = DOMAIN + `/ideation/sharable/` + res.data.id;
        setSharableLink(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Questions = () => {
    switch (step) {
      case 0:
        return (
          <>
            <h1 className="question_lines">I</h1>
            <input
              type="text"
              placeholder="Full Name"
              className="answer_input_box"
              style={{ width: "50%", marginLeft: "1%" }}
              value={ques1}
              onChange={(e) => setQues1(e.target.value)}
            />
          </>
        );
      case 1:
        return (
          <Grid container>
            <Grid
              item
              lg={4}
              md={12}
              className={classes.left_grid}
              style={{ backgroundColor: "" }}
            >
              <h1
                className="question_lines"
                style={{ flex: 1, textAlign: "center" }}
              >
                Skilled In
              </h1>
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              style={{
                backgroundColor: "",
                margin: "auto",
                marginTop: "2.5%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  placeholder="Top Skill"
                  className="answer_input_box"
                  value={ques2}
                  onChange={(e) => setQues2(e.target.value)}
                />
                <IconButton onClick={() => addHandler()}>
                  <AddIcon />
                </IconButton>
              </div>
              {inputs.map((input, key) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    placeholder="Other Skills"
                    className="answer_input_box"
                    value={input.value}
                    onChange={(e) => inputHandler(e.target.value, key)}
                  />
                  <IconButton onClick={() => deleteHandler(key)}>
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <>
            <h1 className="question_lines" style={{ width: "100%" }}>
              have observed{" "}
              <input
                type="text"
                placeholder="Problem Statement"
                className="answer_input_box"
                style={{ width: "75%" }}
                value={ques3}
                onChange={(e) => setQues3(e.target.value)}
              />
            </h1>
          </>
        );
      case 3:
        return (
          <>
            <h1 className="question_lines" style={{ width: "100%" }}>
              This Impacts{" "}
              <input
                type="text"
                placeholder="Severity of the Problem"
                className="answer_input_box"
                style={{ width: "78%" }}
                value={ques4}
                onChange={(e) => setQues4(e.target.value)}
              />
            </h1>
          </>
        );
      case 4:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              Due to which they face{" "}
              <textarea
                type="text"
                placeholder="Impact on absence of your product"
                className="answer_input_textarea"
                value={ques5}
                onChange={(e) => setQues5(e.target.value)}
                maxLength="1000"
              />
            </h1>
          </>
        );
      case 5:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              I can say this because{" "}
              <textarea
                type="text"
                placeholder="How you came to know about the problem"
                className="answer_input_textarea"
                value={ques6}
                onChange={(e) => setQues6(e.target.value)}
                maxLength="1000"
              />
            </h1>
          </>
        );
      case 6:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              I would like to propose{" "}
              <textarea
                type="text"
                placeholder="Your product or solution"
                className="answer_input_textarea"
                value={ques7}
                onChange={(e) => setQues7(e.target.value)}
                maxLength="1000"
              />
            </h1>
          </>
        );
      case 7:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              To validate my idea
              <textarea
                type="text"
                placeholder="What is the simplest way to know your idea would work?"
                className="answer_input_textarea"
                value={ques8}
                onChange={(e) => setQues8(e.target.value)}
                maxLength="1000"
              />
            </h1>
          </>
        );
      case 8:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              My idea is dependent on the factor that{" "}
              <textarea
                type="text"
                placeholder="Your Risk on the Idea"
                className="answer_input_textarea"
                value={ques9}
                onChange={(e) => setQues9(e.target.value)}
                maxLength="1000"
              />
            </h1>
          </>
        );
      case 9:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              I would need someone skilled in
              <input
                type="text"
                placeholder="necessary skills"
                className="answer_input_box"
                style={{ width: "95%" }}
                value={ques10}
                onChange={(e) => setQues10(e.target.value)}
              />
              <IconButton onClick={() => addHandler2()}>
                <AddIcon />
              </IconButton>
              {inputs2.map((input, key) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    placeholder="Other Skills"
                    className="answer_input_box"
                    value={input.value}
                    onChange={(e) => inputHandler2(e.target.value, key)}
                    style={{ width: "95%" }}
                  />
                  <IconButton onClick={() => deleteHandler2(key)}>
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
            </h1>
          </>
        );
      case 10:
        return (
          <>
            <h1
              className="question_lines"
              style={{
                display: "inline-block",
                width: "100%",
              }}
            >
              Product Name
              <input
                type="text"
                placeholder="Name for Recongnition of the Product"
                className="answer_input_box"
                style={{ width: "100%" }}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </h1>
          </>
        );
      case 11:
        return (
          <div className="sample_box">
            <h5 className="question_lines">
              <b>{productName}</b>
            </h5>
            <p className="sample_para">
              I <span className="sample_para_answers">{ques1}</span> , skilled
              in <span className="sample_para_answers">{finStr}</span> have
              observed that <span className="sample_para_answers">{ques3}</span>
              . This impacts{" "}
              <span className="sample_para_answers">{ques4}</span> due to which
              they face <span className="sample_para_answers">{ques5}</span>. I
              can say this because{" "}
              <span className="sample_para_answers">{ques6}</span>.
            </p>
            <p className="sample_para">
              I would like to propose{" "}
              <span className="sample_para_answers">{ques7}</span>. To validate
              my idea <span className="sample_para_answers">{ques8}</span>.
            </p>
            <p className="sample_para">
              My Idea is dependent on the factor that{" "}
              <span className="sample_para_answers">{ques9}</span>. I would need
              someone skilled in{" "}
              <span className="sample_para_answers">{finStr2}</span> as my
              Co-Founder/team mate.
            </p>
            <div className="sample_box_icons">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(sharableLink);
                  linkCopied();
                }}
              >
                <img
                  src={ShareIcon}
                  alt="share-icon"
                  className="sample_icons"
                />
              </button>
              {/* <button style={{backgroundColor: 'transparent', border: 'none',cursor: 'pointer'}}>
                <img src={CommentIcon} alt="share-icon" className="sample_icons"/>
              </button> */}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="questionaire_container" style={{ backgroundColor: "" }}>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={sampleOpen}
        onClose={() => setSampleOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {sample}
      </Modal>
      <Modal
        open={copiedModalOpen}
        onClose={() => setCopiedModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {copied}
      </Modal>
      <div
        className="view_sample_btn_contianer"
        style={{ backgroundColor: "" }}
      >
        <button
          type="submit"
          variant="contained"
          className="test_apply_btn view_btn"
          style={{ width: "250px" }}
          onClick={() => setSampleOpen(true)}
        >
          View Sample
        </button>
      </div>
      <div className="question_container" style={{ backgroundColor: "" }}>
        {Questions()}
      </div>
      <div
        className="next_prev_button_container"
        style={{ backgroundColor: "" }}
      >
        <div className="left_button_box" style={{ backgroundColor: "" }}>
          {step > 0 && step < 11 ? (
            <button
              type="submit"
              variant="contained"
              className="test_apply_btn step_btn"
              style={{ marginTop: "30px" }}
              onClick={prevStep}
            >
              Previous
            </button>
          ) : null}
        </div>
        <div className="right_button_box" style={{ backgroundColor: "" }}>
          {step <= 9 ? (
            <button
              type="submit"
              variant="contained"
              className="test_apply_btn step_btn"
              onClick={nextStep}
              style={{ marginTop: "30px" }}
            >
              Next
            </button>
          ) : step === 11 ? (
            <Box className={classes.btnbox}>
              <Button
                className="test_apply_btn step_btn"
                style={{ marginRight: "5%" }}
              >
                <Link
                  to="/ideation/privatelist"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Private Ideations
                </Link>
              </Button>
              <Button
                className="test_apply_btn step_btn"
                style={{ marginRight: "5%" }}
              >
                <Link
                  to="/ideation/publiclist"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Public Ideations
                </Link>
              </Button>
              <NavLink to={path} style={{ textDecoration: "none" }}>
                <button
                  type="submit"
                  variant="contained"
                  className="test_apply_btn step_btn"
                  style={{ width: "100%" }}
                >
                  To Profile
                </button>
              </NavLink>
            </Box>
          ) : (
            <button
              type="submit"
              variant="contained"
              className="test_apply_btn step_btn"
              style={{ marginTop: "30px" }}
              onClick={() => {
                nextStep();
                submitInfo();
              }}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

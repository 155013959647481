import React from "react";
import { useStyles } from "./VirtualCampusStyle";
import { useState, useEffect } from "react";
import { validateEmail } from "../auth/validateEmail";
import axios from "axios";
// import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@material-ui/core";
// import MessagePopup from "../../MessagePopup";
// import { useHistory } from "react-router-dom";
const DOMAIN = process.env.REACT_APP_DOMAIN;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VirtualCampus = () => {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [startupIdea, setStartupIdea] = useState("");
  const [coFounder, setCoFounder] = useState("");
  const [open, setOpen] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!contactNo) {
      setContactNoError("");
    } else {
      if (contactNo.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [contactNo]);
  const MakeAllFalse = () => {
    setFName("");
    setLName("");
    setContactNo("");
    setEmail("");
    setCollegeName("");
    setStartupIdea("");
    setCoFounder("");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fName: fName,
      lName: lName,
      contactNo: contactNo,
      email: email,
      collegeName: collegeName,
      startupIdea: startupIdea,
      coFounder: coFounder,
    };

    console.log(data);

    axios
      .post(`${DOMAIN}/virtualCampus/create`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        MakeAllFalse();
        setOpen(true);
      })
      .catch((err) => {});
  };
  const classes = useStyles();
  const styles = {
    radioButton: {
      display: "inline-block",
      marginRight: "5px",
      backgroundColor: "red",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
    },
    label: {
      display: "flex",
      fontFamily: "sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      color: "black",
    },
  };
  return (
    <>
      <div className={classes.fullpage}>
        <div className={classes.centerdiv}>
          <Typography variant="h3" className={classes.head}>
            Welcome to Markoknow
          </Typography>
          <div className={classes.formdiv}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.rowinput}>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>{contactNoError}</span>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  required
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  placeholder="Contact No."
                />
              </div>
              <span style={{ color: "red" }}>{emailError}</span>
              <div className={classes.rowinput}>
                <input
                  type="email"
                  class={classes.input_box2}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  value={collegeName}
                  onChange={(e) => setCollegeName(e.target.value)}
                  placeholder="College Name / Organization / Not Applicable (N/A)"
                />
              </div>
              <div className={classes.rowinput}>
                <div
                  class={classes.input_box2}
                  style={{
                    color: "black",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  Do you Have a Startup Idea?
                </div>
              </div>
              <div className={classes.rowinput}>
                <div className={classes.inputcontainer}>
                  <label style={styles.label}>
                    <input
                      type="radio"
                      style={styles.radioButton}
                      name="choice1"
                      value="yes"
                      checked={startupIdea === "yes"}
                      onChange={(e) => setStartupIdea(e.target.value)}
                    />
                    Yes
                  </label>
                </div>
                <div className={classes.inputcontainer}>
                  <label style={styles.label}>
                    <input
                      type="radio"
                      style={styles.radioButton}
                      name="choice1"
                      value="no"
                      checked={startupIdea === "no"}
                      onChange={(e) => setStartupIdea(e.target.value)}
                    />
                    No
                  </label>
                </div>
              </div>
              <div className={classes.rowinput}>
                <div
                  class={classes.input_box2}
                  style={{
                    color: "black",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  Do you Have a Co Founder?
                </div>
              </div>
              <div className={classes.rowinput}>
                <div className={classes.inputcontainer}>
                  <label style={styles.label}>
                    <input
                      type="radio"
                      style={styles.radioButton}
                      name="choice2"
                      value="yes"
                      checked={coFounder === "yes"}
                      onChange={(e) => setCoFounder(e.target.value)}
                    />
                    Yes
                  </label>
                </div>
                <div className={classes.inputcontainer}>
                  <label style={styles.label}>
                    <input
                      type="radio"
                      style={styles.radioButton}
                      name="choice2"
                      value="no"
                      checked={coFounder === "no"}
                      onChange={(e) => setCoFounder(e.target.value)}
                    />
                    No
                  </label>
                </div>
              </div>
              <div className={classes.rowinput}>
                <button type="submit" className={classes.submitbtn}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            The form was submitted successfully.
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default VirtualCampus;

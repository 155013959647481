///////////////Subscription Page///////////////////
import React, { useEffect } from "react";
import { Modal, Box, Grid } from "@material-ui/core";
import "../../stylesheets/partnership.css";
import axios from "axios";
import { isBrowser, isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Login from "../auth/login";
import ReactGA from "react-ga";
require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 450,
    margin: "auto",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Subscription({ isAuth }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [freeSubOpen, setFreeSubOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
  }, []);

  const notLoggedInBox = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">You are not Logged In</h2>
      <p className="simple-modal-desc" id="simple-modal-description">
        Please Log In to perform the action
      </p>
      <Login />
    </div>
  );

  const unlockedFreeSubs = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Congratulation!</h2>
      <p className="simple-modal-desc" id="simple-modal-description">
        Free Points Added!
      </p>
    </div>
  );

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(e) {
    if (isAuth) {
      const val = e.currentTarget.value;
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // creating a new order
      const result = await axios.post(`${DOMAIN}/api/orderpayment`, {
        amount: val,
      });

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result.data;

      const options = {
        key: "rzp_live_kxchZQZKetz0Qy", // Enter the Key ID generated from the Dashboard
        amount: amount,
        currency: currency,
        name: "Markoknow",
        description: "Subscription",
        order_id: order_id,
        handler: async function (response) {
          console.log(response);
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            amount: amount / 100,
          };

          const result = await axios.post(`${DOMAIN}/api/subscribe`, data, {
            headers: {
              authorization: window.localStorage.getItem("token"),
            },
          });

          alert(result.data.msg);
        },
        prefill: {
          name: window.localStorage.getItem("name"),
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      setModalOpen(true);
    }
  }

  return (
    <div className="partner_ship">
      <Box
        boxShadow={4}
        // bgcolor="white"
        m={1}
        p={1}
        className="Box_partnership"
        id="subscription-page"
      >
        <div>
          <Grid container>
            <Grid item lg={6} md={4} sm={12} xs={12}>
              <h6 className="sub_heading">Subscription.</h6>
            </Grid>
            <Grid item lg={6} md={8} sm={12} xs={12} style={{display: 'flex',justifyContent: 'flex-end'}}>
              <button type="submit" value="apply" className="sub_heading_btn" onClick={() => {
                setLoading(true)
                setTimeout(()=> {
                  setLoading(false)
                  setFreeSubOpen(true)
                },2000)
              }}>
                {loading? (
                  <CircularProgress />
                ) : (
                  "Unlock free Subscription"
                )}
              </button>
            </Grid>
          </Grid>
        </div>
        {isBrowser && (
          <p className="sub_para">
            Well hop on for a Committed relationship with Entrepreneurship
          </p>
        )}
        {isMobile && (
          <p className="sub_para">
            Well hop on for a Committed relationship with Entrepreneurship
          </p>
        )}
        <br />
        <div className="subscriptions_container">
          <div className="box1 common">
            <div className="head_sub">
              <h4 className="sub_head">3 months Subscription</h4>
            </div>
            <div className="mid_sub">
              <h4 className="sub_head">INR 199</h4>
            </div>
            <div className="bottom_sub">
              <p className="para_sub">
                Get 500 points that would be useful in utilising in attending
                events, posting intern requirements, and use other services at
                Markoknow.
              </p>
              <button
                type="submit"
                value={199}
                onClick={displayRazorpay}
                class="sub_btn"
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="box2 common">
            <div className="head_sub">
              <h4 className="sub_head">6 months Subscription</h4>
            </div>
            <div className="mid_sub">
              <h4 className="sub_head">INR 349</h4>
            </div>
            <div className="bottom_sub">
              <p className="para_sub">
                Get 1000 points that would be useful in utilising in attending
                events, posting intern requirements, and use other services at
                Markoknow.
              </p>
              <button
                type="submit"
                value={349}
                onClick={displayRazorpay}
                class="sub_btn"
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="box3 common">
            <div className="head_sub">
              <h4 className="sub_head">1 yr Subscription</h4>
            </div>
            <div className="mid_sub">
              <h4 className="sub_head">INR 799</h4>
            </div>
            <div className="bottom_sub">
              <p className="para_sub">
                Get 2000 points that would be useful in utilising in attending
                events, posting intern requirements, and use other services at
                Markoknow.
              </p>
              <button
                type="submit"
                value={799}
                onClick={displayRazorpay}
                class="sub_btn"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{ alignItems: "center", justifyContent: "center" }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {notLoggedInBox}
      </Modal>
      <Modal
        open={freeSubOpen}
        onClose={() => setFreeSubOpen(false)}
        style={{ alignItems: "center", justifyContent: "center" }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {unlockedFreeSubs}
      </Modal>
    </div>
  );
}

export default Subscription;
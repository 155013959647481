import React, { useEffect, useState } from "react";
import IdeationComponent from "./ideation";
import axios from "axios";
// import { useParams } from "react-router-dom";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

export default function IdeationsList() {
  const [ideations, setIdeations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newCommentAdded, setNewCommentAdded] = useState(false);
  // let params = useParams();
  const id = window.localStorage.getItem("id");

  function getData() {
    setLoading(true);
    axios
      .get(`${DOMAIN}/ideation/list/${id}`, {})
      .then((res) => {
        //console.log(res.data.ideations);
        setIdeations(res.data.ideations);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [newCommentAdded]);
  //console.log(ideations,"ye idea");

  return (
    <div style={{ backgroundColor: "#282828" }}>
      {loading
        ? null
        : ideations.map((obj, i) => {
            return (
              <IdeationComponent
                answers={obj.answers}
                comments={obj.comments}
                id={obj._id}
                setNewCommentAdded={() => setNewCommentAdded(!newCommentAdded)}
                ispublic={obj.public}
              />
            );
          })}
    </div>
  );
}

import React from "react";
import QuestionaireForCerti from "../About/questionaire";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";

const Usestyles = makeStyles({
  container: {
    backgroundColor: "#282828",
    height: "100vh",
  },
});

const Questionaire = ({ isAuth, setIsAuth }) => {
  const [preQuestionsOpen, setPreQuestionsOpen] = useState(false);
  const classes = Usestyles();
  return (
    <div className={classes.container}>
      <QuestionaireForCerti
        isAuth={isAuth}
        preQuestionsOpen={preQuestionsOpen}
      />
    </div>
  );
};

export default Questionaire;

import { Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { validateEmail } from "./validateEmail";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import "../../stylesheets/loginform.css";
import axios from "axios";
import ReactGA from "react-ga";
import LoginwithGoogle from "./LoginwithGoogle";
import Typewriter from "typewriter-effect";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function Login({ isAuth, setIsAuth }) {
  const history = useHistory();
  if (isAuth) {
    history.push("/profile/" + window.localStorage.getItem("id"));
  }
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [resp, setResp] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [pathn, setPathn] = useState(window.location.pathname);
  // const [loginDateAndTime, setloginDateAndTime] = useState([]);
  // const now = Date.now().toLocaleString();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // var orientation = "to top left";
    // var colorOne = "#52efda";
    // var colorTwo = "#8dffcc";
    // document.body.style.backgroundImage =
    //   "linear-gradient(" + orientation + "," + colorOne + "," + colorTwo + ")";
    // document.body.style.backgroundRepeat = "no-repeat";
    // document.body.style.backgroundAttachment = "fixed";
  }, []);

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  const clearset = () => {
    setEmail("");
    setPassword("");
    setEmailError("");
    setResp("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const registered = {
      email: email,
      password: password,
    };

    axios
      .post(`${DOMAIN}/signin`, registered)
      .then((response) => {
        if (response.status === 200) {
          window.localStorage.setItem("name", response.data.user.name);
          window.localStorage.setItem("fullName", response.data.user.fullName);
          window.localStorage.setItem("email", response.data.user.email);
          window.localStorage.setItem("id", response.data.user._id);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem("isAuth", true);
          window.localStorage.setItem(
            "DontAskAgain",
            response.data.user.DontAskAgain
          );
          window.localStorage.setItem("isAdmin", response.data.user.isAdmin);
          window.localStorage.setItem(
            "isApplyExperienceFilled",
            response.data.user.isApplyExperienceFilled
          );
          window.localStorage.setItem(
            "point",
            response.data.user.markoknowPoints
          );
          window.localStorage.setItem(
            "eventNumbers",
            JSON.stringify(response.data.user.eventNumber)
          );
          window.localStorage.setItem(
            "referralId",
            response.data.user.referralId
          );
          setIsAuth(true);
          clearset();
          history.push("/");
        } else if (response.status === 203) {
          setResp(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
        clearset();
      });
  };

  const [state1] = useState({
    title: "Markoknow",
    titleTwo: "Welcomes you",
    titleThree: "To",
  });

  return (
    <div className="Login_Form">
      {(pathn === "/dashboard" ||
        pathn === "/bookevent" ||
        pathn === "/membership") && (
        <div style={{ width: "21rem", margin: "auto" }} className="Alert_type">
          <Alert severity="info">
            <AlertTitle>INFO</AlertTitle>
            <strong>Please First Login to visit this Page</strong>
          </Alert>
        </div>
      )}

      {resp.length > 0 && (
        <div style={{ width: "21rem", margin: "auto" }} className="Alert_type">
          <Alert severity="error">
            <AlertTitle>ERROR OCCURED</AlertTitle>
            <strong>{resp}</strong>
          </Alert>
        </div>
      )}

      <Grid container>
        <Grid item lg={6} md={12} sm={12}>
          <div className="left_box">
            <h4 className="main-heading">
              <div className="titleLogin">{state1.title}</div>
              <div className="titleTwoLogin">{state1.titleTwo}</div>
              <div className="titleThreeLogin">{state1.titleThree}</div>
            </h4>
            <div className="textLogin">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    "its 25,000+ Community Platform for Founders, Investors & Learners",
                    "Learn , Network & Grow with Markoknow.",
                    "Startup Your Journey with Markoknow and make your passion a reality",
                  ],
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <div className="right_box">
            <form className="login_form" onSubmit={handleSubmit}>
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                <h3>Login</h3>
                <div style={{ margin: "20px" }}>
                  <LoginwithGoogle setIsAuth={setIsAuth} />
                </div>
              </div>

              <span className="error">{emailError}</span>
              <div>
                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={email}
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  label="Email"
                />

                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={password}
                  variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  label="Password"
                />
              </div>

              <div className="not_member">
                Not a member?{" "}
                <Link exact="true" to="/signup" style={{ color: "#2773a5" }}>
                  click here
                </Link>
                <Link
                  style={{ paddingLeft: "0.9rem", color: "#2773a5" }}
                  exact="true"
                  to="/forgotpassword"
                >
                  Forgot Password?
                </Link>
              </div>
              <br />

              <div className="auth_button">
                <button
                  type="submit"
                  variant="contained"
                  className="btn-primary button_login"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;

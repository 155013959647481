import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CardMedia from "@material-ui/core/CardMedia";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Certificate from "../../images/certificate-template.png";
import "../../stylesheets/questionaire.css";
import "../../stylesheets/about.css";
import { useHistory } from "react-router-dom";
/*-------- crop image after upload imports ------------*/
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./canvasUtils";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 450,
    //   textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
  },
  knowYourScoreModal: {
    display: "flex",
    position: "absolute",
    width: "58%",
    //   height: "70%",
    backgroundColor: theme.palette.background.paper,
  },
  btn: {
    /*width: 100%;
    height: 60px;
    background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
    border: none;
    font-weight: 900;
    font-size: 1rem;
    font-family: "Spartan", sans-serif !important;
    font-stretch: ultra-expanded;
    cursor: pointer;
    padding: 0px 20px;
    border-radius: 4px;*/
    width: "100%",
    height: "60px",
    border: "none",
    fontWeight: "900",
    fontSize: "1rem",
    fontFamily: "Spartan, sans-serif",
    fontStretch: "ultra-expanded",
    cursor: "pointer",
    padding: "0px 20px",
    borderRadius: "4px",
    marginTop: "8px",
  },
  questionaire_radio_button_root: {
    backgroundColor: "#fff",
    width: "5px",
    height: "5px",
    padding: "0.5rem",
    borderColor: "#fff",
  },
  questionaire_radio_button_checked: {
    // // backgroundColor: '#137cbd',
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
  confirmModal: {
    display: "flex",
    position: "absolute",
    width: "20%",
    //   height: "70%",
    backgroundColor: theme.palette.background.paper,
  },
  cropImageModal: {
    // display: 'flex',
    position: "absolute",
    width: "50%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    color: "#000",
    animationDuration: "550ms",
    position: "relative",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  sharebutton: {
    width: "100%",
    height: "60px",
    backgroundImage: "linear-gradient(to top left, #52efda 0%, #8dffcc 100%)",
  },

  "@media only screen and (max-width: 420px)": {
    paper: {
      width: 250,
      marginLeft: 0,
    },
    knowYourScoreModal: {
      width: 300,
      marginLeft: 0,
    },
    confirmModal: {
      width: 250,
      marginLeft: 0,
    },
  },
}));

export default function Questionaire({ isAuth, preQuestionModalOpen }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [copiedOpen, setCopiedOpen] = useState(false);
  const history=useHistory();

  const openCopiedModal = () => {
    setCopiedOpen(true);
    setTimeout(() => {
      setCopiedOpen(false);
    }, 900);
  };

  /*-------------------------Crop Image section start--------------------*/
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropChange = (crop) => {
    setCrop(crop);
  };
  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const cropImage = React.useCallback(async () => {
    try {
      const croppedImageActual = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setCroppedImage(croppedImageActual);
      setImageCropModal(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const roundCropper = (
    <div style={modalStyle} className="cropper_modal">
      <div className="Question_box" style={{ marginTop: "25%" }}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
          showGrid={false}
          style={{
            containerStyle: {
              height: "70%",
              top: "0",
              "@media only screen and (max-width: 996px)": {
                height: "60%",
              },
            },
          }}
        />
        <div style={{ display: "flex", width: "90%" }}>
          <div className="cropper_btn_container">
            <button
              onClick={() => {
                setImageCropModal(false);
                setImageSrc(null);
              }}
              className="test_apply_btn cropper_btns"
            >
              Cancel
            </button>
          </div>
          <div
            className="cropper_btn_container"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              onClick={() => cropImage()}
              className="test_apply_btn cropper_btns"
            >
              Upload
            </button>
          </div>

          {/* <Grid container spacing={1}>
            <Grid item lg={5} md={12} sm={12}>
              
            </Grid>
            <Grid
              item
              lg={5}
              md={2}
              sm={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            ></Grid>
            <Grid
              item
              lg={5}
              md={12}
              sm={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              
            </Grid>
          </Grid> */}
        </div>
      </div>
    </div>
  );

  /*-------------------------Crop Image section end--------------------*/

  /*----------------------pre questionaire -----------------------*/
  const [preQuestionsOpen, setPreQuestionsOpen] =
    useState(preQuestionModalOpen);
  var dont = window.localStorage.getItem("DontAskAgain");

  useEffect(() => {
    if (isAuth) {
      if (dont === "true") {
        setPreQuestionsOpen(false);
      } else {
        setPreQuestionsOpen(true);
      }
    }
  }, []);

  const StyledFormControlLabel = withStyles((theme) => ({
    label: {
      padding: "0.5rem",
      fontSize: "1rem",
      fontFamily: "Open Sans",
      fontWeight: "bold",
    },
  }))(FormControlLabel);

  const StyledRadio = withStyles((theme) => ({
    root: {
      backgroundColor: "#000",
      width: "5px",
      height: "5px",
      padding: "0.5rem",
      borderColor: "#000",
    },
  }))(Radio);
  const StyledSelector = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        borderRadius: 4,
        borderColor: "#fff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const [step, setStep] = useState(1);
  const [preQues1, setPreQues1] = useState("Student");
  const [preQues2, setPreQues2] = useState("");

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const id = window.localStorage.getItem("id");
  function DontAskAgain() {
    if (isAuth) {
      axios
        .post(
          `${DOMAIN}/dont_ask_again`,
          {},
          { headers: { authorization: window.localStorage.getItem("token") } }
        )
        .then((res) => {
          setPreQuestionsOpen(false);
        })
        .catch((err) => {
          console.log(`${err}`);
        });
    }
  }

  const preQuestionFinished = () => {
    setPreQuestionsOpen(false);
    setKnowYourScoreOpen(true);
  };

  function PreQuestions() {
    switch (step) {
      case 1:
        return (
          <>
            <h1 className="questionaire_questions_heading">You are ..</h1>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={preQues1}
              onChange={(e) => setPreQues1(e.target.value)}
              style={{ marginLeft: "10%" }}
            >
              <StyledFormControlLabel
                value="Student"
                control={<StyledRadio />}
                label="Student"
              />
              <StyledFormControlLabel
                value="Professional"
                control={<StyledRadio />}
                label="Professional"
              />
              <StyledFormControlLabel
                value="Early Startup"
                control={<StyledRadio />}
                label="Early Startup"
              />
              <StyledFormControlLabel
                value="Mature Startup"
                control={<StyledRadio />}
                label="Mature Startup"
              />
            </RadioGroup>
          </>
        );
      case 2:
        return (
          <>
            <h1 className="questionaire_questions_heading">
              You are {preQues1}
            </h1>
            {(() => {
              if (preQues1 === "Student" || preQues1 === "Professional") {
                return (
                  <Select
                    value={preQues2}
                    onChange={(e) => setPreQues2(e.target.value)}
                    className="questionaire_selector"
                    defaultValue="None"
                    variant="outlined"
                    input={<StyledSelector />}
                  >
                    <option aria-label="None" value="">
                      None
                    </option>
                    <option value="With An Idea">With An Idea</option>
                    <option value="Learning">Learning</option>
                  </Select>
                );
              }
              if (preQues1 === "Early Startup") {
                return (
                  <Select
                    value={preQues2}
                    onChange={(e) => setPreQues2(e.target.value)}
                    className="questionaire_selector"
                    defaultValue="None"
                    variant="outlined"
                    input={<StyledSelector />}
                  >
                    <option aria-label="None" value="">
                      None
                    </option>
                    <option value="With An Idea">Learning</option>
                    <option value="Learning">Idea Staged</option>
                    <option value="Users onboarded">Users Onboarded</option>
                    <option value="Revenue Generated">Revenue Generated</option>
                  </Select>
                );
              } else {
                return (
                  <Select
                    value={preQues2}
                    onChange={(e) => setPreQues2(e.target.value)}
                    className="questionaire_selector"
                    variant="outlined"
                    input={<StyledSelector />}
                  >
                    <option aria-label="None" value="">
                      None
                    </option>
                    <option value="With An Idea">Looking for Organising</option>
                    <option value="Learning">Looking to sponsor</option>
                    <option value="Users onboarded">
                      Looking to collaborate
                    </option>
                  </Select>
                );
              }
            })()}
          </>
        );
      case 3:
        preQuestionFinished();
      // opening know your score modal
      default:
        return <h1 className="questionaire_questions_heading">DEFAULT CASE</h1>;
    }
  }

  const preQuestions = (
    <div style={modalStyle} className="questionaire_modal">
      <div className="Question_box">
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <PreQuestions />
        </FormControl>
        <Grid container spacing={1} className="next_prev_btn_container">
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {step !== 1 ? (
              <button
                onClick={prevStep}
                className="test_apply_btn"
                style={{ width: "200px" }}
              >
                Previous
              </button>
            ) : (
              <button
                onClick={(e) => setPreQuestionsOpen(false)}
                className={classes.btn}
                style={{ width: "200px" }}
              >
                Remind me later
              </button>
            )}
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            {step === 1 ? (
              <button
                onClick={(e) => DontAskAgain()}
                className={classes.btn}
                style={{ width: "200px" }}
              >
                Dont ask again
              </button>
            ) : null}
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              onClick={nextStep}
              className="test_apply_btn"
              style={{ width: "200px" }}
            >
              Next
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  /*--------------------------Pre Questionaire Section end------------------*/

  /*----------------------Know your score section-------------------------*/
  const [knowYourScoreOpen, setKnowYourScoreOpen] = useState(false);

  const knowYourScore = (
    <Grid
      container
      spacing={0}
      style={modalStyle}
      className="questionaire_modal know_your_score"
    >
      <Grid item md={6} xs={12} className="know_your_score_welcome_box_left">
        <h1 className="know_your_score_about_heading">Markoknow</h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CardMedia
            className={classes.certificate}
            title="certificate"
            image={Certificate}
            component="img"
          />
        </div>
        <p className="know_your_score_about_sub_heading">WELCOMES YOU!</p>
      </Grid>
      <Grid item md={6} xs={12} className="know_your_score_welcome_box_right">
        <h1 className="know_your_score_about_heading heading_right">
          Know Your Journey
        </h1>
        <p className="know_your_score_para">
          Start your journey with a commitment to Entrepreneurship. Understand
          on whether you are ready and what all you have already acheived on
          done towards Entrepreneurship.
        </p>
        <h1 className="about_the_test_heading">About the Test</h1>
        <p className="know_your_score_para_small">
          1. The test contains simple 7 questions <br />
          2. The duration of the test will be 5 minutes <br />
          3. You would be asked about your journey so far and how ready you are
          for your Startup <br />
          4. The test doesn't require you to prepare anything
          <br />
          5. Once you take the test, you would be provided a Certificate on
          beginning your Journey of Startup At Markoknow
          <br />
        </p>
        <button
          className="test_apply_btn"
          onClick={() => {
            setKnowYourScoreOpen(false);
            setQuestionsOpen(true);
          }}
        >
          Start
        </button>
      </Grid>
    </Grid>
  );

  /*-------------------------------Know your score section ends---------------------*/

  /*------------------------------Questions section starts--------------------------*/
  const [questionsOpen, setQuestionsOpen] = useState(false);
  const referralId = window.localStorage.getItem("referralId");
  // const shareCertificateLink =
  //   window.location.href + `generate_certificate/` + referralId;
  const shareCertificateLink =
    window.location.href + `generate_certificate/` + referralId;

  const [questionStep, setQuestionStep] = useState(1);
  const [ques1, setQues1] = useState("1");
  const [ques2, setQues2] = useState("");
  const [ques3, setQues3] = useState(() => []);
  const [ques4, setQues4] = useState(() => []);
  const [ques5, setQues5] = useState("");
  const [ques6, setQues6] = useState("");
  const [ques7, setQues7] = useState("");
  const [ques8, setQues8] = useState("");
  const [confirmModalOpen, setConfirmBoxOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const [openImageCropModal, setImageCropModal] = useState(false);
  const [resMsg, setResMsg] = useState("");
  function submitForm() {
    setSubmitting(true);
    var data = new FormData();
    data.append("question1", ques1);
    data.append("question2", ques2);
    data.append("question3", ques3);
    data.append("question4", ques4);
    data.append("question5", ques5);
    data.append("question6", ques6);
    data.append("question7", ques7);
    data.append("question8", ques8);
    data.append("profileImage", selectedFile);
    data.append("cropCoordinate", [croppedAreaPixels]);
    axios
      .post(`${DOMAIN}/question`, data, {
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res) => {
        setResMsg("200");
        setSubmitting(false);
        setConfirmBoxOpen(false);
        setQuestionStep(0);
      })
      .catch((err) => {
        setResMsg("Form Submission Failed");
        setSubmitting(false);
        setConfirmBoxOpen(false);
        setQuestionStep(0);
        console.log(`${err}`);
      });
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
      marginLeft: "0.5%",
      border: "none",
      "&:not(:first-child)": {
        borderRadius: 50,
      },
      "&:first-child": {
        borderRadius: 50,
      },
      "@media only screen and (max-width: 420px)": {
        display: "flex!important",
        flexDirection: "row",
        marginTop: "2%",
        marginBottom: "2%",
      },
    },
  }))(ToggleButtonGroup);

  const StyledToggleButton = withStyles({
    root: {
      height: "2rem",
      width: "5rem",
      backgroundColor: "#000",
      color: "#fff",
      margin: "2%",
      boxShadow: "-1px 1px",
      textTransform: "none",
      "&:visited": {
        boxShadow: "none",
        backgroundColor: "#fff",
        color: "red",
      },
      "&:hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    },
  })(ToggleButton);

  const MultiStyledToggleButton = withStyles({
    root: {
      height: "3rem",
      width: "10rem",
      padding: "2px",
      backgroundColor: "#000",
      color: "#fff",
      margin: "2%",
      boxShadow: "-1px 1px",
      textTransform: "none",
      "&:visited": {
        boxShadow: "none",
        backgroundColor: "#fff",
        color: "red",
      },
      "&:hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
      "@media only screen and (max-width: 420px)": {
        display: "flex",
        marginTop: "2%",
        marginBottom: "2%",
        height: "3rem",
        width: "6rem",
        fontSize: "0.6rem",
      },
    },
  })(ToggleButton);

  function StyledCircularProgress(props) {
    return (
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    );
  }

  const question1 = [
    {
      key: "1",
      time: "1 Yr",
      value: "1",
    },
    {
      key: "2",
      time: "2 Yr",
      value: "2",
    },
    {
      key: "3",
      time: "3 Yr",
      value: "3",
    },
    {
      key: "4",
      time: "4 Yr",
      value: "4",
    },
    {
      key: "5",
      time: "5 Yr",
      value: "5",
    },
    {
      key: "6",
      time: "6 Yr",
      value: "6",
    },
    {
      key: "7",
      time: "more",
      value: "more",
    },
  ];

  const question3 = [
    {
      domain: "Marketing",
      value: "Marketing",
    },
    {
      domain: "Tech",
      value: "Tech",
    },
    {
      domain: "Business Development",
      value: "Business Development",
    },
  ];

  const question6 = [
    {
      stage: "Learning",
    },
    {
      stage: "Idea",
    },
    {
      stage: "Prototyping",
    },
    {
      stage: "Team Ready",
    },
    {
      stage: "Some Customers",
    },
    {
      stage: "Revenue Generating",
    },
    {
      stage: "Funding Ready",
    },
  ];

  const question7 = [
    {
      domain: "Learning",
    },
    {
      domain: "Networking",
    },
    {
      domain: "Experience Gain",
    },
    {
      domain: "Positng Experience",
    },
    {
      domain: "Organising with Markoknow",
    },
  ];

  const confirmBox = (
    <div style={modalStyle} className="questionaire_modal">
      <div className="Question_box">
        {submitting === true ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StyledCircularProgress />
          </div>
        ) : (
          <>
            <h1 className="questionaire_questions_heading">
              Are you sure you want to submit?
            </h1>
            <Grid container spacing={2}>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button onClick={() => submitForm()} className="test_apply_btn">
                  Yes
                </button>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  onClick={() => setConfirmBoxOpen(false)}
                  className="test_apply_btn"
                >
                  No
                </button>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );

  const questions = (
    <div style={modalStyle} className="questionaire_modal">
      <div className="Question_box">
        <FormControl component="fieldset" style={{ width: "100%" }}>
          {/* If the preques 2 value is Learning only then the following code will run. */}
          {preQues2 === "Learning" ? (
            questionStep === 1 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  Since when are you learning about Entrepreneurship?
                </h1>
                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <StyledToggleButtonGroup
                    value={ques1}
                    exclusive
                    onChange={(e, newVal) => setQues1(newVal)}
                    className="ToggleButtonGroup"
                  >
                    {question1.map((obj, i) => {
                      return (
                        <StyledToggleButton keyt={obj.key} value={obj.value}>
                          {obj.time}
                        </StyledToggleButton>
                      );
                    })}
                  </StyledToggleButtonGroup>
                </Grid>
              </div>
            ) : questionStep === 2 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  Have you ever tried any Startup Idea?
                </h1>
                <RadioGroup
                  aria-label="idea"
                  name="idea"
                  value={ques2}
                  onChange={(e) => setQues2(e.target.value)}
                  style={{ marginLeft: "10%" }}
                >
                  <StyledFormControlLabel
                    value="Yes"
                    control={<StyledRadio />}
                    label="Yes"
                  />
                  <StyledFormControlLabel
                    value="No"
                    control={<StyledRadio />}
                    label="No"
                  />
                </RadioGroup>
              </div>
            ) : questionStep === 3 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  What are your key skills?
                </h1>
                <Grid
                  container
                  spacing={2}
                  className={classes.buttonBox}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <StyledToggleButtonGroup
                    value={ques3}
                    onChange={(e, newVal) => setQues3(newVal)}
                  >
                    {question3.map((obj, i) => {
                      return (
                        <MultiStyledToggleButton value={obj.value}>
                          {obj.domain}
                        </MultiStyledToggleButton>
                      );
                    })}
                  </StyledToggleButtonGroup>
                </Grid>
              </div>
            ) : questionStep === 4 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  What do you seek to Learn?
                </h1>
                <Grid
                  container
                  spacing={2}
                  className={classes.buttonBox}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <StyledToggleButtonGroup
                    value={ques4}
                    onChange={(e, newVal) => setQues4(newVal)}
                  >
                    {question3.map((obj, i) => {
                      return (
                        <MultiStyledToggleButton value={obj.value}>
                          {obj.domain}
                        </MultiStyledToggleButton>
                      );
                    })}
                  </StyledToggleButtonGroup>
                </Grid>
              </div>
            ) : questionStep === 5 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  Do you have a co founder?
                </h1>
                <RadioGroup
                  aria-label="idea"
                  name="idea"
                  value={ques5}
                  onChange={(e) => setQues5(e.target.value)}
                  style={{ marginLeft: "10%" }}
                >
                  <StyledFormControlLabel
                    value="Yes"
                    control={<StyledRadio />}
                    label="Yes, I have someone in mind"
                  />
                  <StyledFormControlLabel
                    value="No"
                    control={<StyledRadio />}
                    label="No"
                  />
                </RadioGroup>
              </div>
            ) : questionStep === 6 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  At what stage are you?
                </h1>
                <Grid
                  container
                  spacing={2}
                  className={classes.buttonBox}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <StyledToggleButtonGroup
                    value={ques6}
                    exclusive
                    orientation="vertical"
                    onChange={(e, newVal) => setQues6(newVal)}
                  >
                    {question6.map((obj, i) => {
                      return (
                        <MultiStyledToggleButton value={obj.stage}>
                          {obj.stage}
                        </MultiStyledToggleButton>
                      );
                    })}
                  </StyledToggleButtonGroup>
                </Grid>
              </div>
            ) : questionStep === 7 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  What are you most excited about Markoknow for?
                </h1>
                <Grid
                  container
                  spacing={2}
                  className={classes.buttonBox}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <StyledToggleButtonGroup
                    value={ques7}
                    exclusive
                    orientation="vertical"
                    onChange={(e, newVal) => setQues7(newVal)}
                  >
                    {question7.map((obj, i) => {
                      return (
                        <MultiStyledToggleButton value={obj.domain}>
                          {obj.domain}
                        </MultiStyledToggleButton>
                      );
                    })}
                  </StyledToggleButtonGroup>
                </Grid>
              </div>
            ) : questionStep === 8 ? (
              <div class="question_containers">
                <h1 className="questionaire_questions_heading">
                  Enter your Full Name
                </h1>
                <input
                  type="text"
                  value={ques8}
                  onChange={(e) => setQues8(e.target.value)}
                  class="test_input1"
                  placeholder="Full Name"
                  style={{ marginBottom: "2%" }}
                />

                <input
                  accept="image/*"
                  // style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={async (e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      setSelectedFile(undefined);
                      return;
                    }
                    const file = e.target.files[0];
                    // setCroppedImage(file);
                    let imageDataUrl = await readFile(file);
                    setImageSrc(imageDataUrl);
                    setSelectedFile(e.target.files[0]);
                    setImageCropModal(true);
                  }}
                />
                {/* <label htmlFor="contained-button-file">
                  <button
                    component="span"
                    onClick={() => console.log("upload image")}
                    className="test_apply_btn upload_image_btn"
                  >
                    Browse Image
                  </button>
                </label> */}
              </div>
            ) : questionStep === 0 ? (
              resMsg === "200" ? (
                <>
                  <h3
                    className="questionaire_questions_heading"
                    style={{ padding: "2%" }}
                  >
                    Form Submitted Successfully!
                  </h3>
                  <Canvas />
                </>
              ) : (
                <h1 className="questionaire_questions_heading">{resMsg}</h1>
              )
            ) : null
          ) : null}
        </FormControl>
        <Grid container spacing={1} className="next_prev_btn_container">
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {questionStep != 1 && questionStep != 0 ? (
              <button
                onClick={() => setQuestionStep(questionStep - 1)}
                className="test_apply_btn"
              >
                Previous
              </button>
            ) : null}
          </Grid>
          <Grid item lg={4} md={6} sm={4}>
            {questionStep == 0 ? (
              <button
                onClick={() => {
                  navigator.clipboard.writeText(shareCertificateLink);
                  openCopiedModal();
                }}
                className="test_apply_btn"
                style={{ marginTop: "5%", marginLeft: "-180px" }}
              >
                Share
              </button>
            ) : null}
          </Grid>

          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {questionStep != 8 && questionStep != 0 ? (
              <button
                onClick={() => setQuestionStep(questionStep + 1)}
                className="test_apply_btn"
              >
                Next
              </button>
            ) : questionStep == 0 ? (
              <button
                onClick={() => {setQuestionsOpen(false); history.push("/");}}
                className="test_apply_btn"
                style={{ marginTop: "5%" }}
              >
                Close
              </button>
            ) : (
              <button
                onClick={() => setConfirmBoxOpen(true)}
                className="test_apply_btn"
              >
                Submit
              </button>
            )}
          </Grid>
        </Grid>
        <Modal
          open={confirmModalOpen}
          onClose={() => setConfirmBoxOpen(false)}
          disableBackdropClick
        >
          {confirmBox}
        </Modal>
        <Modal
          open={openImageCropModal}
          onClose={() => setImageCropModal(false)}
        >
          {roundCropper}
        </Modal>
      </div>
    </div>
  );

  /*--------------------------Questions section ends----------------------------------*/
  window.localStorage.setItem("certiname", ques8);
  window.localStorage.setItem("certipic", croppedImage);
  /*-----------------------Certificate generation start---------------------------*/
  function Canvas() {
    const canvasRef = useRef(null);

    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      // let imgUrl = {CertificateTemplate}
      let imgUrl = `${DOMAIN}/` + "imageUploads/certificate-template.png";
      const image = new Image();
      const pfp = new Image();
      pfp.src = croppedImage;
      image.crossOrigin = "Anonymous";
      image.src = imgUrl;
      image.onload = function () {
        ctx.drawImage(image, 0, 0, 2000, 1420);
        ctx.font = "100px Norwester";
        ctx.fillStyle = "#fff";
        ctx.textAlign = "center";
        ctx.fillText(ques8, canvas.width / 2, 840);
        ctx.beginPath();
        ctx.arc(1000, 450, 200, 0, Math.PI * 2, false);
        ctx.clip();
        ctx.drawImage(pfp, 800, 250, 400, 400);
      };
      image.onerror = function (err) {
        console.log("err", err);
      };
    }, []);

    return (
      <>
        <canvas
          ref={canvasRef}
          height={1414}
          width={2000}
          style={{ border: "1px solid black" }}
        />
      </>
    );
  }

  /*-----------------------Certificate generation end---------------------------*/

  return (
    <>
      <Modal
        open={preQuestionsOpen}
        onClose={() => setPreQuestionsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {preQuestions}
      </Modal>
      <Modal
        open={knowYourScoreOpen}
        onClose={() => setKnowYourScoreOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {knowYourScore}
      </Modal>
      <Modal
        open={questionsOpen}
        onClose={() => setQuestionsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {questions}
      </Modal>
      <Modal
        open={copiedOpen}
        onClose={() => setCopiedOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          Link Copied!
        </div>
      </Modal>
    </>
  );
}

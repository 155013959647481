import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import { yellow } from '@material-ui/core/colors';
import Startup from "./Startup";

const useStyles = makeStyles({
  heading: {
    color: "white",
    margin: 17,
    marginTop: "40px",
  },
  alex: {
    textAlign: "center",
    backgroundColor: "black",
    height: "90vh",
    overflowY: "hidden",
  },
  "@media only screen and (max-width: 480px)": {
    heading: {
      color: "white",
      margin: 15,
    },
    alex: {
      textAlign: "center",
      backgroundColor: "black",
      height: "auto",
      overflowY: "scroll",
    },
  },
});

const Home = () => {
  const classes = useStyles();
  return (
    <Box className={classes.alex}>
      <Typography variant="h3" className={classes.heading}>
        Startup Now
      </Typography>
      <Typography variant="h5" className={classes.heading}>
        Get your startup path sorted with step by step achieving your milestone
        and goals
      </Typography>
      <Startup />
    </Box>
  );
};

export default Home;

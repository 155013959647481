import {
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  Modal,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import LoginwithGoogle from "../../auth/LoginwithGoogle";
import Axios from "axios";
import "./UpperBox.css";
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'black'
  },
  leftbox: {
    //backgroundColor:'yellow',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "black",
    height: "90vh",
    "@media screen and (max-width: 480px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "black",
      height: "50vh",
    },
  },
  rightbox: {
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
    height: "90vh",
    "@media screen and (max-width: 480px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "black",
      height: "60vh",
    },
  },
  text: {
    color: "white",
    fontSize: "72px",
    margin: "10px",
    fontFamily: "Josefin Sans, sans-serif",
    "@media screen and (max-width: 480px)": {
      color: "white",
      fontSize: "36px",
      margin: "10px",
    },
  },
  btnbox: {
    display: "flex",
    //backgroundColor:'blue',
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    "@media screen and (max-width: 480px)": {
      display: "flex",
      //backgroundColor:'blue',
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "5px",
    },
  },
  btn: {
    color: "black",
    backgroundColor: "#85FCCD",
    width: "30%",
    height: "60px",
    margin: "5px",
    fontWeight: "bold",
    borderRadius: "30px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
    "@media screen and (max-width: 480px)": {
      color: "black",
      backgroundColor: "#85FCCD",
      width: "30%",
      height: "45px",
      margin: "5px",
      fontWeight: "bold",
      borderRadius: "30px",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "white",
      },
    },
  },
  suggestbtn: {
    backgroundColor: "#52EFDA",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
  },
  inputbox: {
    width: "100%",
    //backgroundColor:'yellow',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    marginTop: "20px",
  },
  input: {
    width: "60%",
    backgroundColor: "white",
  },
  carousel: {
    width: "80%",
    "@media screen and (max-width: 480px)": {
      width: "90%",
    },
  },
  loginboxcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "200px",
  },
  loginbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    width: "30%",
    backgroundColor: "#85FCCD",
    flexDirection: "column",
    borderRadius: "4px",
  },
  announcement_div: {
    color: "white",
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: "100px",
    top: "100px",
    zindex: "1",
  },
});
const DOMAIN = process.env.REACT_APP_DOMAIN;

const UpperBox = ({ setIsAuth }) => {
  const classes = useStyles();
  const [boxOpen, setBoxOpen] = useState(false);
  const [PriorityAnswer, setPriorityAnswer] = useState("none");
  const [newAnnouncement, setNewAnnouncement] = useState([]);

  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      src: "https://images.moneycontrol.com/static-mcnews/2017/09/Startup3-770x433.jpg?impolicy=website&width=770&height=431",
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
      src: "https://cdn1.expresscomputer.in/wp-content/uploads/2020/01/03162856/Startup-Hub-Express-Computer.jpg",
    },
  ];
  const loginBox = (
    <Box className={classes.loginboxcontainer}>
      <Box className={classes.loginbox}>
        <Typography style={{ margin: "5px" }}>Login to continue</Typography>
        <LoginwithGoogle
          PriorityAnswer={PriorityAnswer}
          setIsAuth={setIsAuth}
        />
      </Box>
    </Box>
  );
  const handleSubmit = (value) => {
    setPriorityAnswer(value);
    setBoxOpen(true);
  };
  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} lg={6} className={classes.leftbox}>
          <Typography className={classes.text}>
            What Do You Need To Start Up.
          </Typography>
          <Box className={classes.btnbox}>
            <Button
              className={classes.btn}
              onClick={() => handleSubmit("Learn")}
            >
              Learn
            </Button>
            <Button
              className={classes.btn}
              onClick={() => handleSubmit("Find Co-Founder")}
            >
              Find Co-Founder
            </Button>
            <Button
              className={classes.btn}
              onClick={() => handleSubmit("Hire")}
            >
              Hire
            </Button>
          </Box>
          <Box className={classes.btnbox}>
            <Button
              className={classes.btn}
              onClick={() => handleSubmit("Ideation")}
            >
              Ideation
            </Button>
            <Button
              className={classes.btn}
              onClick={() => handleSubmit("Brainstorm")}
            >
              Brainstorm
            </Button>
          </Box>
          <Box className={classes.inputbox}>
            <TextField
              className={classes.input}
              onChange={(e) => setPriorityAnswer(e.target.value)}
            ></TextField>
            <Button
              className={classes.suggestbtn}
              onClick={() => setBoxOpen(true)}
            >
              Suggest
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={6} className={classes.rightbox}>
          <Carousel
            autoPlay={true}
            indicators={true}
            navButtonsAlwaysVisible={true}
            className={classes.carousel}
          >
            {items.map((item) => (
              <Card>
                <CardMedia component="img" height="300" image={item.src} />
              </Card>
            ))}
          </Carousel>
        </Grid>
        <Modal open={boxOpen} onClose={() => setBoxOpen(false)}>
          {loginBox}
        </Modal>
      </Grid>
      {/* <Grid className={classes.announcement_div}>
        {
          announcementDisplay? 
          <> 
          
            { newAnnouncement.map((val, key) => {
          return (
            <div key={key}>
              <a href={val.link}>
                <div className="ann_pop_main_page">
                 
                  <div className="ann_pop_main_content">
                    <span id="main_page_pop_heading">{}</span>
                    {"  "}
                    <span id="main_page_pop_text">{val.body}</span>
                  </div>
                </div>
              </a>
       
            </div>
          );
        })}
          </>
          :null
        }
      
      </Grid> */}
    </>
  );
};

export default UpperBox;

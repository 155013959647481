import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles({
    fullpage : {
        margin: '0px',
        backgroundColor : '#806BD0',
        height :'auto',
        width:'100%',
        position : 'absolute',
        overflow:'hidden',
        paddingBottom:'50vh'
    },
    design : { 
        display: 'inline',
        float: 'right',
        width: '400px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '60px',
        position: 'absolute',
        top : '0px',
        right: '0px',
        borderRadius: '60px 0px 0px 60px'
    },
    design2 : { 
        display: 'inline',
        float: 'right',
        width: '200px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '40px',
        position: 'absolute',
        top: '30px',
        borderRadius: '0px 20px 20px 0px'
    },
    heading: {
        textAlign:'center',
        color:'white',
        fontSize:'45px',
        fontWeight:'bold',
        fontStyle:'roboto',
        marginTop: '10px',
        // padding: '15px',
    },
    bottomline:{
        color: 'white',
        textAlign: 'center',
        // padding : '10px',
    },
    
    '@media(min-width: 1000px) and (max-width: 1200px)': {
        fullpage: {
            overflow: 'scroll',
            marginTop: '0px',
        },
        design: {
            width: '20%',
            height: '40px',
            opacity : '0.5'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.5',
        },
        
    },

    '@media(min-width: 700px) and (max-width: 1000px)' : {
        fullpage :{
            overflow: 'scroll',
            marginTop: '0px'
        },
        design: {
            width: '25%',
            height: '40px',
            opacity : '0.5'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.5',
        },
        
    },

    '@media(max-width: 700px)' : {
        fullpage :{
            overflow: 'scroll',
            marginTop: '0'
        },
        design: {
            width: '8%',
            height: '40px',
            opacity : '0.2'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.2',
        },
        
        nextbtn : {
            fontSize : '4vw'
        },
        heading : {
            marginTop: '20px',
            fontSize : '8vw'
        },
        bottomline : {
            fontSize : '4vw'
        }
    }
})
export {useStyles}
import React, { useEffect } from "react";
import axios from "axios";
import ShareIcon from "../../../images/share_icon.png";
import CommentIcon from "../../../images/feedback_icon.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { useParams } from "react-router-dom";
import "../../../stylesheets/questionaire.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@material-ui/core";
import {Box} from "@material-ui/core"

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    //   textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    ["@media only screen and (max-width: 420px)"]: {
      width: 250,
      marginLeft: 0, // secondary
    },
  },
  buttonboxleft:{
    display:'flex',
    flex:'6',
    //backgroundColor:'red'
  },
  buttonboxright:{
    display:'flex',
    flex:'6',
    //backgroundColor:'yellow',
    alignItems:'center',
    justifyContent:"flex-end"
  },
  pbtn:{
    backgroundColor:'#8dffcc'
  },
  delbtn:{
    backgroundColor:'#1956B4',
    marginLeft:'5px',
    height:'50px',
    width:'30px',
    borderRadius:'10px',
    "@media only screen and (max-width: 420px)":{
      backgroundColor:'#1956B4',
      marginLeft:'5px',
      height:'30px',
      width:'1px',
      borderRadius:'10px',
    }
  },
  deliconbtn:{
    height:'40px',
    width:'40px',
    color:'white',
    marginLeft:'10px',
    "@media only screen and (max-width: 420px)":{
      height:'20px',
      width:'20px',
      color:'white',
      marginLeft:'5px',
    }
  }
}));

export default function IdeationComponent({
  answers,
  comments,
  id,
  setNewCommentAdded,
  ispublic,
}) {
  const classes = useStyles();
  //   const [comments, setComments] = React.useState(comments);
  const postId = id;
  //console.log(answers);
  const sharableLink = window.location.href + `ideation/` + id;
  const [showAddNewComment, setShowAddNewComment] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  //   const [modalStyle] = React.useState(getModalStyle);
  const [newComment, setNewComment] = React.useState("");
  //   const [newCommentAdded, setNewCommentAdded] = React.useState(false);
  //   const [showErrorModal, setShowErrorModal] = React.useState(false);

  //   let params = useParams();

  //   async function getData() {
  //     await axios
  //       .get(`${DOMAIN}/ideation/` + params.uid, {})
  //       .then((res) => {
  //         console.log(res.data.result);
  //         setPostId(params.uid);
  //         setAns(res.data.result.answers);
  //         setComments(res.data.result.comments);
  //         setSharableLink(
  //           window.location.href + `ideation/` + res.data.result._id
  //         );
  //       })
  //       .catch((e) => console.log(e));
  //   }

  //   useEffect(() => {
  //     getData();
  //   }, []);

  //   useEffect(() => {
  //     getData();
  //   }, [newCommentAdded]);

  async function postComment() {
    const data = {
      userId: window.localStorage.getItem("id"),
      username: window.localStorage.getItem("name"),
      comment: newComment,
    };
    await axios
      .post(`${DOMAIN}/ideation/comment/` + postId, data, {
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res) => {
        setNewCommentAdded();
        setNewComment("");
        setShowAddNewComment(false);
      })
      .catch((e) => console.log(e));
  };

  //delete ideation
  const DeleteIdeation=()=>{
    axios
      .delete(`${DOMAIN}/ideation/delete/${postId}`,{
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res)=>{
        alert("sucessfully deleted ideation");
      })
      .catch((err)=>{
        alert("error occured!! try again later");
        console.log(err);
      })
  }
  // switch into public or private vice versa
  const SwitchIdeation=()=>{
    axios
      .post(`${DOMAIN}/ideation/switch/${postId}`,{},{
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res)=>{
        alert("sucessfully switched ideation");
      })
      .catch((err)=>{
        alert("error occured!! try again later");
        console.log(err);
      })
  }

  return (
    <div style={{ marginTop: "15px" }}>
      <div className="sample_box" style={{ 'backgroundColor':'#F8F8FF'}}>
        <h1 className="question_lines">{answers[10]}</h1>
        <p className="sample_para">
          I <span className="sample_para_answers">{answers[0]}</span> , skilled
          in <span className="sample_para_answers">{answers[1]}</span> have
          observed that{" "}
          <span className="sample_para_answers">{answers[2]}</span>. This
          impacts <span className="sample_para_answers">{answers[3]}</span> due
          to which they face{" "}
          <span className="sample_para_answers">{answers[4]}</span>. I can say
          this because <span className="sample_para_answers">{answers[5]}</span>
          .
        </p>
        <p className="sample_para">
          I would like to propose{" "}
          <span className="sample_para_answers">{answers[6]}</span>. To validate
          my idea <span className="sample_para_answers">{answers[7]}</span>.
        </p>
        <p className="sample_para">
          My Idea is dependent on the factor that{" "}
          <span className="sample_para_answers">{answers[8]}</span>. I would
          need someone skilled in{" "}
          <span className="sample_para_answers">{answers[9]}</span> as my
          Co-Founder/team mate.
        </p>
        <div className="sample_box_icons">
          <Box className={classes.buttonboxleft}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() =>  navigator.clipboard.writeText(sharableLink)}
            >
              <img src={ShareIcon} alt="share-icon" className="sample_icons" />
            </button>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => setShowAddNewComment(!showAddNewComment)}
            >
              <img src={CommentIcon} alt="share-icon" className="sample_icons" />
            </button>
            <Button 
            startIcon={<DeleteIcon className={classes.deliconbtn}/>}
            onClick={()=>{DeleteIdeation();}}
            className={classes.delbtn}
            />
          </Box>
          <Box className={classes.buttonboxright}>
            <Button className={classes.pbtn} onClick={()=>{SwitchIdeation();}}>{ispublic?("Make Private"):("Make Public")}</Button>
          </Box>
        </div>
        <div className="show_comments_box">
          {showComments ? (
            <IconButton onClick={() => setShowComments(false)}>
              <KeyboardArrowUpIcon size={32} />
            </IconButton>
          ) : (
            <IconButton onClick={() => setShowComments(true)}>
              <KeyboardArrowDownIcon size={32} />
            </IconButton>
          )}
        </div>
        <div className="comments_container">
          {showAddNewComment ? (
            <div class="question_containers">
              <h1 className="questionaire_questions_heading">
                Share your thoughts!
              </h1>
              <textarea
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="test_input1 comment_input"
                placeholder="Add new comment..."
                maxLength="200"
                style={{ marginBottom: "2%" }}
              />
              <button
                type="submit"
                className="test_apply_btn ideating_btn"
                style={{ width: "20%", fontSize: "1.2rem" }}
                onClick={() => postComment()}
              >
                Post
              </button>
            </div>
          ) : null}
          {showComments
            ? comments.map((obj, i) => {
                return (
                  <div className="comment_container" key={obj._id}>
                    <p
                      className="sample_para comment_name"
                      style={{ fontWeight: "bold" }}
                    >
                      {obj.username}
                    </p>
                    <p className="sample_para comment_content">{obj.comment}</p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

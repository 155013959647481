import { Box } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import ChatContacts from "./ChatContacts";
import Conversation from "../Components/Conversation";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { io } from "socket.io-client";
const DOMAIN = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles({
  main: {
    display: "flex",
    overflow: "hidden",
  },
  rightclose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'red',
    flex: 8,
    "@media only screen and (max-width: 420px)": {
      display: "none",
    },
  },
  leftclose: {
    //bas ayse hii
    display: "flex",
    flex: 4,
  },
  rightopen: {
    display: "flex",
    //alignItems:'center',
    //justifyContent:'center',
    flex: 8,
    "@media only screen and (max-width: 420px)": {},
  },
  leftopen: {
    //bas ayse hii
    display: "flex",
    flex: 4,
    "@media only screen and (max-width: 420px)": {
      display: "none",
    },
  },
});

const ChatHome = ({ userId }) => {
  const classes = useStyles();
  // states;
  const [recentChat, setRecentChat] = useState([]);
  //active conversationId
  const [conversationId, setConversationId] = useState();
  const [oppuser, setOppuser] = useState();
  const [styleright, setStyleright] = useState(classes.rightclose);
  const [styleleft, setStyleleft] = useState(classes.leftclose);
  const [checkchat, setCheckchat] = useState(false);
  const [connected, setConnected] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [flag, setFlag] = useState(false);

  const [socket, setSocket] = useState(null);
  if (userId) {
    axios
      .post(
        `${DOMAIN}/api/conversation/create`,
        { receiver: userId },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setConversationId(res._id);
        setFlag(!flag);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const getRecentChat = () => {
      const userId = window.localStorage.getItem("id");
      axios
        .get(`${DOMAIN}/api/conversation/${userId}`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setRecentChat(res.data);
        });
    };
    const changestyle = () => {
      if (checkchat === true) {
        setStyleleft(classes.leftopen);
        setStyleright(classes.rightopen);
        console.log("ye dheko hogaya");
      } else {
        setStyleleft(classes.leftclose);
        setStyleright(classes.rightclose);
        console.log("ye dheko hogaya");
      }
    };
    getRecentChat();
    changestyle();
  }, [checkchat, flag]);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_DOMAIN, { autoConnect: false });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  useEffect(() => {
    (() => {
      if (!socket) return;
      try {
        const authToken = window.localStorage.getItem("token");
        socket.auth = { Token: authToken };
        socket?.connect();
      } catch (error) {
        console.log(error);
      }
    })();
    return () => socket?.disconnect();
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => {
      console.log("connected");
      setConnected(true);
    });
    socket.on("disconnect", () => {
      console.log("disconnected");
      setConnected(false);
    });

    socket?.on("incoming_message", async (response) => {
      setRecentChat((recentChat) => {
        var indx = recentChat.findIndex(
          (x) => x._id == response.conversationId
        );
        if (indx === -1) return recentChat;
        recentChat[indx].latestMessage = response;
        return recentChat;
      });

      if (response.conversationId === conversationId?.toString())
        setConversation((prevChats) => [...prevChats, response]);
    });

    socket?.on("online", (response) => {
      setFlag(!flag);
      //updating recent chat
      // setRecentChat((recentChat)=>{
      //    let conv = recentChat;
      //    //id of user conversation
      //    const otherUser = response.userId;
      //    const convId = response.id;
      //    var indx = conv.findIndex((x) => x._id == convId);
      //    console.log(indx);
      //    if (indx === -1) return;

      //    //getting data object to update
      //    let data = conv[indx];

      //    if (response.status === true) {
      //      //user online
      //      data.online = [window.localStorage.getItem("id"), otherUser];
      //    } else {
      //      // user not online
      //      data.online = [window.localStorage.getItem("id")];
      //    }

      //    conv[indx] = data;
      //    return conv;
      // });
    });

    return () => socket.removeAllListeners();
  }, [socket, conversationId, flag]);

  return (
    <div>
      <Grid container spacing={2} className={classes.main}>
        <Box item xs={4} sm={12} md={4} lg={4} className={styleleft}>
          <ChatContacts
            recentChat={recentChat}
            setConversationId={setConversationId}
            setOppuser={setOppuser}
            setCheckchat={setCheckchat}
          />
        </Box>
        <Box item xs={8} sm={12} md={8} lg={8} className={styleright}>
          <Conversation
            setConversation={setConversation}
            setCheckchat={setCheckchat}
            socket={socket}
            connected={connected}
            conversation={conversation}
            conversationId={conversationId}
            oppuser={oppuser}
          />
        </Box>
      </Grid>
    </div>
  );
};

export default ChatHome;

import React from 'react'
import { Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useStyles } from './style'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ProfileSelection from '../profile-selection'
import ProfileLocation from '../Location'
import ProfileLinkedIn from '../profile-linkedin'
import ProfileDescription from '../CoFounder-profile/Pages/CoFounderProfile'
import SkillSelection from '../skill-selection'
import SkillLooking from '../skill-looking'
import axios from 'axios'
import MessagePopup from '../../MessagePopup'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    width:'80%',
    outline:'none',
    paddingBottom:5,
    backgroundColor:'#806BD0',
  };
  const DOMAIN = process.env.REACT_APP_DOMAIN
function CofounderProfile() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [count,setCount] = useState(0); 
    const [skillprimary, setSkillPrimary] = useState("")
    const [skillsecondary, setSkillSecondary] = useState("")
    const [skilllookingprimary,setSkillLookingprimary] = useState("")
    const [skilllookingsecondary,setSkillLookingsecondary] = useState("")
    const [youAre,setYouare] = useState("")
    const [description, setDescription] = useState("")
    const [linkedIn, setLinkedIn] = useState("")
    const [location,setLocation] = useState("")
    const [alertMessage, setAlertMessage] = useState("");
    const [openAlert, setOpenAlert] = useState(false); 
    const handleAlertClose = () => setOpenAlert(false);
    //console.log(count)
    const history = useHistory()
    //console.log(window.localStorage.getItem("id"))
    const submitHandler = (e) =>{
        let skill = {primary: skillprimary,secondary : skillsecondary}
        let skillLooking = {primary : skilllookingprimary,secondary : skilllookingsecondary}
        axios
          .post(`${DOMAIN}/cofounder/create`,{ 
              skillLooking,
              skill,
              description,
              youAre,
              location,
              linkedIn
            },
          {headers:{authorization:window.localStorage.getItem("token")}}
          )
          .then((res)=>{
            setAlertMessage("CoFounder Profile created Successfully ")
            setOpenAlert(true);
            history.push(`/profile/${window.localStorage.getItem("id")}`);
          })
          .catch((err)=>{
              if(err){
                  setAlertMessage(err.response?.data.Error); 
                  console.log(err.response?.datra.error)
                  setOpenAlert(true); 
              }
          })
    }

    // console.log(skilllookingprimary,skilllookingsecondary)
    // console.log(skillprimary,skillsecondary,youAre,description,linkedIn,location);
    
    return (
        <div className={classes.fullpage}>

            <div className={classes.design2}></div>
            <Typography variant='h2' className={classes.heading}>
                Co Founders Match
            </Typography>
            <div className={classes.design}></div>
            
            <Typography variant='h6' className={classes.bottomline}>
                Get the best matching Co Founder profile to connect and try your Startup
            </Typography>

            <Typography variant='h4' className={classes.heading2}>
                Couldn't Find Your Perfect CoFounder Match Right Now
            </Typography>

            <center>
              <button className={classes.nextbtn} onClick={handleOpen}>CREATE PROFILE</button>
            </center>
            <Typography variant='h6' className={classes.bottomline2}>
             To get recommendations on 100s of Prospective Co Founders profile
            </Typography>

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                <MessagePopup
                open={openAlert}
                handleAlertClose={handleAlertClose}
                message={alertMessage}
            />
                    {count===0?(
                        <SkillLooking
                            skilllookingprimary={skilllookingprimary}
                            skilllookingsecondary={skilllookingsecondary}
                            setSkillLookingprimary={setSkillLookingprimary}
                            setSkillLookingsecondary={setSkillLookingsecondary}
                        />
                    ):null}
                    {count===1?(
                        <SkillSelection
                            skillprimary={skillprimary} 
                            skillsecondary={skillsecondary}
                            setSkillPrimary={setSkillPrimary}
                            setSkillSecondary={setSkillSecondary}
                        />
                    ):null}
                    {count===2?(
                        <ProfileSelection setYouare={setYouare}/>
                    ):null}
                    {count===3?(
                        <ProfileDescription setDescription={setDescription}/>
                    ):null}
                    {count===4?(
                        <ProfileLinkedIn setLinkedIn={setLinkedIn}/>
                    ):null}
                    {count===5?(
                        <ProfileLocation setLocation={setLocation}/>
                    ):null}

                    <div className={classes.btnDiv}>
                        <button
                          className={classes.modalbtns}
                          onClick={function(event){handleClose();setCount(0)}}
                        >Cancel</button>
                        {count===5?(
                            <button 
                            className={classes.modalbtns}
                            type='submit'
                            onClick={function(event){submitHandler()}}
                            >Submit</button>
                        ):(
                            <button 
                            className={classes.modalbtns}
                            type='submit'
                            onClick={()=> setCount(count+1)}
                          >Next</button>
                        )}
                       
                    </div>
                </Box>

            </Modal>
            <div className={classes.quarter}></div>
        </div>
    )
}

export default CofounderProfile
import React from "react";
import { useStyles } from "./style";
import { TextField } from "@material-ui/core";
function CofounderLocation({ setLocation }) {
  const classes = useStyles();
  return (
    <div className={classes.fullpage}>
      <div className={classes.design2}></div>
      <div className={classes.design}></div>
      {/* <Typography variant='h3'>Location</Typography> */}
      <TextField
        className={classes.input}
        placeholder="Location"
        onChange={(event) => setLocation(event.target.value)}
        rows={1}
        InputProps={{
          disableUnderline: true,
          maxLength: 5,
        }}
      />
    </div>
  );
}

export default CofounderLocation;

/* eslint-disable no-useless-computed-key */
/* eslint-disable no-unused-vars */
import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { isMobile, isBrowser } from "react-device-detect";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
//import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { FaPaperPlane } from "react-icons/fa";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import ReactRoundedImage from "react-rounded-image";
import Modal from "@material-ui/core/Modal";
//import "../../stylesheets/profile.css";
import DefaultImg from "../../images/icon_download.png";
import axios from "axios";
import ReactGA from "react-ga";
import "./newprofile.css";
import ViewListIcon from "@mui/icons-material/ViewList";
// import UpwardDrawer from "./UpwardDrawer";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
}));

function Profile() {
  let params = useParams();
  const [bio, setBio] = useState("");
  const [bio1, setBio1] = useState("");
  const [point, setPoint] = useState(0);
  const [src, setSrc] = useState("IMG-icon_download.png");
  const [events, setEvents] = useState({
    meetups: 0,
    workshops: 0,
    sessions: 0,
  });
  const [about, setAbout] = useState("");
  const [error, setError] = useState("");
  const [imgerror, setImgError] = useState(true);
  const [img, setImg] = useState(null);
  const [msg, setMsg] = useState("");
  // // eslint-disable-next-line no-unused-vars

  const [members, setMembers] = useState([]);

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (params.id !== window.localStorage.getItem("id")) {
      setError("error");
    } else {
      axios
        .get(`${DOMAIN}/api/profile/` + params.id, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then(async (res) => {
          const response = res.data.result;
          setError(res.data.message);
          await setBio(response.bio);
          setAbout(response.bio);
          setPoint(response.markoknowPoints);
          setEvents((events) => ({
            ...events,
            meetups: response.events.meetups,
          }));
          setEvents((events) => ({
            ...events,
            workshops: response.events.workshops,
          }));
          setEvents((events) => ({
            ...events,
            sessions: response.events.sessions,
          }));
          if (response.imagePath) {
            setSrc(`${DOMAIN}/` + response.imagePath);
          } else {
            setSrc(response.image.name);
          }
        })
        .catch((err) => {
          console.log(`Error: ${err}`);
          setError("POST Error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (about) {
      setBio1(about);
    } else {
      setBio1(bio);
    }
  }, [about, bio]);

  const uploadImage = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
  };

  const submitImage = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (img === null) {
      setMsg("Select Image to Change Profile Image");
    } else {
      data.append("imagePath", img);
      axios
        .post(`${DOMAIN}/api/uploadpic/` + params.id, data)
        .then((resp) => {
          if (resp) {
            setImgError(false);
          }
        })
        .catch((err) => {
          setSrc(DefaultImg);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      bio: bio1,
    };
    axios
      .post(`${DOMAIN}/api/profile/edit/` + params.id, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        handleClose();
      });
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Update your Bio field</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          style={{ paddingBottom: "15px", width: "100%" }}
          value={about}
          multiline
          variant="outlined"
          rowsMax={5}
          onChange={(e) => setAbout(e.target.value)}
          type="text"
          required
          label="Bio"
          inputProps={{ maxLength: 100 }}
        />
      </form>
      <div className="buttons">
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-lg"
        >
          Submit
        </button>
      </div>
    </div>
  );

  const [show, setShow] = useState(true);
  return (
    <>
      <div className="profile_full_page">
        {error === "success" && (
          <div className="userBox">
            <div className="containerBox">
              {msg.length > 0 && <h2 className="msg_head">{msg}</h2>}
              {!imgerror && (
                <h2 className="msg_head">
                  Profile Picture Uploaded Successfully!
                </h2>
              )}

              <div className="profile_div">
                <div className="img_upload_div">
                  {isBrowser && (
                    <div className="profile_img_box">
                      <img
                        src={src}
                        className="profile_img"
                        alt="Profile Pic"
                      ></img>
                    </div>
                  )}
                  {isMobile && (
                    <img
                      src={src}
                      className="profile_img"
                      alt="Your Profile img"
                    />
                  )}

                  <form onSubmit={submitImage}>
                    <label htmlFor="upload-photo">
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="imagePath"
                        type="file"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                        accept="image/png, image/gif, image/jpeg"
                      />
                      <Fab
                        size="small"
                        className="fab_add"
                        component="span"
                        aria-label="add"
                        variant="extended"
                      >
                        <AddIcon className="fab_add_icon" />
                      </Fab>
                    </label>
                    {isBrowser && (
                      <button className="submit_pic" type="submit">
                        Submit <FaPaperPlane className="paper_plane" />
                      </button>
                    )}
                    {isMobile && (
                      <button className="submit_pic" type="submit">
                        <FaPaperPlane className="paper_plane" />
                      </button>
                    )}
                  </form>
                </div>

                <div className="userdetails_div">
                  <h2 className="username">
                    {window.localStorage.getItem("name")}
                  </h2>
                  <div className="profile_bio_div">
                    {bio1}{" "}
                    <EditIcon className="editicon" onClick={handleOpen} />
                  </div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    {body}
                  </Modal>
                  <h3 className="user_points">Markoknow Points: {point}</h3>
                </div>
              </div>

              {/* The below part of page starts here  */}
              <div className="user_profile_div">
                <Grid container className="user_profile_div2">
                  {/* <Grid item xs={12}></Grid> */}
                  <Grid item xs={6}>
                    <h5 style={{ color: "black" }}>Meetups</h5>
                    <h5>Workshops</h5>
                    <h5>Sessions</h5>
                    <h5>Experience</h5>
                    <h5>Connections</h5>
                    <h5>Sources</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <h5>{events.meetups}</h5>
                    <h5>{events.workshops}</h5>
                    <h5>{events.sessions}</h5>
                    <h5>{events.experience}</h5>
                    <h5>{events.connections}</h5>
                    <h5>{events.sources}</h5>
                  </Grid>
                </Grid>

                <div className="button_div">
                  <Link to="/bookevent" className="btn_below_link">
                    <button
                      type="submit"
                      variant="contained"
                      className="btn_below_profile"
                    >
                      Improve Score
                    </button>
                  </Link>
                  <Link to="/willcomesoon" className="btn_below_link">
                    <button
                      type="submit"
                      variant="contained"
                      className="btn_below_profile"
                    >
                      Share Achievement
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="side_div">
              <div className="toogle_btn_div">
                <button onClick={() => setShow(!show)} className="toogle_btn">
                  <ViewListIcon />
                </button>
              </div>

              {show ? (
                <div className="sidebar_div">
                  <div class="sidebar_btn_div">
                    <Link to="/willcomesoon" className="sidebar_btn_link">
                      <button class="sidebar_button">Notification</button>
                    </Link>
                  </div>
                  <div class="sidebar_btn_div">
                    <Link to="/willcomesoon" className="sidebar_btn_link">
                      <button class="sidebar_button">Experience Profile</button>
                    </Link>
                  </div>
                  <div class="sidebar_btn_div">
                    {/* <Link to = {`/profile/ideations/${params.id}`} className="sidebar_btn_link"> */}
                    <Link
                      to="/ideation/privatelist"
                      className="sidebar_btn_link"
                    >
                      <button class="sidebar_button">Ideations</button>
                    </Link>
                  </div>
                  <div class="sidebar_btn_div">
                    <Link to="/willcomesoon" className="sidebar_btn_link">
                      <button class="sidebar_button">Messages</button>
                    </Link>
                  </div>
                  <div class="sidebar_btn_div">
                    <Link to="/willcomesoon" className="sidebar_btn_link">
                      <button class="sidebar_button">My Applications</button>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {(error === "error" || error === "POST Error") && (
          <div
            style={{ width: "21rem", margin: "auto" }}
            className="Alert_Profile"
          >
            <Alert severity="error">
              <AlertTitle>ERROR OCCURED</AlertTitle>
              <strong>You are accessing wrong profile.</strong>
            </Alert>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;

import React from "react";
import { Container, Grid, Card, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useStyles } from "./style_profilecard";
import Button from "@material-ui/core/Button";
import ButtonBase from "@mui/material/ButtonBase";
import ReactReadMoreReadLess from "react-read-more-read-less";
const Img = styled("img")({
  maxWidth: "70px",
  maxHeight: "70px",
  borderRadius: "100%",
});
function Profilecard({ data }) {
  // console.log(data)
  const classes = useStyles();
  return (
    <Container>
      <Grid container spacing={5} style={{ marginTop: "20px" }}>
        {data.map((p) => (
          <Grid item xs={12} sm={6} md={4}>
            <Card elevation={3} className={classes.cards}>
              <Grid container spacing={2} xs={12} sm>
                <Grid item xs={4}>
                  <ButtonBase>
                    <Img alt="complex" src={p.image.name} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={8}>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography variant="h5" component="div">
                          {p.fullName}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Markoknow Points:{p.markoknowPoints}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Location: {p.cofounderProfile.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm container style={{ padding: "10px" }}>
                  <Grid item xs container direction="row">
                    <Grid item xs={5} className={classes.skills}>
                      <Typography
                        variant="h8"
                        component="div"
                        style={{ fontFamily: "spartan" }}
                      >
                        {p.cofounderProfile.skill.primary}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.skills}>
                      <Typography
                        variant="h8"
                        component="div"
                        style={{ fontFamily: "spartan" }}
                      >
                        {p.cofounderProfile.skill.secondary}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm container style={{ padding: "10px" }}>
                  <Grid item xs={12} className={classes.description}>
                    <Typography gutterBottom variant="h7" component="div">
                      <ReactReadMoreReadLess
                        charLimit={25}
                        readMoreText={"Read more"}
                        readLessText={"Read Less"}
                      >
                        {p.cofounderProfile.description}
                      </ReactReadMoreReadLess>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="row">
                    <Grid
                      item
                      xs={5}
                      style={{ padding: "10px" }}
                      className={classes.sect1}
                    >
                      <Typography gutterBottom variant="h7" component="div">
                        {p.cofounderProfile.youAre}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ padding: "10px" }}
                      className={classes.sect1}
                    >
                      <Typography gutterBottom variant="h7" component="div">
                        Looking For: {p.cofounderProfile.skillLooking.primary},
                        {p.cofounderProfile.skillLooking.secondary}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container xs={12}>
                <Grid
                  item
                  xs={12}
                  sm
                  container
                  style={{ justifyContent: "center" }}
                >
                  <Button className={classes.connect_btn}>CONNECT</Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Profilecard;

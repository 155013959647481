import "../../stylesheets/upcoming_event.css";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

function UpcomingEvent() {
  return (
    <>
      <h1 class="mkw_heading"> Watch Now </h1>
      <div class="mkw_cards_wrapper">
      
            <Card class="mkw_card" style={{ width: 18 + "rem" }} sx={{ maxWidth: 345 }}>
            <CardMedia
              class="mkw_card-img-top"
              component="img"
              height="140"
              image="https://cdn.pixabay.com/photo/2020/02/18/08/32/reading-4858795__340.jpg"
              alt="..."
            />
            <CardContent>
            <h5 >
              Startup Marketing and Popular Early Stage Marketing
            </h5>
              <Typography variant="body2" color="text.secondary">
              Speaker: Anisha Sharma, CEO of junglee
              </Typography>
              <span
              style={{
                fontWeight: "bold",
                position: "absolute",
                bottom: "40px",
              }}
            >
              Jan 13, 1999
            </span>
            </CardContent>
            <CardActions>
            <button
              class="mkw_test_apply_btn"
              style={{ position: "absolute", bottom: "20px", left: "35%" }}
            >
              Book
            </button>
            </CardActions>
            </Card>



            <Card class="mkw_card" style={{ width: 18 + "rem" }} sx={{ maxWidth: 345 }}>
            <CardMedia
            class="mkw_card-img-top"
              component="img"
              height="140"
              image="https://cdn.pixabay.com/photo/2020/10/02/17/46/rocket-5621755__480.png"
              alt="..."
            />
            <CardContent>
            <h5 >
              Startup Marketing and Popular Early Stage Marketing
            </h5>
              <Typography variant="body2" color="text.secondary">
              Speaker: Anisha Sharma, CEO of junglee
              </Typography>
              <span
              style={{
                fontWeight: "bold",
                position: "absolute",
                bottom: "40px",
              }}
            >
              Jan 13, 1999
            </span>
            </CardContent>
            <CardActions>
            <button
              class="mkw_test_apply_btn"
              style={{ position: "absolute", bottom: "20px", left: "35%" }}
            >
              Book
            </button>
            </CardActions>
            </Card>



            <Card class="mkw_card" style={{ width: 18 + "rem" }} sx={{ maxWidth: 345 }}>
            <CardMedia
              class="mkw_card-img-top"
              component="img"
              height="140"
              image="https://cdn.pixabay.com/photo/2021/08/29/18/51/idea-6584030__340.png"
              alt="..."
            />
            <CardContent>
            <h5 >
              Startup Marketing and Popular Early Stage Marketing
            </h5>
              <Typography variant="body2" color="text.secondary">
              Speaker: Anisha Sharma, CEO of junglee
              </Typography>
              <span
              style={{
                fontWeight: "bold",
                position: "absolute",
                bottom: "40px",
              }}
            >
              Jan 13, 1999
            </span>
            </CardContent>
            <CardActions>
            <button
              class="mkw_test_apply_btn"
              style={{ position: "absolute", bottom: "20px", left: "35%" }}
            >
              Book
            </button>
            </CardActions>
            </Card>
      </div>
    </>
  );
}
export default UpcomingEvent;

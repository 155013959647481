import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  cards: {
    padding: "10px",
    backgroundColor: "#221857",
    color: "white",
    height: "auto",
    minHeight: "370px",
    borderRadius: "15px",
  },
  skills: {
    backgroundColor: "#6A58c6",
    borderRadius: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    margin: "10px",
    padding: "10px",
  },
  sect1: {
    backgroundColor: "#6A58c6",
    fontFamily: "spartan",
    fontSize: "12px",
    margin: "5px",
    padding: "5px",
    wordWrap: "break-word",
  },
  description: {
    // border:'1px solid black',
    padding: "5px",
    textAlign: "justify",
    backgroundColor: "inherit",
    color: "white",
    fontSize: "15px",
    width: "100%",
    height: "auto",
  },
  connect_btn: {
    width: "100%",
    backgroundColor: "#8DFFCC",
    fontFamily: "spartan",
    fontSize: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    cursor: "pointer",
    marginTop: "20px",
  },
  "@media(max-width: 720px)": {
    cards: {
      height: "auto",
    },
  },
});

export { useStyles };

import React, { useState, useEffect } from "react";
import { Grid, Button, capitalize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../About/Footer";
import image from "./Untitled.png";
import image2 from "./Untitled_design__54_-removebg-preview.png";
import IITB from "../newHome/wallpaper.png";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useParams } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import { Typography } from "@mui/material";

const DOMAIN = process.env.REACT_APP_DOMAIN;
const u = makeStyles({
  body: {
    backgroundImage: `url(${image})`,
    backgroundColor: "lightblue",
    color: "black",
    height: "100%",
    width: "100%",
    paddingTop: "30px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "@media only screen and (max-width: 960px)": {
      height: "100%",
    },
    "@media only screen and (max-width: 480px)": {
      height: "165vh",
      paddingTop: "20px",
    },
  },
  body3: {
    backgroundColor: "white",
    color: "black",
    height: "190vh",
    paddingTop: "50px",
    "@media only screen and (max-width: 960px)": {
      height: "220vh",
    },
    "@media only screen and (max-width: 480px)": {
      height: "200vh",
      paddingTop: "20px",
    },
  },
  body1: {
    backgroundColor: "white",
    color: "black",
    height: "60vh",
    textAlign: "center",
    paddingTop: "50px",
    "@media only screen and (max-width: 960px)": {
      height: "100vh",
      marginLeft: "30px",
    },
    "@media only screen and (max-width: 480px)": {
      height: "100vh",
      marginLeft: "10px",
      textAlign: "left",
      paddingTop: "20px",
    },
  },
  container: {
    marginRight: "100px",
    marginLeft: "80px",
    "@media only screen and (max-width: 960px)": {
      marginRight: "10px",
      marginLeft: "10px",
    },
    "@media only screen and (max-width: 480px)": {
      marginRight: "30px",
      marginLeft: "30px",
    },
  },
  mainVideo: {
    marginLeft: "100px",
    borderRadius: "20px",
    "@media only screen and (max-width: 960px)": {
      width: "500px",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "0px",
      width: "100%",
      height: "100%",
    },
  },
  heading: {
    marginBottom: "20px",
    disply: "flex",
    color: "black",
    textAlign: "center",
    fontSize: "52px",
    fontWeight: "bold",
    width: "100%",
    // height: '600px',
    "@media only screen and (max-width: 960px)": {
      textAlign: "center",
      marginLeft: "30px",
    },
    "@media only screen and (max-width: 480px)": {
      fontSize: "30px",
      flexDirection: "row",
      marginLeft: "10px",
    },
  },
  subheading: {
    lineHeight: "1",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    width: "600px",
    marginTop: "30px",
    "@media only screen and (max-width: 480px)": {
      marginTop: "5px",
      marginLeft: "0px",
      marginBottom: "10px",
      width: "100%",
      textAlign: "none",
      fontSize: "15px",
    },
  },
  cardText: {
    fontSize: "32px",
    paddingTop: "10px",
    color: "#7e99bd",
    fontWeight: "bold",
    textAlign: "left",
    margin: "5px 5px 5px 30px",

    "@media only screen and (max-width: 480px)": {
      fontSize: "20px",
      textAlign: "center",
      margin: "5px 5px 5px 5px",
    },
  },
  cardText1: {
    fontSize: "28px",
    paddingTop: "10px",
    color: "#7e99bd",
    fontWeight: "bold",
    textAlign: "left",
    margin: "5px 5px 5px 30px",
    "@media only screen and (max-width: 480px)": {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "bold",
      margin: "5px 5px 5px 5px",
    },
  },
  Internships: {
    backgroundColor: "#251e4f",
    width: "90%",
    marginRight: "100px",
    marginLeft: "100px",
    borderRadius: "20px",
    "@media only screen and (max-width: 480px)": {
      width: "90%",
      marginLeft: "20px",
      marginRight: "0px",
    },
    "@media only screen and (max-width: 960px)": {
      width: "90%",
      marginLeft: "25px",
      marginRight: "0px",
    },
  },
  ourTeam1: {
    margin: "50px 0px 50px 50px",
    "@media only screen and (max-width: 960px)": {
      margin: "50px 100px 50px 100px",
    },
    "@media only screen and (max-width: 480px)": {
      margin: "50px 100px 50px 50px",
    },
  },
  mentorshipText: {
    color: "white",
    fontSize: "20px",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "0px",
      marginRight: "0px",
      textAlign: "left",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "50px",
      marginRight: "0px",
      textAlign: "left",
    },
  },
  communityText: {
    width: "300px",
    height: "300px",
    fontSize: "10px",
    marginBottom: "20px",
    borderRadius: "30px",

    "@media only screen and (max-width: 480px)": {
      height: "200px",
      width: "160px",
      overflowY: "scroll",
    },
  },
  communityText1: {
    width: "300px",
    height: "300px",
    backgroundColor: "black",
    color: "white",
    marginBottom: "50px",
    borderRadius: "30px",
    "@media only screen and (max-width: 480px)": {
      height: "200px",
      width: "180px",
      marginLeft: "0px",
      overflowY: "scroll",
      marginBottom: "10px",
    },
  },
  mentorshipList: {
    color: "white",
    fontSize: "20px",
    marginLeft: "50px",
    marginRight: "50px",
    marginTop: "50px",
    fontSize: "30px",
    "@media only screen and (max-width: 960px)": {
      textAlign: "left",
      marginTop: "50px",
    },
    "@media only screen and (max-width: 480px)": {
      marginTop: "20px",
      fontSize: "20px",
      marginRight: "0px",
    },
  },
  "@keyframes myEffect": {
    "100%": {
      transform: "translateY(-5px)",
    },
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(5px)",
    },
  },
  mainDiv: {
    marginTop: "50px",
    textAlign: "center",
    paddingLeft: "120px",
    animation: "$myEffect 3s linear infinite",
    "@media only screen and (max-width: 480px)": {
      marginTop: "5px",
      paddingLeft: "0px",
    },
  },
  mainButtons: {
    marginBottom: "40px",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "bold",
    width: "80%",
    boxShadow: "0 16px 12px -12px #7e99bd",
    border: "1px solid black",
    color: "#251e4f",
    "@media only screen and (max-width: 480px)": {
      marginBottom: "30px",
      marginLeft: "50px",
      fontSize: "20px",
    },
  },
  mainButtons2: {
    marginBottom: "40px",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "bold",
    width: "80%",
    boxShadow: "0 16px 12px -12px #7e99bd",
    border: "1px solid #251e4f",
    color: "#251e4f",
    marginLeft: "60px",
    "@media only screen and (max-width: 480px)": {
      fontSize: "20px",
      marginLeft: "100px",
      marginBottom: "30px",
      textAlign: "center",
    },
  },
  buttonFounders: {
    padding: "20px 100px 20px 100px",

    color: "#251E4F",
    fontWeight: "bold",
    textDecoration: "none",
    backgroundColor: "white",
    borderRadius: "30px",
    fontSize: "20px",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 150,
    paddingRight: 150,
    "@media only screen and (max-width: 960px)": {
      padding: "10px 50px 10px 50px",
    },
    "&:hover": {
      backgroundColor: "#7affc9",
      color: "black",
    },
  },
  buttonFounders2: {
    color: "white",
    fontWeight: "bold",
    textDecoration: "none",
    backgroundColor: "#251e4f",
    borderRadius: "30px",
    fontSize: "10px",
    padding: "10px 20px 10px 20px",
    "@media only screen and (max-width: 960px)": {
      padding: "10px 50px 10px 50px",
      textAlign: "center",
    },
    "&:hover": {
      backgroundColor: "#7affc9",
      color: "black",
    },
  },
  buttonFounders1: {
    padding: "10px 80px 10px 80px",
    marginTop: "40px",
    fontWeight: "bold",
    fontSize: "30px",
    color: "black",
    backgroundColor: "#7affc9",
    borderRadius: "30px",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "black",
    },
    "@media only screen and (max-width: 960px)": {
      marginLeft: "30px",
    },
  },
  buttonFounders3: {
    textDecoration: "none",
    textAlign: "left",
  },
  buttonFounders4: {
    textDecoration: "none",
    backgroundColor: "white",
    border: "5px solid black",
    color: "black",
    marginRight: "10px",
    padding: "10px 10px 10px 10px",
    borderRadius: "30px",
    "@media only screen and (max-width: 960px)": {
      backgroundColor: "white",
      padding: "20px 10px 20px 10px",
      color: "black",
      marginBottom: "10px",
      marginRight: "0px",
    },
  },
  buttonFounders5: {
    padding: "5px 40px 5px 40px",
    fontWeight: "bold",
    fontSize: "20px",
    color: "black",
    backgroundColor: "#7affc9",
    borderRadius: "30px",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "black",
    },
    "@media only screen and (max-width: 960px)": {
      marginLeft: "30px",
    },
  },
  cardDiv: {
    textAlign: "left",
    marginLeft: "30px",
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "15px",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "10px",
    },
  },
  cardHeading: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "15px",
    marginLeft: "30px",
    "@media only screen and (max-width: 480px)": {
      fontSize: "8px",
      marginLeft: "10px",
      fontWeight: "bold",
    },
  },
  cardContent: {
    textAlign: "left",
    marginLeft: "30px",
    fontSize: "10px",
    "@media only screen and (max-width: 480px)": {
      fontSize: "10px",
      marginLeft: "10px",
    },
  },
  eventButton: {
    padding: "20px 100px 20px 100px",
    marginTop: "40px",
    fontWeight: "bold",
    fontSize: "40px",
    textAlign: "center",
    color: "black",
    backgroundColor: "#7affc9",
    borderRadius: "30px",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "40px",
      fontSize: "20px",
    },
  },
  arrowForward: {
    "@media only screen and (max-width: 480px)": {
      fontSize: "50px",
    },
  },
  buttonLearners: {
    padding: "5px 60px 5px 60px",
    fontWeight: "bold",
    fontSize: "18px",
    color: "black",
    backgroundColor: "#7affc9",
    borderRadius: "30px",
    marginTop: "30px",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "black",
    },
    "@media only screen and (max-width: 960px)": {
      marginLeft: "10px",
      padding: "5px 30px 5px 30px",
      fontSize: "10px",
      marginTop: "2px",
    },
  },
  communityImage: {
    height: "150px",
    width: "300px",
    "@media only screen and (max-width: 960px)": {
      height: "100px",
      width: "150px",
    },
  },
  startupbox: {
    backgroundColor: "#251E4F",
    border: "1px solid",
    borderRadius: 30,
    borderColor: "white",
    borderWidth: 5,
    padding: 20,
    paddingLeft: 50,
    paddingBottom: 50,
  },
  btngrp: {
    color: "white",
    backgroundColor: "#251E4F",
    fontSize: 20,
    marginLeft: 50,
    marginRight: 50,
    marginBottom: 40,
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 30,
    borderColor: "white",
    border: "1px solid",
    borderWidth: 5,
    textTransform: "initial",
  },
  community: {
    width: "350px",
    border: "1px solid",
    color: "white",
    backgroundColor: "#251E4F",
    fontSize: 20,
    marginLeft: 300,
    marginRight: 50,

    paddingLeft: 70,
    paddingRight: 70,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 30,
    borderColor: "white",
    borderWidth: 5,
    textTransform: "initial",
  },
  image2: {
    "@media only screen and (max-width: 570px)": {
      display: "none",
    },
  },
});

function Learners() {
  const classes = u();
  // const { id } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  // ALl Data request
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${DOMAIN}/blogs`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className={classes.body}>
        <div className={classes.container}>
          <Grid container style={{ marginBottom: "40px" }}>
            <Grid className={classes.startupbox}>
              <div className={classes.heading}>
                <p style={{ textAlign: "left", color: "white", fontSize: 60 }}>
                  Your Virtual<br></br> Startup Campus
                </p>
              </div>
              <div
                className={classes.subheading}
                style={{ marginBottom: "20px" }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontWeight: "100",
                    color: "white",
                  }}
                >
                  Before planning Startup,Understanding it from<br></br> the
                  people who have actually done it correctly. Explore <br></br>
                  Startup in guided mode through Virtual Campus
                </p>
              </div>
              <div style={{ alignItems: "center", textDecoration: "none" }}>
                <Link to={"/virtual_campus"} style={{ textDecoration: "none" }}>
                  <Button variant="large" className={classes.buttonFounders}>
                    Apply Now
                  </Button>
                </Link>
              </div>
            </Grid>

            <Grid item lg={2} md={3} xs={12}>
              <Button className={classes.community}>
                <Typography display="block" variant="h5">
                  Join Community{" "}
                </Typography>
              </Button>
              <img className={classes.image2} src={image2} />
            </Grid>
          </Grid>
        </div>
        <Grid>
          <Button className={classes.btngrp}>
            <Typography display="block" variant="h5">
              Learn from<br></br> <h3>Founders</h3>{" "}
            </Typography>
          </Button>
          <Button className={classes.btngrp}>
            <Typography variant="h5">
              Skill based <br></br>
              <h3>Certificates</h3>
            </Typography>
          </Button>
          <Button className={classes.btngrp}>
            <Typography variant="h5">
              Part of Startup <br></br>
              <h3>Teams</h3>
            </Typography>
          </Button>
          <Button className={classes.btngrp}>
            <Typography variant="h5">
              Work on Live <br></br>
              <h3>Projects</h3>
            </Typography>
          </Button>
        </Grid>
        <div container className={classes.Internships}>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              "@media only screen and (max-width: 480px)": {
                disply: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Grid item lg={4} md={12} xs={12} className={classes.ourTeam1}>
              <div
                className={classes.heading}
                style={{
                  fontSize: "40px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "20px",
                }}
              >
                <p>
                  Internship with
                  <br /> Founder
                </p>
              </div>
              <p className={classes.mentorshipText}>
                Don't Join a Team, Join a founder in their mission. Internships
                designed to let you experience entrepreneurship and help you
                understand how startups work. Apply to achieve your goals
                faster.
              </p>
              <p>
                <Link
                  to={"/experience/inspire"}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="large" className={classes.buttonFounders1}>
                    Post
                  </Button>
                </Link>
              </p>
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "10px",
                "@media only screen and (max-width: 480px)": {
                  marginBottom: "50px",
                },
              }}
            >
              <div className={classes.mentorshipList}>
                <ul style={{ marginBottom: "30px" }}>Join Founders</ul>
                <ul style={{ marginBottom: "30px" }}>
                  Contribute towards Vision
                </ul>
                <ul style={{ marginBottom: "30px" }}>Grow while you work</ul>
                <ul style={{ marginBottom: "30px" }}>
                  Get hands on Experience
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.body3} style={{ height: "100%" }}>
        <Grid>
          <div className={classes.heading}>
            <p>Case Studies & Blogs</p>
          </div>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            {
              // <CardDesign img={blog.imagePath} subTitle={blog.subTitle} description={blog.description}/>
            }
            {blogs.map((blog) => {
              return (
                <Grid className={classes.communityText}>
                  <img
                    src={`${DOMAIN}/${blog.imagePath}`}
                    alt="..."
                    className={classes.communityImage}
                  ></img>
                  <Grid className={classes.cardDiv}>Nov 24 2002</Grid>
                  <Grid className={classes.cardHeading}>{blog.subTitle}</Grid>
                  <Grid className={classes.cardContent}>
                    <ReactReadMoreReadLess
                      charLimit={25}
                      readMoreText={"Read more"}
                      readLessText={"Read Less"}
                    >
                      {blog.description}
                    </ReactReadMoreReadLess>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* <Grid style={{backgroundColor: 'white'}}>
        <div className={classes.heading}>
          <p>
          All Projects
          <p  style={{fontSize: '20px', fontWeight: '200'}}>
            Start your projects journey with these independent projects.
          </p>
          </p>
        </div>
        <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
       <Grid item lg={3} md={5} xs={5}>
              <Grid className={classes.communityText}>
                <img src={IITB} height="150px" width="300px"/>
                <Grid className={classes.cardHeading} style={{fontWeight: '300'}}>
                How to create the following course?
              </Grid>
              <Grid className={classes.cardContent} style={{marginTop: '20px'}}>
                <p>
                <Button className={classes.buttonFounders2}>
                  Take Course
                </Button>
                  </p>
              </Grid>
              </Grid>
           
        </Grid>
        <Grid item lg={3} md={5} xs={5}>
              <Grid className={classes.communityText}>
                <img src={IITB} height="150px" width="300px"/>
                <Grid className={classes.cardHeading} style={{fontWeight: '300'}}>
                How to create the following course?
              </Grid>
              <Grid className={classes.cardContent} style={{marginTop: '20px'}}>
                <p>
                <Button className={classes.buttonFounders2}>
                  Take Course
                </Button>
                  </p>
              </Grid>
              </Grid>
           
        </Grid>
        <Grid item lg={3} md={5} xs={5}>
              <Grid className={classes.communityText}>
                <img src={IITB} height="150px" width="300px"/>
                <Grid className={classes.cardHeading} style={{fontWeight: '300'}}>
                How to create the following course?
              </Grid>
              <Grid className={classes.cardContent} style={{marginTop: '20px'}}>
                <p>
                <Button className={classes.buttonFounders2}>
                  Take Course
                </Button>
                  </p>
              </Grid>
              </Grid>
           
        </Grid>
           
        </Grid>
        </Grid> */}
      </div>

      <div className={classes.body3}>
        <div className={classes.body1} style={{ height: "50vh" }}>
          <div className={classes.container}>
            <div className={classes.heading}>
              <p style={{ textAlign: "left" }}>Startup Shorts</p>
              <div
                className={classes.subheading}
                style={{ width: "100%", textAlign: "left" }}
              >
                Subheading Is an Important Angle for the website and it should
                <br /> be smaller font than Heading
              </div>
            </div>
            <div className={classes.buttonFounders3}>
              <input
                type="text"
                className={classes.buttonFounders4}
                placeholder="Name"
              />
              <input
                type="text"
                className={classes.buttonFounders4}
                placeholder="Email"
              />
              <Link to={"/login"} style={{ textDecoration: "none" }}>
                <Button className={classes.buttonFounders5}>
                  Go To Login!
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <Grid style={{ backgroundColor: "#6A58c6" }}>
          <div className={classes.heading}>
            <p>Watch Videos</p>
          </div>
          <Grid
            container
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item lg={3} md={5} xs={5}>
              <Grid className={classes.communityText1}>
                <CardMedia
                  component="img"
                  height="140"
                  src={`${DOMAIN}/watchnow/ogNzZMsFMUhEbeSSV6dbay-1656673999550.jpg`}
                  alt="..."
                />
                <Grid
                  className={classes.cardHeading}
                  style={{ fontWeight: "300" }}
                >
                  How to create the following course?
                </Grid>
                <Grid className={classes.cardContent} style={{}}>
                  <p>
                    <Button className={classes.buttonLearners}>
                      Watch Now
                    </Button>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={5} xs={5}>
              <Grid className={classes.communityText1}>
                <CardMedia
                  component="img"
                  height="140"
                  src={`${DOMAIN}/watchnow/ogNzZMsFMUhEbeSSV6dbay-1656673999550.jpg`}
                  alt="..."
                />
                <Grid
                  className={classes.cardHeading}
                  style={{ fontWeight: "300" }}
                >
                  How to create the following course?
                </Grid>
                <Grid className={classes.cardContent} style={{}}>
                  <p>
                    <Button className={classes.buttonLearners}>
                      Watch Now
                    </Button>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={5} xs={5}>
              <Grid className={classes.communityText1}>
                <CardMedia
                  component="img"
                  height="140"
                  src={`${DOMAIN}/watchnow/ogNzZMsFMUhEbeSSV6dbay-1656673999550.jpg`}
                  alt="..."
                />
                <Grid
                  className={classes.cardHeading}
                  style={{ fontWeight: "300" }}
                >
                  How to create the following course?
                </Grid>
                <Grid className={classes.cardContent} style={{}}>
                  <p>
                    <Button className={classes.buttonLearners}>
                      Watch Now
                    </Button>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.Internships}>
          {/* <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginBottom: "100px",
              "@media only screen and (max-width: 480px)": {
                disply: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Grid item lg={4} md={12} xs={10} className={classes.ourTeam1}>
              <div
                className={classes.heading}
                style={{
                  fontSize: "40px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "20px",
                }}
              >
                <p>Ready For Funding?</p>
              </div>
              <p className={classes.mentorshipText}>
                Do you feel you have learnt and implemented sufficiently and you
                are at a stage where you can go for mentorship and funding. Here
                is something for you, Go ahead and explore our Founder's segment
              </p>
              <p>
                <Link to={"/fund"} style={{ textDecoration: "none" }}>
                  <Button variant="large" className={classes.buttonFounders1}>
                    Explore
                  </Button>
                </Link>
              </p>
            </Grid>
            <Grid
              item
              lg={4}
              md={12}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "10px",
                "@media only screen and (max-width: 480px)": {
                  marginBottom: "50px",
                },
              }}
            >
              <div className={classes.mentorshipList}>
                <ul style={{ marginBottom: "30px" }}>Network with Founders</ul>
                <ul style={{ marginBottom: "30px" }}>
                  Get Mentored on Your Startup
                </ul>
                <ul style={{ marginBottom: "30px" }}>Hire for Your Team</ul>
                <ul style={{ marginBottom: "30px" }}>
                  Get Funded for your Startup
                </ul>
              </div>
            </Grid>
          </Grid> */}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Learners;

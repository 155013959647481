//////////////////Experience Page/////////////////////
import React from "react";
import "../../stylesheets/partnership.css";
import LiveProject from "./liveProject";
import ReactGA from "react-ga";
// import SponsorForm from './sponsorForm';

function Experience() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
  }, []);

  return (
      <>
    
    <div className="partner_ship">
        <LiveProject />
    </div>
    </>
  );
}

export default Experience;

/*--------------------old code-----------------*/
// {/* <div style={{padding: '5%'}}>
//   <h1 className="partner_heading">Experience</h1>
//   {isBrowser && (
//     <p className="partner_para">
//       Get experience of How an Early Stage Startup works and choose the
//       founder
//       <br /> whose story inspires you the most
//     </p>
//   )}
//   {isMobile && (
//     <p className="partner_para">
//       Get experience of How an Early Stage Startup works and choose the
//       founder
//       <br /> whose story inspires you the most
//     </p>
//   )}
// </div>

// <br />
// <div class="partnership_btn">
//   <Grid
//     container 
//     spacing={2} 
//     style={{display: 'flex',justifyContent: 'center'}}
//     >
//     <Grid item lg={6} md={4} sm={12} style={{textAlign: 'center',width: '100%'}}>
//     <NavLink to="/experience/apply">
//         <button name="submit" class="exp_btn_test">
//           Apply for Experience
//         </button>
//     </NavLink>
//     </Grid>
//     <Grid item lg={6} md={4} sm={12}style={{textAlign: 'center',width: '100%'}}>
//     <NavLink to="/experience/inspire">
//         <button name="submit" class="exp_btn_test">
//           Inspire Young Entrepreneurs
//         </button>
//     </NavLink>
//     </Grid>
//   </Grid>
// </div> */}
import React from 'react'
import Header from '../Components/Header'
import Skills from '../Components/Skills'

const Home = () => {
    return (
        <div className='Home'>
            <Header/>
            <Skills/>
        </div>
    )
}

export default Home

import { Typography } from '@mui/material'
import React from 'react';
import Footer from '../About/Footer';
import {useMediaQuery} from '@mui/material';

const AboutUs = () => {
    const isMobile = useMediaQuery('(max-width:800px)');
  return (
    <div
     style={{
      margin:0,
      background: "linear-gradient(to top left, #6A58C6, #a8f5ff)",
    }}
    >
    <div 
    style={{
        marginTop:'30px',
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        
    }}
    >
        <Typography 
        variant="h2" 
        style={{
            textAlign:'center',
            fontFamily: 'sans-serif',
            fontWeight: 'bold'

        }}>
            About Us
        </Typography>
        <Typography 
        variant="h6"
        style={{
            textAlign:'center',
            fontFamily: 'sans-serif',
            fontSize:'30px'

        }}
        >
        Platform building Startups
        </Typography>
        <div
        style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'50px',
            flexDirection:isMobile?'column':'row',
            // flexWrap:'wrap',
            width:isMobile?'90%':'80%',
            borderRadius:'10px',
            // border:'#000 solid',
            marginBottom:'50px',
            background: "linear-gradient(to top left, #ffffffff,#ffffff33)",
            boxShadow: "2px 2px 5px #2773a5",
            
        }}
        >
            <div
            style={{
                width:'50%',
                padding:isMobile?'':'5%',

            }}
            >
            <Typography 
            variant="h4"
            style={{
                textAlign:'left',
                fontFamily: 'sans-serif',

                fontWeight:'900'
            }}>
            Who we are?
            </Typography>
            <br/>
            <Typography
            variant="body1"
            style={{
                // marginTop:'10px',
                textAlign:'left',
                fontFamily: 'sans-serif',

                fontSize:'24px'
            }}
            >
           With 50,000+ plus community people, Markoknow is enabling startup ecosystem in various aspects of their journey. The platform offer support, mentorship, community connect, events. 
            </Typography>
            <br/>
            <Typography
            variant="body1"
            style={{
                // marginTop:'10px',
                textAlign:'left',
                fontFamily: 'sans-serif',
                fontSize:'24px'
            }}
            >
            We are also a platform offering modules, learning material, resources, case studies, insights, video from industry experts, workshops and events for startup and business domains. 
            </Typography>

            </div>
            <div
            style={{
                width:'50%',
                padding:isMobile?'':'5%'
            }}
            >
            <br/>
            <Typography
            variant="body1"
            style={{
                textAlign:'left',
                fontFamily: 'sans-serif',

                fontSize:'24px'
            }}
            >
           Our Mission is to bring more job creators and support in the initial phase of startup journey. Our vision is to create a space that really channelizes  tangible resources to deserving startups. 
            </Typography>
            <br/>
            <Typography
            variant="body1"
            style={{
                // marginTop:'10px',
                textAlign:'left',
                fontFamily: 'sans-serif',

                fontSize:'24px'
            }}
            >
            Our core values are we want to create a supportive ecosystem that can drive best results with an outcome driven approach
            </Typography>

            </div>
        </div>
        
    </div>
    <Footer/>
    </div>
  )
}

export default AboutUs
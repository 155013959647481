/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import "../stylesheets/eventandmemberform.css";
import axios from "axios";
import { Carousel } from "3d-react-carousal";
// import Carousel from 'react-material-ui-carousel'
import CardComponent from "./eventList/CardComponent";
import ReactGA from "react-ga";
import EntreprenuerEvent from "./EntrepreneurEvents";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

function EventForm({ isAuth, setIsAuth }) {
  const [date1, setDate1] = useState();
  const [date, setDate] = useState();

  const [members, setMembers] = useState([]);

  const isAuthUser = isAuth;

  let listItems = [];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (date) {
      setDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (date1) {
      setDate1(date1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllMember();
  }, []);

  const getAllMember = () => {
    axios
      .get(`${DOMAIN}/api/eventboard`, {
        headers: {},
      })
      .then((res) => {
        setMembers(res.data.result);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };

  {
    members &&
      members.reverse().map((member) => {
        if (member.isImportant === true) {
          listItems.push(
            <CardComponent
              key={member._id}
              isAuth={isAuthUser}
              member={member}
              setIsAuth={setIsAuth}
            />
          );
        }
      });
  }

  return (
    <>
      <div className="fullpage">
        <div className="Event_form">
          {listItems.length && (
            <Carousel
              className="carousel_event"
              slides={listItems}
              autoplay={false}
            />
          )}
        </div>

        <EntreprenuerEvent key={members._id} members={members} />
      </div>
    </>
  );
}

export default EventForm;

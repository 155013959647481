import React, { useEffect, useState } from "react";
import "./App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

//components
import Signup from "./components/auth/Signup";
import Login from "./components/auth/login";
import Profile from "./components/auth/profile";
import MembershipForm from "./components/MembershipForm";
import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Dashboard/Home.jsx";
import About from "./components/About";
import EmailVerification from "./components/email/EmailVerification";
import EmailVerSignup from "./components/email/EmailVerSignup";
import EventForm from "./components/EventForm";
import ForgetPass from "./components/forgotPassword/ForgotPass";
import ResetPassword from "./components/forgotPassword/ResetPassword";
import PartnerShip from "./components/partnership/partnerShip";
import OrganiseForm from "./components/partnership/organiseForm";
import SponsorForm from "./components/partnership/sponsorForm";
import EmailTestimonial from "./components/email/EmailTestimonial";
import Testimonials from "./components/Testimonials/TestimonialDashboard";
import Experience from "./components/experience/experience";
import Apply from "./components/experience/applyForm";
import Inspire from "./components/experience/inspireForm";
import Subscription from "./components/subs/Subscription";
import CertificateGenerator from "./components/certificateGen/generator";
import Ideation from "./components/Ideation/ideation";
import IdeationQuestionaire from "./components/Ideation/questionaire";
import SharableIdeation from "./components/Ideation/Shareable/shareableIdeation";
import IdeationsList from "./components/Ideation/IdeationList/ideationsList";
import PublicIdeationList from "./components/Ideation/IdeationList/PublicIdeationList";
import PrivateIdeationList from "./components/Ideation/IdeationList/PrivateIdeationList";
import WatchNow from "./components/watch-now/watchNow";
import UpcomingEvent from "./components/upcoming_event/upcoming_event";
import UserProfile from "./components/user_profile/user_profile";
// import CoFounderProfileLink from "./components/CoFounder/profile-linkedin/co-founder-profile-link";
import CoFounderCreateProfile from "./components/CoFounder/create-profile";
import CoFounderMatch from "./components/CoFounder/profile-match-result";
import WatchNowForm from "./components/watch-now-form/watch-now-form";
import ChatHome from "./components/ChatSection/Pages/ChatHome";
import ChatStart from "./components/ChatSection/Pages/ChatStart";
import SamplePage from "./sample";
import CoFounderProfile from "./components/CoFounder/CoFounder-profile/Pages/CoFounderProfile";
import Skillselection from "./components/Skill-Selection/Pages/Home";
import LearnNow from "./components/Learn-Now/Pages/Home";
import Membership from "./components/Membership";
import CofounderSkillSelection from "./components/CoFounder/skill-selection";
import SkillSearch from "./components/CoFounder/skill-search/SkillSearch";
import StartupNow from "./components/StartupNow";
import Contact from "./components/Contact";
import WillComeSoon from "../src/CommingSoon";
import CofounderProfileSelection from "./components/CoFounder/profile-selection";
import Location from "./components/CoFounder/Location";
import PartnerWithUs from "./components/partnership/partner-with-us";
import EntreprenuerEvent from "./components/EntrepreneurEvents";
import Feedback from "./components/Feedback/Feedback";
import ExperienceProfile from "./components/auth/ExperienceProfile";
import Video from "./components/watch-now/Video";
import GetCerti from "./components/Questionare/Questionaire";
import StartupProblem from "./components/StartupProblem";
import Compatibility from "./components/Compatibility";
// import Founders from "./components/Founders";
import Learners from "./components/Learners";
import NewHome from "./components/newHome/NewHome";
import Courses from "./components/courses";
// import Funding from "./components/funding";
import Blogs from "./components/Blog/index";
import BlogPage from "./components/Blog/blogPage";
import LearnerLanding from "./components/LearnerLanding";
import VirtualCampus from "./components/VirtualCampus/VirtualCampus";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import AboutUs from "./components/AboutUs/index";
import PaymentCheckout from "./components/PaymentCheckout/PaymentCheckout";
import PaymentSuccess from "./components/PaymentCheckout/PaymentSuccess";
import RefundPolicy from "./components/RefundPolicy/index";
function App() {
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("isAuth") || false
  );
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize("UA-211886888-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <NavBar isAuth={isAuth} setIsAuth={setIsAuth} />
      <Switch>
        <Route exact path="/" component={NewHome} />
        <Route exact path="/chatstart" component={ChatStart} />
        <Route exact path="/chathome" component={ChatHome} />
        <Route exact path="/chat" component={ChatStart} />
        <Route exact path="/sample" component={SamplePage} />
        <Route exact path="/willcomesoon" component={WillComeSoon} />
        <Route exact path="/experienceproflie" component={ExperienceProfile} />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/cofounderprofile" component={CoFounderProfile} />
        <Route exact path="/entreprenuerevent" component={EntreprenuerEvent} />
        <Route exact path="/skillselection" component={Skillselection} />
        <Route
          exact
          path="/learnnow"
          component={() => <LearnNow isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/cofounderprofileselection"
          component={CofounderProfileSelection}
        />
        <Route
          exact
          path="/cofounderskillselection"
          component={CofounderSkillSelection}
        />
        <Route exact path="/cofounderskillsearch" component={SkillSearch} />
        <Route exact path="/startupnow" component={StartupNow} />
        <Route
          exact
          path="/dashboard"
          component={() => <Home isAuth={isAuth} />}
        />
        {/* <Route
            exact
            path="/"
            component={() => <About isAuth={isAuth} setIsAuth={setIsAuth} />}
          /> */}
        <Route
          exact
          path="/oldHome"
          component={() => <LearnNow isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/member"
          component={() => <Membership isAuth={isAuth} />}
        />
        {/* <Route
            exact
            path="/fund"
            component={() => <Funding isAuth={isAuth} />}
          /> */}
        <Route
          exact
          path="/virtual_Campus"
          component={() => <VirtualCampus isAuth={isAuth} />}
        />

        <Route
          exact
          path="/courses"
          component={() => <Courses isAuth={isAuth} />}
        />
        <Route
          exact
          path="/getcertificate"
          component={() => <GetCerti isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/signup"
          component={() => <Signup isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/login"
          component={() => <Login isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route
          exact
          path="/membership"
          component={() => <MembershipForm isAuth={isAuth} />}
        />
        <Route
          exact
          path="/partnership"
          component={() => <PartnerShip isAuth={isAuth} />}
        />
        <Route
          exact
          path="/partnership/organise"
          component={() => <OrganiseForm isAuth={isAuth} />}
        />
        <Route
          exact
          path="/partnership/sponser"
          component={() => <SponsorForm isAuth={isAuth} />}
        />
        <Route exact path="/partnerwithus" component={PartnerWithUs} />
        <Route exact path="/forgotpassword" component={ForgetPass} />
        <Route
          exact
          path="/bookevent"
          component={() => <EventForm isAuth={isAuth} setIsAuth={setIsAuth} />}
        />

        <Route
          exact
          path="/profile/:id"
          component={
            isAuth
              ? () => <Profile />
              : () => <Login history={history} setIsAuth={setIsAuth} />
          }
        />
        <Route
          exact
          path="/profile/ideations/:id"
          component={() => <IdeationsList />}
        />
        <Route
          exact
          path="/verify_email/:token"
          isAuth={isAuth}
          component={EmailVerification}
        />
        <Route
          exact
          path="/verify_signup_email/:token"
          isAuth={isAuth}
          component={EmailVerSignup}
        />
        <Route
          exact
          path="/verify_testimonial/:token"
          isAuth={isAuth}
          component={EmailTestimonial}
        />
        <Route exact path="/resetpassword/:token" component={ResetPassword} />
        <Route exact path="/testimonials" component={Testimonials} />
        <Route
          exact
          path="/subscription"
          component={() => <Subscription isAuth={isAuth} />}
        />
        <Route exact path="/experience" component={Experience} />
        <Route exact path="/experience/apply" component={Apply} />
        <Route exact path="/experience/inspire" component={Inspire} />
        {/* ideation */}
        <Route
          exact
          path="/ideation"
          component={() => <Ideation isAuth={isAuth} />}
        />
        <Route
          exact
          path="/ideation/questionaire"
          component={() => <IdeationQuestionaire isAuth={isAuth} />}
        />
        <Route
          exact
          path="/ideation/sharable/:uid"
          component={() => <SharableIdeation isAuth={isAuth} />}
        />
        <Route
          exact
          path="/ideation/list"
          component={() => <IdeationsList isAuth={isAuth} />}
        />
        <Route
          exact
          path="/ideation/publiclist"
          component={() => <PublicIdeationList isAuth={isAuth} />}
        />
        <Route
          exact
          path="/ideation/privatelist"
          component={() => <PrivateIdeationList isAuth={isAuth} />}
        />
        <Route
          exact
          path="/community"
          component={() => <CertificateGenerator />}
        />
        {/* watchnow */}
        <Route
          exact
          path="/watch_now"
          component={() => <WatchNow isAuth={isAuth} setIsAuth={setIsAuth} />}
        />
        <Route exact path="/watch_now/watch" component={Video} />
        <Route exact path="/upcoming_event" component={UpcomingEvent} />
        <Route exact path="/user_profile" component={UserProfile} />
        <Route
          exact
          path="/co_founder_create_profile"
          component={CoFounderCreateProfile}
        />
        <Route exact path="/co_founder_match" component={CoFounderMatch} />
        <Route exact path="/watch_now_form" component={WatchNowForm} />
        <Route exact path="/blog" component={Blogs} />
        <Route path="/blog/:id" component={BlogPage} />
        <Route exact path="/startupProblem" component={StartupProblem} />
        <Route exact path="/compatibility" component={Compatibility} />
        {/* <Route exact path="/founders" component={Founders} /> */}
        <Route exact path="/learners" component={Learners} />
        <Route exact path="/learnerLanding" component={LearnerLanding} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/cofounder_location" component={Location} />
        <Route exact path="/privacy_policy" component={PrivacyPolicy} />
        <Route exact path="/terms_conditions" component={TermsConditions} />
        <Route exact path="/about" component={AboutUs} />
        <Route
          exact
          path="/program/paymentcheckout/:id/:userid"
          component={PaymentCheckout}
        />
        <Route exact path="/success" component={PaymentSuccess} />
        <Route
          exact
          path="/refund&cancellationpolicy"
          component={RefundPolicy}
        />
      </Switch>
    </div>
  );
}

export default App;
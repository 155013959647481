import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { BeatLoader } from 'react-spinners'
import { css } from '@emotion/react'
import { makeStyles } from '@material-ui/core'
//import SkillSearch from '../CofounderSkillSearch/SkillSearch'

const loaderCss = css`
    display: flex; 
    justify-content: center; 
    align-items: center;  
`;

const useStyles = makeStyles({
    popup : {
        width: '400px', 
        height: '150px',
        border: 'none',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        padding : '20px',
        overflow : 'hidden',
    }
})

function SkillSearchpopup(props) {
    const { title, children, openPopup, setOPenPopup } = props;  
    const classes = useStyles()
    return (
       <Dialog open={openPopup} >
        
            <div className={classes.popup}>
                <h2>Finding You the best Match</h2>
                <BeatLoader/>
            </div>
           
       </Dialog>
    )
}

export default SkillSearchpopup

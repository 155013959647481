import React, { useState, useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Footer from "../About/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
const DOMAIN = process.env.REACT_APP_DOMAIN;
function convertHtmlToPlainText(html) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(html, "text/html");
  return parsedHtml.body.textContent || "";
}
function BlogPage() {
  const classes = u();
  const { id } = useParams();
  const [blog, setBlog] = useState({
    title: "",
    imagePath: "",
    subTitle: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${DOMAIN}/blog/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBlog(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [blog.id]);

  const htmlContent = blog.description;
  const plainText = convertHtmlToPlainText(htmlContent);
  const description = plainText;
  const sentences = description.split(/(?<=\. )/);
  // const formattedText = sentences.map((sentence, index) => (
  //   <React.Fragment key={index}>
  //     <span>{sentence}</span>
  //     {index !== sentences.length - 1 && <br />}
  //     <br />
  //   </React.Fragment>
  // ));
  return (
    <>
      <div className={classes.body}>
        {loading ? (
          <CircularProgress style={loader} size={45} />
        ) : (
          <div className={classes.container}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography className={classes.heading}>{blog.title}</Typography>
            </Box>

            <Box className={classes.box}>
              <Box className={classes.imgBox}>
                <img
                  src={`${DOMAIN}/${blog.imagePath}`}
                  alt="..."
                  className={classes.img}
                ></img>
              </Box>

              <Box className={classes.textBox}>
                <Typography className={classes.subhead}>
                  {blog.subTitle}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.box2}>
              <Box className={classes.descriptionBox}>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                {/* <Typography className={classes.description}>
                  {formattedText}
                </Typography> */}
              </Box>
            </Box>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

const loader = {
  // backgroundColor: 'rgba(52, 52, 52, 0.8)',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
};

const u = makeStyles({
  box: {
    width: "77vw",
    backgroundColor: "#6A58c6",
    padding: "10px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    boxShadow: " 2px 2px 5px #2773a5 ",
    display: "flex",
    // border: "1px solid red",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  textBox: {
    display: "block",
    width: "60%",
    fontFamily:
      "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
    fontSize: "20px",
    color: "#EDEDF1",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  imgBox: {
    objectFit: "cover",
    width: "40%",
    height: "25vh",
    marginTop: "auto",
    marginRight: "1rem",
    marginBottom: "auto",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    background: "#221857",
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    minHeight: "100vh",
    // border: "0px solid blue"
  },
  container: {
    // marginRight: '200px',
    // marginLeft: '200px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "@media only screen and (max-width: 726px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "28px",
    padding: "3px 5px 3px 5px",
    backgroundColor: "#6A58c6",
    width: "90%",
    alignItems: "center",
    wordWrap: "break-word",
    marginBottom: "20px",
    borderRadius: "8px",
    whiteSpace: "initial",
    "@media only screen and (max-width: 726px)": {
      marginLeft: "0px",
      width: "100%",
      borderRadius: "5px",
    },
  },
  subhead: {
    wordWrap: "break-word",
    paddingTop: "10px",
  },

  box2: {
    width: "77vw",
    backgroundColor: "#6A58c6",
    padding: "20px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    // border: "1px solid red",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  descriptionBox: {
    display: "block",
    width: "100%",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  description: {
    fontFamily: "source-serif-pro, Georgia, Cambria, Times, serif",
    // fontSize: "20px",
    // lineHeight: "32px",
    // color: "#ededf1",
    // fontWeight: "400",
    wordWrap: "break-word",
    paddingTop: "10px",
  },
});

export default BlogPage;

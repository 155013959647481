import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    fullpage:{
        width:'100%',
        background:'black',
        display:'flex',
        paddingBottom:'50px',
        padding:'20px',
        // border:'1px solid red',
        overflowX:'hidden'
    },
    // subpage:{
    //     width:'100%'
    // },
    headdiv:{
        padding:'20px',
        justifyContent:'center',
        height:'80px',
        display:'flex',
    },
    head:{
        color:'white',
        fontFamily:'spartan',
        fontSize:'3vw'
    },
    contentdiv:{
        width:'100%',
        padding:'10px',
        display:'grid',
        gridTemplateColumns:'repeat(3,1fr)',
        gap:'10px'
    },
    content:{
        height:'auto',
        minHeight:'380px',
        width:'80%',
        backgroundColor:'white',
        padding:'10px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        fontFamily:'spartan',
        marginTop:'20px'
    },
    imgdiv:{
        width:'100%',
        height:'50%',
    },
    eventImage:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    eventname:{
        fontFamily:'spartan'
    },
    speaker:{
        color:'#838788',
        fontFamily:'spartan'
    },
    time:{
        fontSize:'18px'
    },
    bookbtn:{
        width:'100%',
        background:'rgb(141,255,204)',
        fontFamily:'spartan',
        cursor:'pointer',
        outline:'none',
        border:'none',
        marginTop:'10px',
        height:'30px',
        padding:'10px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    '@media (max-width:780px)': {
        head:{
            fontSize:'5vw',
            textAlign:'center'
        },
        contentdiv:{
            gridTemplateColumns:'repeat(1,1fr)',
        },
        content:{
            width:'100%',
            marginTop:'20px'
        }
    }
})

export {useStyles}
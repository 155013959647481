import React from "react";
import { Typography } from "@material-ui/core";
import { useState } from "react";
// import { Link } from 'react-router-dom'
import { useStyles } from "./style";

function CofounderProfileSelection({ setYouare }) {
  const classes = useStyles();
  const [result, setResult] = useState("");

  function clickHandlerStartup() {
    setResult("A Startup");
  }
  function clickHandlerIndividual() {
    setResult(" An Individual");
  }
  return (
    <div className={classes.fullpage}>
      <div className={classes.design2}></div>
      <div className={classes.design}></div>

      <Typography variant="h2" className={classes.heading}>
        You Are {result}
      </Typography>

      <div className={classes.innerdiv}>
        <button
          className={classes.curvediv}
          onClick={() => {
            setYouare("Startup looking for a Co Founder");
            clickHandlerStartup();
          }}
        >
          A StartUp looking for a Co Founder
        </button>
        <button
          className={classes.curvediv}
          onClick={() => {
            setYouare("An individual looking to join a team");
            clickHandlerIndividual();
          }}
        >
          An individual looking to join a team
        </button>
      </div>
    </div>
  );
}

export default CofounderProfileSelection;

import React, { useState } from "react";
import { Box, Button , IconButton, Typography, TextField, Divider } from "@mui/material";
import CouponCard from "./CouponCard";
import PaymentCard from "./PaymentCard";
import ImageStrip from "./ImageStrip";
import axios from "axios";
import { GET_PROMOCODES, USER_REFFERAL , CART_REMOVE_SINGLE } from "../../api/paymentCheckout";
import CouponList from "./CouponList";
import "../../stylesheets/paymentcheckout.css";
import { useParams , useHistory } from "react-router-dom";
import Footer from "../About/Footer";
import CloseIcon from '@mui/icons-material/Close';

const PaymentCheckout = () => {
  const params = useParams();
  const navigate = useHistory();
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  // const [codes, setCodes] = useState("");
  const [selectedCode, setSelectedCode] = useState([]);
  const [message, setMessage] = useState("");
  const [course, setCourse] = useState([]);
  const [user, setUser] = useState({});
  const [total, setTotal] = React.useState(0);
  const [ref , setRef] = React.useState(false);

    const removeItem = (cartItem) => {
      axios
      .post(CART_REMOVE_SINGLE+window.localStorage.id+"/"+cartItem.courseId,{courseName: cartItem.courseName})
      .then(res => {
        setRef(true);
      })
      .catch(err => {
      console.log(err);
      })
  }
  React.useEffect(() => {
    axios
      .get(USER_REFFERAL + params.userid)
      .then((response) => {
        setUser(response.data.data);
        if(response.data.data.cart.length == 0){
          window.location.replace('https://campus.markoknow.com')
        }
        const totalSum = response.data.data.cart
          .filter(
            (cart) =>
              cart.courseId !== "64fb08d372d0fde41877df63" &&
              cart.courseId !== "64fb15b672d0fde41877e193" &&
              cart.courseId !== "64fb105b72d0fde41877e101"
          )
          .reduce((acc, cart) => acc + cart.price, 0);

        setTotal(totalSum);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
    if (event.target.value === "") {
      setIsCouponApplied(false);
      setMessage("");
      setSelectedCode([]);
    }
  };
  const handleSetCode = (value) => {
    setSelectedCode(value);
    setCouponCode(value.code);
  };

  const handleApplyCoupon = async () => {
    try {
      const response = await axios.get(GET_PROMOCODES);
      const couponCodes = response.data.data;
      couponCodes.map((code) => {
        if (couponCode === code.code) {
          setSelectedCode(code);
        }
      });

      const selectedC = couponCodes.find((data) => data.code === couponCode);
      if (selectedC) {
        setIsCouponApplied(true);
        setMessage("Applied");
      } else if (!selectedC) {
        setIsCouponApplied(true);
        setMessage("No Code");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        p={0}
        sx={{
          marginTop: 0,
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mb={2} pl={3} pt={3}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ textAlign: "left", color: "#6A58C6", marginLeft: "70px" }}
          >
            Payment Summary
          </Typography>
        </Box>

        <Divider style={{ marginBottom: "20px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            "@media only screen and (min-width: 764px)": {
              flexDirection: "row",
              alignItems: "flex-start",
            },
          }}
          className="box_payment"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "70%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                marginBottom: "40px",
                "@media only screen and (min-width: 764px)": {
                  marginBottom: "100px",
                },
              }}
            >
              <div
                style={{width: '100%'}}
              >
                {user.cart &&
                  user.cart.find(
                    (cart) =>
                      cart.courseId === "64fb08d372d0fde41877df63" ||
                      cart.courseId === "64fb15b672d0fde41877e193" ||
                      cart.courseId === "64fb105b72d0fde41877e101"
                  ) && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center' , 
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color={"#6A58C6"}
                        style={{ fontWeight: "900" , display: 'flex' , gap: '20px' ,  }}
                      >
                        Product To Model : Startup Playbook <IconButton ><CloseIcon/></IconButton>
                      </Typography>
                      <Typography variant="subtitle1" color={"#6A58C6"}>
                        {" "}
                        Minimum Viable Product Building
                      </Typography>
                      <Typography variant="subtitle1" color={"#6A58C6"}>
                        {" "}
                        Go To Market Strategy
                      </Typography>
                      <Typography variant="subtitle1" color={"#6A58C6"}>
                        Business Model Canvas Creation
                      </Typography>
                    </Box>
                  )}
                {user.cart &&
                  user.cart
                    .filter(
                      (cart) =>
                        cart.courseId !== "64fb08d372d0fde41877df63" &&
                        cart.courseId !== "64fb15b672d0fde41877e193" &&
                        cart.courseId !== "64fb105b72d0fde41877e101"
                    )
                    .map((cart) => (
                      <Box
                      sx={{
                        display: 'flex' ,alignItems: 'center'  , justifyContent: 'space-between'
                      }}>

                      <Typography
                        key={cart.courseId}
                        variant="subtitle1"
                        color={"#6A58C6"}
                        sx={{
                         
                        }}
                      >
                        {cart.courseName} 
                      </Typography>
                      <Button variant="contained" onClick={(e) => removeItem(cart)}  sx={{background: '#6a58c6' , textTransform: 'none' , width: 80 , height: 20 , padding: 1 , fontSize: '14px'}}>Remove</Button>
                      </Box>

                    ))}

                <Typography variant="caption">Happy Learning !</Typography>
              </div>
            </Box>

            <Box mt={2} display="flex" flexDirection="column" width={"70%"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "8px",
                }}
              >
                <TextField
                  variant="standard"
                  size="small"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  placeholder="Enter The Coupon Code"
                />
                <Button
                  variant="standard"
                  // color="primary"
                  onClick={handleApplyCoupon}
                  // disabled={isCouponApplied}
                  style={{ color: "#6A58C6" }}
                >
                  Apply
                </Button>
              </div>

              {isCouponApplied ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <CouponCard
                    couponCode={selectedCode.code}
                    message={message}
                    selectedCode={selectedCode}
                  />
                </div>
              ) : (
                <CouponList handleSetCode={handleSetCode} />
              )}
              {/* (<Typography variant='h6'>
            No Codes Entered
        </Typography>) */}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <PaymentCard
              isCouponApplied={isCouponApplied}
              selectedCode={selectedCode}
              course={course}
              refr={ref}
            />
          </Box>
        </Box>
        <Box style={{ marginTop: "100px", marginBottom: "20px" }}>
          <ImageStrip />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default PaymentCheckout;

import React from "react";
// import { Link } from 'react-router-dom'
import { useStyles } from "./style";
import { TextField } from "@material-ui/core";
function CofounderLinkedIn({ setLinkedIn }) {
  const classes = useStyles();
  return (
    <div className={classes.fullpage}>
      <div className={classes.design2}></div>
      <div className={classes.design}></div>
      {/* <Typography variant='h2'>LinkedIn Profile </Typography> */}
      <TextField
        className={classes.input}
        placeholder="LinkedIn Profile"
        onChange={(event) => setLinkedIn(event.target.value)}
        rows={1}
        InputProps={{
          disableUnderline: true,
          maxLength: 5,
        }}
      />
    </div>
  );
}

export default CofounderLinkedIn;

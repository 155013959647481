import React from "react";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { POST_CONTACT } from "../../api/mainPage.js";
import { validateEmail } from "../auth/validateEmail";
import { Typography, Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import Fade from '@mui/material/Fade';
import Loader from "./Loader";
import Footer from "../About/Footer";
const DOMAIN = process.env.REACT_APP_DOMAIN;

const Contact = () => {
  const currencies = [
    {
      value: "General",
      label: "General",
    },
    {
      value: "Webite",
      label: "Website",
    },
    {
      value: "Payment",
      label: "Payment",
    },
  ];

  const [clicked, setClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fName, setFName] = React.useState("");
  const [lName, setLName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [wNumber, setWnumber] = React.useState("");
  const [iType, setIType] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [resume, setResume] = React.useState(null);
  const [emailError, setEmailError] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [numberError, setNumberError] = React.useState("");

  const isMobile = useMediaQuery("(max-width:838px)");

  const validate = () => {
    if (fName.length <= 0 && lName.length <= 0) return false;
    else if (wNumber.length !== 10) return false;
    else if (!validateEmail(email)) return false;
    else return true;
  };

  const handleApply = () => {
    if (validate()) {
      const data = new FormData();
      data.append("firstname", fName);
      data.append("lastname", lName);
      data.append("email", email);
      data.append("whatsappno", wNumber);
      data.append("issuetype", iType);
      data.append("description", description);
      if (resume) data.append("file", resume);

      axios
        .post(`${DOMAIN}/api/postcontact`, data)
        .then((response) => {
          console.log("success");
          console.log(response);
          setClicked(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Internal Server Error!");
      setResponse("Entered Wrong Data");
      alert(response);
    }

    if (validate()) {
      const data = new FormData();
      data.append("fName", fName);
      data.append("lName", lName);
      data.append("email", email);
      data.append("wNumber", wNumber);
      data.append("iType", iType);
      data.append("description", description);
      if (resume) data.append("resume", resume);

      setLoading(true);
      axios
        .post(POST_CONTACT, data)
        .then((response) => {
          console.log(response);
          setLoading(false);
          setClicked(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setResponse("Entered Wrong Data");
      alert(response);
    }
  };

  return (
    <div
      className="fullPage"
      style={{
        margin: 0,
        background: "linear-gradient(to top left, #6A58C6, #a8f5ff)",
      }}
    >
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant={!isMobile ? "h2" : "h4"}
            style={{
              color: "#000",
              fontWeight: "bold",
              fontFamily: "sans-serif",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            {" "}
            Contact Us{" "}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "#000",
              fontFamily: "sans-serif",
              marginBottom: "10px",
              textAlign: "center",
              fontSize: isMobile ? "15px" : "24px",
            }}
          >
            Reach out to us and raise any issue you are facing during
            navigation, checkout or anything challenging.{" "}
          </Typography>
        </Box>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: !isMobile ? 1 : 0.5 },
            width: isMobile ? "80%" : "50%",
            margin: "2%",
            borderRadius: "10px",
            background: "linear-gradient(to top left, #ffffffff,#ffffff33)",
            padding: "2% 6% 2% 6% ",
            display: clicked && "flex",
            justifyContent: clicked && "center",
            alignItems: clicked && "center",
            boxShadow: "2px 2px 5px #2773a5",
            height: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          {loading && <Loader />}
          {!loading && !clicked && (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: !isMobile ? "row" : "column",
                    width: "100%",
                  }}
                >
                  <TextField
                    required
                    id="outlined-password-input"
                    label="First Name"
                    InputLabelProps={{
                      style: {
                        color: "#000",
                        borderColor: "#000",
                        fontFamily: "Poppins",
                      },
                    }}
                    style={{
                      background: "#fff",
                      borderRadius: "10px",

                      borderColor: "#000",
                      width: !isMobile ? "50%" : "100%",
                    }}
                    onChange={(e) => setFName(e.target.value)}
                  />
                  <TextField
                    required
                    id="outlined-password-input"
                    label="Last Name"
                    InputLabelProps={{
                      style: {
                        color: "#000",
                        borderColor: "#000",
                        fontFamily: "Poppins",
                      },
                    }}
                    style={{
                      background: "#fff",
                      borderRadius: "10px",
                      borderColor: "#000",
                      width: !isMobile ? "50%" : "100%",
                    }}
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
                {/* <div 
      style={{
        display:'flex',
        flexDirection:!isMobile?'row':'column',
        width:'100%'
      }}
      > */}
                <TextField
                  required
                  id="outlined-password-input"
                  label="Email"
                  type="email"
                  InputProps={{
                    style: {
                      color: "#000",
                      borderColor: "#000",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#000",
                      borderColor: "#000",
                      fontFamily: "Poppins",
                    },
                  }}
                  FormHelperTextProps={{
                    style: { color: "red" },
                  }}
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    borderColor: "#000",
                    width: !isMobile ? "98%" : "100%",
                  }}
                  onChange={(e) => {
                    if (
                      !validateEmail(e.target.value) &&
                      e.target.value.length > 0
                    )
                      setEmailError("Enter A Correct Email");
                    else setEmailError("");
                    setEmail(e.target.value);
                  }}
                  helperText={emailError}
                />

                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: !isMobile ? "row" : "column",
                    width: "100%",
                  }}
                >
                  <TextField
                    required
                    id="outlined-password-input"
                    label="Whatsapp Number"
                    InputProps={{
                      style: {
                        color: "#000",
                        borderColor: "#000",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#000",
                        borderColor: "#000",
                        fontFamily: "Poppins",
                      },
                    }}
                    FormHelperTextProps={{
                      style: { color: "red" },
                    }}
                    style={{
                      background: "#fff",
                      borderRadius: "10px",
                      borderColor: "#000",
                      width: !isMobile ? "50%" : "100%",
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length > 10 &&
                        e.target.value.length !== 0
                      )
                        setNumberError("Enter A Correct Number");
                      else setNumberError("");
                      setWnumber(e.target.value);
                    }}
                    helperText={numberError}
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Issue Type"
                    InputLabelProps={{
                      style: {
                        color: "#000",
                        borderColor: "#000",
                        fontFamily: "Poppins",
                      },
                    }}
                    style={{
                      background: "#fff",
                      borderRadius: "10px",
                      borderColor: "#000",
                      width: !isMobile ? "50%" : "100%",
                    }}
                    onChange={(e) => setIType(e.target.value)}
                  >
                    {currencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* <div style={{
          display:'flex',
          flexDirection:'column',
          width:'100%'

        }}> */}
                <TextField
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  maxRows={4}
                  InputLabelProps={{
                    style: {
                      color: "#000",
                      borderColor: "#000",
                      fontFamily: "Poppins",
                    },
                  }}
                  style={{
                    background: "#fff",
                    borderRadius: "10px",

                    borderColor: "#000",
                    width: !isMobile ? "98%" : "100%",
                  }}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {/* </div> */}
                <div
                  style={{
                    margin: "1%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      width: !isMobile ? "50%" : "100%",
                      marginBottom: "20px",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      color: "white",
                      background: "#2773a5",
                      fontSize: "1rem",
                      fontFamily: "sans-serif",
                      fontWeight: "600",
                    }}
                  >
                    Upload Doc
                    <input
                      type="file"
                      onChange={(e) => setResume(e.target.files[0])}
                      hidden
                    />
                  </Button>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      width: !isMobile ? "50%" : "100%",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      color: "white",
                      background: "#2773a5",
                      fontSize: "1rem",
                      fontFamily: "sans-serif",
                      fontWeight: "600",
                    }}
                    onClick={handleApply}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )}
          {!loading && clicked && (
            <div
              style={{
                width: "70%",
              }}
            >
              <Typography
                variant="h4"
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "sans-serif",

                  fontWeight: "bold",
                }}
              >
                Thank You
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "sans-serif",

                  fontWeight: "bold",
                  wordWrap: "break-word",
                }}
              >
                Thanks for informing, we will be looking into your case and
                would be reaching out to you on the same
              </Typography>
            </div>
          )}
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;

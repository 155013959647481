import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  fullpage: {
    width: "100%",
    height: "80%",
    background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
    display: "flex",
    justifyContent: "center",
    // alignItems:'center'
  },
  centerdiv: {
    width: "60%",
    color: "white",
    padding: "10px",
    margin: "5px",
    // border:'1px solid white',
    display: "flex",
    // alignItems:'center',
    // justifyContent:'center',
    flexDirection: "column",
    textAlign: "center",
    paddingBottom: "100px",
  },
  head: {
    fontWeight: "bold",
    color: "white",
    fontFamily: "sans-serif",
    marginTop: "1.3rem",
  },
  formdiv: {
    margin: "1.5rem 10px 10px 10px ",
    padding: "3%",
    backdropFilter: "blur(20px)",
    // border: "1px solid white",
    borderRadius: "15px",
    background: "linear-gradient(to top left, #ffffffff,#ffffff33)",
    boxShadow: "2px 2px 5px #2773a5",
  },
  rowinput: {
    display: "flex",
    width: "100%",
    // border: "1px solid red",
    padding: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  inputcontainer: {
    width: "50%",
    //border:'1px solid yellow',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding:'5px'
  },
  input_box: {
    width: "90%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
  },
  input_box2: {
    width: "95%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
  },
  input_box3: {
    width: "95%",
    padding: "2px 10px 2px 10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffffff",
    border: "none",
    outline: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  input_file: {
    width: "95%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffffff",
    border: "none",
    outline: "none",
    textAlign: "center",
    color: "gray",
  },
  dropdown_label: {
    fontFamily: "sans-serif",
    color: "#757575",
    // border: "1px solid red",
  },
  dropdown: {
    width: "70%",
    padding: "5px",
    height: "35px",
    margin: "0",
    border: "none",
    outline: "none",
    // border: "1px solid red",
  },
  submitbtn: {
    width: "100%",
    padding: "8px",
    fontSize: "1.5rem",
    fontFamily: "sans-serif",
    fontWeight: "600",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    fontSize: "1.8rem",
    cursor: "pointer",
    color: "white",
    backgroundColor: "#2773a5",
    "&:hover": {
      backgroundColor: "#195081",
    },
  },

  "@media(max-width:900px)": {
    fullpage: {
      height: "45%",
      paddingBottom: "28%",
      overflow: "scroll",
    },
    centerdiv: {
      width: "100%",
      paddingBottom: "0",
    },
    head: {
      fontSize: "7vw",
    },
    submitbtn: {
      padding: "18px",
      fontSize: "1.5rem",
    },
    // rowinput:{
    //     flexDirection:'column'
    // },
    // inputcontainer:{
    //     width:'100%',
    //     margin:'5px'
    // },
    // inputbox:{
    //     width:'120%',
    //     margin:'10px',
    //     border:'2px solid red'
    // }
  },
  "@media(max-width:380px)": {
    centerdiv: {
      width: "100vw",
      paddingBottom: "0",
    },
    input_box: {
      fontSize: "1rem",
    },
    input_box2: {
      fontSize: "1rem",
    },
    submitbtn: {
      padding: "18px",
      fontSize: "1.3rem",
    },
  },
});

export { useStyles };

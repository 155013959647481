import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles ({
    
    fullpage : {
        height : '100vh',
        width: '100%',
        margin: '0',
        overflow : 'hidden',
        backgroundColor : '#7B67CA',
        display: 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        marginTop: '-2px'
    },
    innerdiv : {
        width : '80%',
        height : 'auto',
        backgroundColor : '#232325',
        borderRadius : '284px',
        textAlign :'center',
        color : 'white',
        boxShadow : '10px 10px 20px 0px black',
        paddingBottom : '20px',
        overflow: 'hidden',
        marginTop: '-50px',
    },
    heading: {
        marginTop: '0px',
        fontSize: '42px',
        fontWeight : 'bold',
    },
    para : {
        fontSize : '22px',
        marginTop : '-25px',
    },
    inputdiv :{
        display : 'inline',
        //justifyContent : 'spaceBetween'
    },
    heading2:{
        fontSize : '24px',
        fontWeight : 'bold',
        display : 'inline',
        margin : 'auto',
    },
    input : {
        height : '37px',
        width : '50%',
        border : 'none',
        textAlign : 'center',
        borderRadius : '2px',
        backgroundColor : 'white',
        color : 'black',
        margin : 'auto',
        display: 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        marginTop : '10px',
        marginBottom : '10px',
    },
    btn : {
        width : '15%',
        height : '37px',
        fontSize : '18px',
        fontWeight : 'bold',
        backgroundColor : '#6EFBD0',
        border : 'none',
        borderRadius : '10px',
        cursor : 'pointer',
        outline:'none'
    },
    btnsection : {
        width : '35.5%',
        height : '190px',
        backgroundColor : 'inherit',
        margin: 'auto',
        border : 'none',
        marginTop : '10px',
        
    },
    btns : {
        margin: '10px',
        width: '25%',
        height: '40px',
        borderRadius: '32px',
        border : 'none',
        cursor: 'pointer',
        fontSize: '0.8vw',
        fontWeight: 'bold',
        padding : '10px',
        '&:hover':{
            backgroundColor: '#806BD0',
        }
    },
    searchdiv : {
        height : '200px',
        width: '500px',
        color : 'white',
        backgroundColor : 'grey',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center',
        position : 'absolute',
        top : '0',
        bottom :'0',
        left : '0',
        right : '0',
        margin : 'auto'
    },
    '@media(min-width: 700px) and (max-width: 1000px)' : {
        fullpage : {
            overflow : 'scroll',
            height : '130vh',
            marginTop : '-5px',
        },
        innerdiv : {
            width : '80%',
            borderRadius : '20px',
            height : '80%',
            marginTop: '50px',
        },
        input: {
            width : '90%',
            borderRadius : '10px',
        },
        btn : {
            width : '90%'
        },
        btnsection : {
            width : '90%',
            height : '60%',
            margin : '10px 0px 10px 30px',
            overflowY : 'scroll'
        },
        btns : {
            width : '35%',
            fontSize : '2vw'
        }

    },
    '@media (max-width : 700px)' : {
        fullpage : {
            marginTop: '-5px'
        },
        innerdiv : {
            width : '90%',
            height : '70vh',
            borderRadius : '32px',
            overflow : 'hidden',
            boxShadow : '10px 10px 10px 0px black',
            marginTop: '-50px',
            marginBottom: '10px',
            //position : 'fixed'
        },
        heading : {
            fontSize : '8vw',
        },
        heading2 : {
            fontSize : '5vw',
        },
        para : {
            fontSize : '4vw',
        },
        input : {
            width : '90%',
            borderRadius : '10px',
        },
        btn : {
            width : '90%',
            margin : '0'
        },
        btnsection : {
            width : '90%',
           
            height : '60%',
            overflow: 'scroll'
        },
        btns : {
            width : '90%',
            fontSize : '4vw',
        }, 
    }
})

export { useStyles }
import React, { useState } from "react";
import "../../stylesheets/watch-now-form.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Alert from "../MessagePopup";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function WatchNowForm() {
  const history = useHistory();
  const { evt } = useLocation().state;
  console.log(evt);
  const [title, setTitle] = useState(evt ? evt.title : "");
  const [speaker, setSpeaker] = useState(evt ? evt.speaker : "");
  const [aboutSpeaker, setAboutSpeaker] = useState(evt ? evt.aboutSpeaker : "");
  const [link, setLink] = useState(evt ? evt.link : "");
  const [poster, setPoster] = useState("");
  // let tags=[];

  //alert
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleAlertClose = () => setOpenAlert(false);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("speaker", speaker);
    data.append("aboutSpeaker", aboutSpeaker);
    data.append("link", link);
    data.append("poster", poster);

    axios
      .post(`${DOMAIN}/watchnow/create`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAlertMessage("SuccessFully watchnow created!");
        setOpenAlert(true);
        history.push(`/watch_now`);
      })
      .catch((err) => {
        setAlertMessage("Please Try again later!");
        setOpenAlert(true);
      });
  };

  const editHandler = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("speaker", speaker);
    data.append("aboutSpeaker", aboutSpeaker);
    data.append("link", link);
    if (poster) data.append("poster", poster);

    axios
      .put(`${DOMAIN}/watchnow/${evt?._id}`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setAlertMessage("SuccessFully watchnow edited!");
        setOpenAlert(true);
        history.push(`/watch_now`);
      })
      .catch((err) => {
        setAlertMessage("Please Try again later!");
        setOpenAlert(true);
      });
  };

  return (
    <div className="a-box">
      {/* alert */}
      <Alert
        open={openAlert}
        handleAlertClose={handleAlertClose}
        message={alertMessage}
      />
      {/* alert end */}
      <form onSubmit={evt ? editHandler : submitHandler}>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          style={{ marginBottom: "10px" }}
        />
        <TextField
          id="outlined-basic"
          label="Speaker"
          variant="outlined"
          value={speaker}
          onChange={(e) => setSpeaker(e.target.value)}
          fullWidth
          style={{ marginBottom: "10px" }}
        />
        <TextField
          id="outlined-basic"
          label="About Speaker"
          variant="outlined"
          value={aboutSpeaker}
          onChange={(e) => setAboutSpeaker(e.target.value)}
          fullWidth
          style={{ marginBottom: "10px" }}
        />

        <TextField
          id="outlined-basic"
          label="Event Recorded Link"
          variant="outlined"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          fullWidth
          style={{ marginBottom: "10px" }}
        />

        <label> Select Poster for the event</label>
        <div class="custom-file test_input form-group">
          <input
            type="file"
            required={evt ? false : true}
            class="custom-file-input"
            id="customFile"
            onChange={(e) => setPoster(e.target.files[0])}
          />
        </div>

        <button type="submit" class="test_apply_btn">
          Submit
        </button>
      </form>
    </div>
  );
}

export default WatchNowForm;

import React from "react";
import { useState } from "react";
import { Button } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import axios from "axios";
import MessagePopup from "../MessagePopup";

require("dotenv").config();

const DOMAIN = process.env.REACT_APP_DOMAIN;

const WatchnowButtonBox = ({ Id, completedlist, streaks }) => {
  const [completed, setCompleted] = useState(false);
  const [inStreak, setInstreak] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleAlertClose = () => setOpenAlert(false);
  // console.log(Id,"id");
  // console.log(completedlist,"comp");
  // console.log(streaks,"str");
  const checkCompleted = () => {
    completedlist.map((item) => {
      if (Id == item) {
        setCompleted(true);
      }
    });
  };
  const checkInstreak = () => {
    streaks.map((item) => {
      if (Id == item) {
        setInstreak(true);
      }
    });
  };
  useEffect(() => {
    checkCompleted();
    checkInstreak();
  });

  //add to streak
  const AddtoStreak = (val) => {
    //console.log(val);
    const formdata = new FormData();
    formdata.append("watchnowId", val);
    axios
      .post(
        `${DOMAIN}/watchnow/streak/add`,
        { watchnowId: val },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((res) => {
        setAlertMessage("Video Added to Streak");
        window.location.reload();
        setOpenAlert(true);
      })
      .catch((err) => {
        alert("try again later");
        console.log(err);
      });
  };

  //remove from streak
  const RemovefromStreak = (val) => {
    axios
      .post(
        `${DOMAIN}/watchnow/streak/remove`,
        { watchnowId: val },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((res) => {
        setAlertMessage("Video Removed from Streak");
        setOpenAlert(true);
        setInstreak(false);
      })
      .catch((err) => {
        alert("try again later");
        console.log(err);
      });
  };

  return (
    <div>
      {completed ? (
        <Button
          startIcon={
            <CheckCircleOutlineIcon
              style={{ height: "1.5rem", width: "1.5rem" }}
            />
          }
          style={{
            backgroundColor: "#6a58c6",
            cursor: "pointer",
            width: "100%",
            color: "white",
            borderRadius: "0 0 0.5rem 0",
            height: "60px",
          }}
        />
      ) : inStreak ? (
        <Button
          startIcon={
            <DeleteIcon style={{ height: "1.5rem", width: "1.5rem" }} />
          }
          onClick={() => {
            RemovefromStreak(Id);
          }}
          style={{
            backgroundColor: "#6a58c6",
            cursor: "pointer",
            width: "100%",
            color: "white",
            borderRadius: "0 0 0.5rem 0",
            height: "60px",
          }}
        />
      ) : (
        <Button
          startIcon={
            <AddCircleOutlineIcon
              style={{ height: "1.5rem", width: "1.5rem" }}
            />
          }
          onClick={() => {
            AddtoStreak(Id);
          }}
          style={{
            backgroundColor: "#6a58c6",
            cursor: "pointer",
            color: "white",
            width: "100%",
            borderRadius: "0 0 0.5rem 0",
            height: "60px",
          }}
        />
      )}
      <MessagePopup
        open={openAlert}
        handleAlertClose={handleAlertClose}
        message={alertMessage}
      ></MessagePopup>
      {/* {
          <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >

          <Box sx={style}>
          <MessagePopup
          open={openAlert}
          handleAlertClose={handleAlertClose}
          message={alertMessage}
      />
      </Box>
      </Modal>
        } */}
    </div>
  );
};

export default WatchnowButtonBox;

/* eslint-disable no-useless-computed-key */
/* eslint-disable no-useless-escape */
import React from "react";
import "../../stylesheets/ideation.css";
import Grid from "@material-ui/core/Grid";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ShareIcon from "@material-ui/icons/Share";
import RateReviewIcon from "@material-ui/icons/RateReview";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Link, NavLink } from "react-router-dom";
import { Box, Button } from "@material-ui/core";

require("dotenv").config();
// const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
  btnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'yellow',
    width: "75%",
    marginLeft: "5%",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      flexDirection: "column",
    },
  },
  btnleftbox: {
    display: "flex",
    flex: "6",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "15%",
    },
  },
  btnrightbox: {
    display: "flex",
    flex: "6",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      marginLeft: "15%",
    },
  },
  btn: {
    backgroundColor: "#8dffcc",
  },
}));

function Ideation({ isAuth }) {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const isAuthUser = isAuth;
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">You are not Logged In</h2>
      <p className="simple-modal-desc" id="simple-modal-description">
        TO VIEW THIS PAGE, YOU NEED TO BE LOGGED IN TO THE SITE
      </p>
      <div className="buttons">
        <NavLink
          to="/login"
          onClick={handleClose}
          style={{ textDecoration: "none", color: "black" }}
        >
          <button className="btn btn-primary btn-lg">Login</button>
        </NavLink>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (isAuthUser) {
      handleClose();
    } else {
      setTimeout(function () {
        handleOpen();
      }, 2000);
    }
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className="ideation_container">
        <Grid container>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div className="left_box">
              <h1 className="header_big">Fastest</h1>
              <h1 className="header_big">Startup</h1>
              <h1 className="header_big">Ideation.</h1>
              {/* <div className="start_btn_container"> */}
              <NavLink
                to="/ideation/questionaire"
                className="start_btn_container"
                style={{ textDecoration: "none" }}
              >
                <button
                  type="submit"
                  className="test_apply_btn ideating_btn"
                  style={{ width: "80%", fontSize: "1.5rem" }}
                >
                  Start Ideating
                </button>
              </NavLink>
              <Box className={classes.btnbox}>
                <Box className={classes.btnleftbox}>
                  <Button className={classes.btn}>
                    <Link
                      to="/ideation/privatelist"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Private Ideations
                    </Link>
                  </Button>
                </Box>
                <Box className={classes.btnrightbox}>
                  <Button className={classes.btn}>
                    <Link
                      to="/ideation/publiclist"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Public Ideations
                    </Link>
                  </Button>
                </Box>
              </Box>
              {/* </div> */}
            </div>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div className="right_box">
              <div className="right_box_button">
                <EventNoteIcon className="iconStyle" />
                <h1 className="right_button_header_big">Organize</h1>
                <p className="right_button_header_small">
                  Organize startup ideas with Markoknow Ideation Template
                </p>
              </div>
              <div className="right_box_button">
                <ShareIcon className="iconStyle" />
                <h1 className="right_button_header_big">Share</h1>
                <p className="right_button_header_small">
                  Share Markoknow Ideation Templates with Friends and
                  Connections
                </p>
              </div>
              <div className="right_box_button">
                <RateReviewIcon className="iconStyle" />
                <h1 className="right_button_header_big">Feedback</h1>
                <p className="right_button_header_small">
                  Recieve Feedback and Support from Friends and Connections
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Ideation;

import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import IdeationComponent from './ideation'

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

const Usestyles=makeStyles({
    container:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:"#282828",
        flexDirection:'column'
    },
    heading:{
        fontSize:'60px',
        color:'white'
    }
});

const PublicIdeationList = () => {
    const classes=Usestyles();
    const [ideations, setIdeations] = useState([]);
    const id=window.localStorage.getItem("id");
    const [newCommentAdded, setNewCommentAdded] = useState(false);
    function getData(){
        axios
        .get(`${DOMAIN}/ideation/list/${id}`, {})
        .then((res) => {
            //console.log(res.data.ideations);
            setIdeations(res.data.ideations);
        }).catch(e => {
            console.log(e)
        });
    }

    useEffect(()=> {
        getData();
    },[]);

    useEffect(()=> {
        getData();
    },[newCommentAdded])
    
  return (
    <Box className={classes.container}>
        <Typography className={classes.heading}>Your Public Ideations</Typography>
        <Box>
            {
                ideations.filter((val)=>{
                    console.log(val);
                    if(val.public)return val;
                }).map((obj,i) => {
                    return(
                        <IdeationComponent
                            answers={obj.answers} 
                            comments={obj.comments} 
                            id={obj._id}
                            setNewCommentAdded={() => setNewCommentAdded(!newCommentAdded)}
                            ispublic={obj.public}
                        />
                    )
                })
            }
        </Box>
    </Box>
  )
}

export default PublicIdeationList
import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// import { display } from '@mui/system';
import axios from "axios";
const DOMAIN = process.env.REACT_APP_DOMAIN;

const UseStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'blue',
    marginTop: "40px",
  },
  profilebox: {
    //backgroundColor:'yellow',
    width: "70%",
    display: "flex",
    flexDirection: "column",
    "@media only screen and (max-width: 420px)": {
      width: "90%",
      display: "flex",
      flexDirection: "column",
    },
  },
  name: {
    fontSize: "48px",
    //backgroundColor:'red',
    display: "flex",
    flex: "9",
    "@media only screen and (max-width: 420px)": {
      fontSize: "30px",
      //backgroundColor:'red',
      display: "flex",
      flex: "9",
    },
  },
  editbtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: "3",
    //backgroundColor:'blue'
  },
  nameBox: {
    display: "flex",
    alignItems: "center",
    //backgroundColor:'yellow',
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    //backgroundColor:'blue'
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      //alignItems:'center',
      //backgroundColor:'blue',
      flexDirection: "column",
    },
  },
  info: {
    marginLeft: "3%",
    marginRight: "3%",
    fontSize: "24px",
  },
  detailsBox: {
    display: "flex",
    //backgroundColor:'yellow',
    flexDirection: "column",
  },
  details: {
    fontSize: "24px",
  },
  infoInput: {
    //backgroundColor:'yellow',
  },
  submitBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submitbtn: {
    backgroundImage: "#8dffcc",
  },
});

const ExperienceProfile = () => {
  const classes = UseStyles();
  const [updateMode, setUpdateMode] = useState(false);
  const [firstName, setFirstName] = useState("nfnegegeg");
  const [email, setMail] = useState("");
  const [college, setCollege] = useState("");
  const [contact, setPhno] = useState("");
  const [city, setCity] = useState("Hyderabad");
  const [role, setRole] = useState("Tech");
  const [courseAndYear, setWork] = useState("");
  const [inspire, setAns1] = useState("");
  const [industry, setAns2] = useState("");
  const [build, setAns3] = useState("");
  const [linkdln, setLinkdln] = useState("");
  const [file, setFile] = useState("");
  const id = window.localStorage.getItem("id");

  const getData = () => {
    axios
      .get(`${DOMAIN}/experienceprofile/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const profile = res.data[0];
        setFirstName(profile.firstName);
        setCollege(profile.college);
        setMail(profile.email);
        setPhno(profile.contact);
        setWork(profile.courseAndYear);
        setAns1(profile.inspire);
        setAns2(profile.industry);
        setAns3(profile.build);
        setLinkdln(profile.linkdln);
        setCity(profile.city);
        setFile(profile.filePath);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };
  const handleSubmit = () => {
    let data = new FormData();
    data.append("firstName", firstName);
    data.append("college", college);
    data.append("courseAndYear", courseAndYear);
    data.append("city", city);
    data.append("contact", contact);
    data.append("email", email);
    data.append("linkdln", linkdln);
    data.append("inspire", inspire);
    data.append("industry", industry);
    data.append("build", build);
    data.append("file", file);
    axios
      .put(`${DOMAIN}/experienceprofile`, data, {
        headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setUpdateMode(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box className={classes.wrapper}>
      <Card className={classes.profilebox}>
        {updateMode ? (
          <CardContent className={classes.nameBox}>
            <TextField
              fullWidth
              className={classes.name}
              defaultValue={firstName}
              label="Full Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </CardContent>
        ) : (
          <CardContent className={classes.nameBox}>
            <Typography className={classes.name}>{firstName}</Typography>
            <Button
              className={classes.editbtn}
              onClick={() => setUpdateMode(true)}
              startIcon={<EditIcon />}
            >
              EDIT{" "}
            </Button>
          </CardContent>
        )}
        <CardContent className={classes.infoBox}>
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={email}
              label="Email Id"
              onChange={(e) => setMail(e.target.value)}
            />
          ) : (
            <Typography className={classes.info}>{email}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={contact}
              label="Contact NO."
              onChange={(e) => setPhno(e.target.value)}
            />
          ) : (
            <Typography className={classes.info}>{contact}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={city}
              label="City"
              onChange={(e) => setCity(e.target.value)}
            />
          ) : (
            <Typography className={classes.info}>{city}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={role}
              label="Interest's"
              onChange={(e) => setRole(e.target.value)}
            />
          ) : (
            <Typography className={classes.info}>{role}</Typography>
          )}
        </CardContent>
        <CardContent className={classes.detailsBox}>
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={courseAndYear}
              label="Course and Year"
              onChange={(e) => setWork(e.target.value)}
            />
          ) : (
            <Typography className={classes.details}>{courseAndYear}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={college}
              label="College"
              onChange={(e) => setCollege(e.target.value)}
            />
          ) : (
            <Typography className={classes.details}>{college}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={inspire}
              label="What inspires you in Entrepreneurship?"
              multiline
              onChange={(e) => setAns1(e.target.value)}
            />
          ) : (
            <Typography className={classes.details}>{inspire}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={industry}
              label="What industry and work type you want to assume?"
              multiline
              onChange={(e) => setAns2(e.target.value)}
            />
          ) : (
            <Typography className={classes.details}>{industry}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={build}
              label="How would this help you in building your Startup? "
              multiline
              onChange={(e) => setAns3(e.target.value)}
            />
          ) : (
            <Typography className={classes.details}>{build}</Typography>
          )}
          {updateMode ? (
            <TextField
              fullWidth
              margin="normal"
              className={classes.infoInput}
              defaultValue={linkdln}
              label="LinkedlN"
              onChange={(e) => setLinkdln(e.target.value)}
            />
          ) : (
            <Typography className={classes.details}>{linkdln}</Typography>
          )}
          {updateMode ? (
            <Button variant="contained" component="label">
              Upload RESUME
              <input
                type="file"
                hidden
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Button>
          ) : (
            <a href={file} target="_blank" rel="noopener noreferrer">
              YOUR RESUME
            </a>
          )}
        </CardContent>
        {updateMode ? (
          <CardContent className={classes.submitBox}>
            <Button onClick={handleSubmit} className={classes.submitbtn}>
              UPDATE
            </Button>
          </CardContent>
        ) : null}
      </Card>
    </Box>
  );
};

export default ExperienceProfile;

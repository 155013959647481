import React, { useEffect, useState } from "react";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import AddIcon from '@material-ui/icons/Add';
import ReactReadMoreReadLess from 'react-read-more-read-less'
//The CSS stylings of this page is written in stylesheets/partnership.css file. 
require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;


const useStyles = makeStyles((theme) => ({
  addBtnContainer: {
    background: 'linear-gradient(to top left, #52efda 0%, #8dffcc 100%)',
    marginRight: '20px',
    height: '50px',
    width: '50px',
   '@media (max-width:420px)':{
      height: '35px',
      width: '35px',
      backgroundColor:'red'
    }
  },
  addIcon: {
    color: '#000',
    fontSize: '32px',
    '@media (max-width:420px)':{
      fontSize: '24px',
    }
  },
  para_icon_container:{
    display:'flex',
    justifyContent:'space-between',
    '@media(max-width:420px)':{
      //backgroundColor:'yellow',
      display:'flex',
      flexDirection:'column',
      padding:'0px'
    }
  },
  paragraph:{
    '@media(max-width:420px)':{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      //backgroundColor:'red',
      height:'50px'
    }
  },
  icon_container:{
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    marginBottom:'auto',
    '@media(max-width:420px)':{
      display:'flex',
      alignItems:'flex-end',
      justifyContent:'center',
      //backgroundColor:'blue',
      marginBottom:'5px'
    }
  },
  foundername:{
    color: "inherit", 
    textDecoration: "none",
    //backgroundColor:'yellow',
    display:'flex',
    alignItems:'center',
    marginTop:'4px',
    '@media(max-width:420px)':{
      color: "inherit", 
      textDecoration: "none",
      //backgroundColor:'yellow',
      display:'flex',
      alignItems:'center',
      marginTop:'7px',
    }
  },
  startupname:{
    //backgroundColor:'red', 
    width:'50%'
  },
  applybutton:{
    display:'flex',
    alignItems:'center',
    //backgroundColor:'blue', 
    width:'50%',
    justifyContent:'right'
  }
}));

export default function LiveProject() {
  const classes = useStyles();
  const [jobs,setJobs] = useState([]);
  function getData(){
    axios
      .get(`${DOMAIN}/get_open_position`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setJobs(res.data);
        console.log(res.data)
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  }

  useEffect(()=>{
    getData();
  },[])

  const isApplyExperienceFilled=window.localStorage.getItem("isApplyExperienceFilled");

  return (
    <div className="live_project_container">
      <div className="live_project_inner_container">
        <h2 className="form_heading live_project_header">
          Learn on a live project
        </h2>
        <div className={classes.para_icon_container}>
          <div className={classes.paragraph} >
            <p className="partner_para live_project_sub_header">
              Enhance your skills by learning to work from a live project of a
              Startup
            </p>
          </div>
          <div className={classes.icon_container}>
            <NavLink to="/experience/inspire">
              <IconButton aria-label="add-new" className={classes.addBtnContainer}>
                  <AddIcon className={classes.addIcon}/>
              </IconButton>
            </NavLink>
          </div>
          
        </div>
        
        {jobs.map((obj) => (
          <div className="live_project_apply_box" id={obj._id}>
          <div className="startup_name_box">
            <div className={classes.startupname}>
              <a
                href="#form_heading"
                style={{ color: "inherit", textDecoration: "none",  }}
              >
                <p className="partner_para links" style={{marginRight: '20px'}}>{obj.startupName}</p>
              </a>
            </div>
            <div className={classes.applybutton}>
                <Link to="/experience/apply" state={{positionID:`${obj._id}`}} >
                  <button onClick={()=>window.localStorage.setItem("positionID",obj._id)} className="test_apply_btn" style={{width: '100px'}}>Apply</button>
                </Link>
            </div>
          </div>

          <div className="position_box">
            <div style={{/*backgroundColor:'blue',*/ margin:'2px'}}>
              <h5 className="partner_para position_header" >
                <b>Founder :</b>
              </h5>
              <a 
               href="#form_heading" 
               className={classes.foundername}
               >
                <h5 className="partner_para links" >
                  {obj.fullName}
                </h5>
              </a>
            </div>
            <div>
              <h5 className="partner_para position_header" >
                <b>Positions Open :</b> {obj.positionOpen}
              </h5>
            </div>
            <div className="position_box_inner">
              <Grid container>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <p className="startup_description">
                   <b>Description  </b><br/>
                    <ReactReadMoreReadLess
                       charLimit={50}
                       readMoreText={"Read more"}
                       readLessText={"Read Less"}
                    >
                    {obj.jobDescription} 
                    </ReactReadMoreReadLess>
                  </p>
                </Grid>
                {/* <Grid item lg={1}></Grid> */}
              </Grid>
            </div>
          </div>

          <div className="apply_button_box">
              <p className="startup_description">
                <b>What can aspiring Entrepreneurs learn from you </b><br/>
                <ReactReadMoreReadLess
                       charLimit={50}
                       readMoreText={"Read more"}
                       readLessText={"    Read Less"}
                    >
                 {obj.whatCanLearn} 
               </ReactReadMoreReadLess>
              </p>
              

              {/* <div style={{ flex: 1 }}></div> */}
          </div>
          <Grid container >
            <Grid item lg={12} md={12} sm={12} xs={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <p className="partner_para2" style={{margin: ' 10px', display:'flex', justifyContent:'center', fontSize:'20px'}}>{obj.mail}</p>
              {/* <p className="partner_para" style={{margin: ' 10px'}}>{obj.contact}</p> */}
            </Grid>
          </Grid>


        </div>
        ))}
        
      </div>
    </div>
  );
}

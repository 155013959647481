import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  fullpage: {
    width: "100%",
    height: "80%",
    background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
    display: "flex",
    justifyContent: "center",
    // alignItems:'center'
  },
  head: {
    fontWeight: "bold",
    color: "white",
    fontFamily: "sans-serif",
    marginTop: "2rem",
  },
  centerdiv: {
    width: "60%",
    color: "white",
    padding: "10px 10px 0px 10px",

    margin: "5px",
    // border: "1px solid white",
    display: "flex",
    // alignItems:'center',
    // justifyContent:'center',
    flexDirection: "column",
    textAlign: "center",
    paddingBottom: "100px",
  },
  formdiv: {
    margin: "2rem 10px 10px 10px ",
    padding: "3%",
    borderRadius: "10px",
    backdropFilter: "blur(20px)",
    // border: "1px solid white",
    background: "linear-gradient(to top left, #ffffffff,#ffffff33)",
    boxShadow: "2px 2px 5px #2773a5",
  },
  rowinput: {
    display: "flex",
    width: "100%",
    // border: "1px solid red",
    padding: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  inputcontainer: {
    width: "50%",
    // border: "1px solid yellow",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding:'5px'
  },
  input_box: {
    width: "90%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
  },
  input_box2: {
    width: "95%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
  },
  submitbtn: {
    width: "100%",
    padding: "8px",
    fontSize: "1.5rem",
    fontFamily: "sans-serif",
    fontWeight: "600",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    color: "white",
    backgroundColor: "#2773a5",
  },
  "@media(max-width:1400px)": {
    centerdiv: {
      width: "85%",
    },
  },

  "@media(max-width:480px)": {
    fullpage: {
      height: "45%",
      paddingBottom: "28%",
    },
    centerdiv: {
      width: "100%",
      paddingBottom: "0",
    },
    head: {
      fontSize: "7vw",
    },
    submitbtn: {
      padding: "18px",
      fontSize: "1.2rem",
    },
  },
  "@media(max-width:345px)": {
    input_box: {
      fontSize: "1rem",
    },
    input_box2: {
      fontSize: "1rem",
    },
    submitbtn: {
      padding: "18px",
      fontSize: "1rem",
    },
  },
});
export { useStyles };

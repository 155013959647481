import {makeStyles} from '@material-ui/core'
const useStyles = makeStyles({
    fullpage : {
        // margin: '0px',
        backgroundColor : '#806BD0',
        height :'60vh',
        width:'100%',
        overflow:'hidden',
        // position : 'fixed'
    },

    btnsection:{
        margin: 'auto',
        width: '50%',
        border: 'none',
        padding : '10px',
        backgroundColor: '#C8BEE1',
        marginTop: '10px',
        height: '35vh',
        overflow:'hidden'
    },

    bottomline:{
        color: 'white',
        textAlign: 'center',
        padding : '5px',
        marginTop:'50px'
    },
    output : {
        color : 'black',
        margin: 'auto',
        marginTop: '10px',
        width: '50%',
        border: 'none',
        height: '45px',
        backgroundColor: 'white',
        display: 'flex',
        // justifyContent: 'center',
        alignItems:'center',
        textIndent: '20px',
        position:'relative',
    },
    // innerbtn:{
    //     width: '20%',
    //     height:'100%',
    //     backgroundColor: '#8DFFCC',
    //     textAlign: 'center',
    //     border: '1px solid red',
    //     cursor: 'pointer',
    //     position: 'absolute',
    //     right: '0px',
    //     display: 'flex',
    //     alignItems : 'center',
    //     justifyContent: 'center',
    // },

    btns : {
        margin: '10px',
        width: '29%',
        height: '50px',
        borderRadius: '32px',
        border : 'none',
        cursor: 'pointer',
        fontSize: '13px',
        fontWeight: 'bold',
        padding : '10px',
        '&:hover':{
            backgroundColor: '#806BD0',
        }
    },
    design : { 
        display: 'inline',
        float: 'right',
        width: '400px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '60px',
        position: 'absolute',
        top : '0px',
        right: '0px',
        borderRadius: '60px 0px 0px 60px'
    },
    design2 : { 
        display: 'inline',
        float: 'right',
        width: '200px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '40px',
        position: 'absolute',
        top: '30px',
        borderRadius: '0px 20px 20px 0px'
    },
    '@media(min-width: 1000px) and (max-width: 1200px)':{
        btns : {
            width: '40%',
        },
        btnsection: {
            marginBottom: '20px',
            overflowY:'scroll'
        }
    },
    '@media(min-width: 700px) and (max-width: 1000px)' :{
        btns : {
            width: '85%',
        },
        
        btnsection: {
            marginBottom: '20px',
            overflowY:'scroll'
        },
        output: {
            fontSize: '1.5vw'
        },
        design: {
            width: '25%',
            height: '40px',
        },
        design2: {
            width: '15%',
            height: '30px',
        }
    },
    '@media(max-width: 700px)' : {
        
        innerbtn :{
            width: '15%',
            fontSize:'2.5vw',
        },
        btns: {
            width: '85%',
            fontSize: '3.5vw',
            borderRadius: '15px',
        },
        btnsection: {
            width: '80%',
            marginBottom: '20px',
            overflowY:'scroll'
        },
        output: {
            width: '95%',
            fontSize: '3vw'
        },
        design: {
            width: '25%',
            height: '40px',
            opacity : '0.2'
        },
        design2: {
            width: '15%',
            height: '30px',
            opacity : '0.2',
        }
    },
})
export {useStyles}
import React from "react";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { NavLink } from "react-router-dom";
import "../../stylesheets/Navbar.css";
import NotificationBar from './NotificationBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    outline: "black",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: "black",
    flexGrow: 1,
    //backgroundColor:'yellow',
    fontSize: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbar: {
    //backgroundColor:'red',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "5.5rem",
  },
}));

function NavbarTopM({ isAuth, setIsAuth }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    setAnchorEl(null);
    setIsAuth(false);
    window.localStorage.clear();
  };

  return (
    <>
    
    <div className={classes.root}>
      <AppBar
        position="static"
        className="Navbar"
        style={{ backgroundColor: "black", height: "auto" }}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            <a
              href="/"
              style={{
                textDecoration: "none",
                fontSize:"30px",
                color: "white",
                fontWeight: "bold",
                backgroundColor: '#251e4f',
                marginRight: "70px",
                width: "70%",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              Markoknow&trade;
            </a>
          </Typography>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {isMobile && (
                <AccountCircle fontSize="large" className="account_icon" />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {isAuth ? (
                <div>
                  <NavLink
                    to={"/profile/" + window.localStorage.getItem("id")}
                    exact
                    style={{ textDecoration: "none", color: "black" }}
                    className="nav-link"
                  >
                    <MenuItem onClick={handleClose}>
                      {window.localStorage.getItem("name")}
                    </MenuItem>
                  </NavLink>
                  <NavLink
                    to="/contact"
                    exact
                    style={{ textDecoration: "none", color: "black" }}
                    className="nav-link"
                  >
                    <MenuItem onClick={handleClose}>CONTACT US</MenuItem>
                  </NavLink>
                  <NavLink
                    to="/feedback"
                    exact
                    style={{ textDecoration: "none", color: "black" }}
                    className="nav-link"
                  >
                    <MenuItem onClick={handleClose}>FEEDBACK</MenuItem>
                  </NavLink>
                  <MenuItem onClick={logoutHandler}>LOGOUT</MenuItem>
                </div>
              ) : (
                <div>
                  <MenuItem onClick={logoutHandler}>
                    <NavLink
                      to="/signup"
                      exact
                      style={{ textDecoration: "none", color: "black" }}
                      className="nav-link"
                    >
                      SIGNUP
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={logoutHandler}>
                    <NavLink
                      to="/login"
                      exact
                      style={{ textDecoration: "none", color: "black" }}
                      className="nav-link"
                    >
                      LOGIN
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={logoutHandler}>
                    <NavLink
                      to="/feedback"
                      exact
                      style={{ textDecoration: "none", color: "black" }}
                      className="nav-link"
                    >
                      FEEDBACK
                    </NavLink>
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
     <div className={classes.root}>
      <NotificationBar/>
    </div>
    </>
 
  );
}

export default NavbarTopM;

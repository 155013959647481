import {
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  Modal,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import LoginwithGoogle from "../auth/LoginwithGoogle";
import Axios from "axios";
import LoginFloat from '../LoginModal';
const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:'black'
  },
  leftbox: {
    opacity: '0.8',
    //backgroundColor:'yellow',
    display: "flex",
    alignItems: "center",
    textAlign: 'center',
    justifyContent: "center",
    // flexDirection: "column",
    backgroundColor: "black",
    border: "2px solid white",
    width: "40%",
    marginTop: "30vh",
    marginLeft: "55vh",
    height: "50vh",
    borderRadius: "12px",
    objectFit: "contain",

    "@media screen and (max-width: 480px)": {
      width: "80%",
      marginLeft: '50px',
      marginTop: '50%',
    }
  },
  text: {
    color: "white",
    fontSize: "50px",
    margin: "10px",
    fontFamily: "Josefin Sans, sans-serif",
  },
  btnbox: {
    display: "flex",
    //backgroundColor:'blue',
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    "@media screen and (max-width: 480px)": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "5px",
    },
  },
  btn: {
    color: "black",
    backgroundColor: "#85FCCD",
    width: "30%",
    height: "40px",
    margin: "5px",
    fontWeight: "bold",
    borderRadius: "30px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
    "@media screen and (max-width: 480px)": {
      color: "black",
      backgroundColor: "#85FCCD",
      width: "30%",
      height: "45px",
      margin: "5px",
      fontWeight: "bold",
      borderRadius: "30px",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "white",
      },
    },
  },
  suggestbtn: {
    backgroundColor: "#52EFDA",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
  },
  inputbox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    marginTop: "20px",
  },
  input: {
    width: "60%",
    backgroundColor: "white",
  },
  loginboxcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "200px",
    "@media screen and (max-width: 480px)": {
      width: "100px",
      height: "200px",
    }
  },
  loginbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    width: "30%",
    backgroundColor: "#85FCCD",
    flexDirection: "column",
    borderRadius: "4px",
    "@media screen and (max-width: 480px)": {
      width: "100px",
      height: "200px",
    }
  },
});

const DOMAIN = process.env.REACT_APP_DOMAIN;

function FloatPage({time,isAuth,setIsAuth}){
const [boxOpenLogin, setBoxOpenLogin] = useState(false);
  const classes = useStyles();
  const [boxOpen, setBoxOpen] = useState(true);
 
  const [PriorityAnswer, setPriorityAnswer] = useState("none");

//   const timer=()=>{
//       setTimeout(()=>{
//           setBoxOpen(true);
//       },time);
// }


    const handleSubmit = (value) => {
      setPriorityAnswer(value);
      setBoxOpenLogin(true);
    };
    const handleclose = () => {
      setBoxOpen(false);
    };
return(
  <>
  <Box  className={classes.container}>  
  <Modal open={boxOpen} onClose={handleclose} style={{backgroundColor: "rgba(0, 0, 0, .7)"}}>
  <>
    <Grid container className={classes.leftbox}>
      <Grid item xs={12} sm={12} lg={6}>
        <Typography className={classes.text}>
          What Do You Need To Start Up.
        </Typography>
        <Box className={classes.btnbox}>
          <Button
            className={classes.btn}
            onClick={() => handleSubmit("Learn")}
          >
            Learn
          </Button>
          <Button
            className={classes.btn}
            onClick={() => handleSubmit("Find Co-Founder")}
          >
            Find Co-Founder
          </Button>
          <Button
            className={classes.btn}
            onClick={() => handleSubmit("Hire")}
          >
            Hire
          </Button>
        </Box>
        <Box className={classes.btnbox}>
          <Button
            className={classes.btn}
            onClick={() => handleSubmit("Ideation")}
          >
            Ideation
          </Button>
          <Button
            className={classes.btn}
            onClick={() => handleSubmit("Brainstorm")}
          >
            Brainstorm
          </Button>
        </Box>
      </Grid>
      <Box className={classes.loginboxcontainer}>
     <LoginFloat setIsAuth={setIsAuth} open={boxOpenLogin} setOpen={setBoxOpenLogin}/>
     </Box>
    </Grid>
  </>
  </Modal>
  </Box>
  </>
);
}

export default FloatPage
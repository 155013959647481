import React,{useState , useEffect} from "react";
import { Container, Grid, Typography,Box } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Button } from "@material-ui/core";
import VideocamIcon from "@mui/icons-material/Videocam";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Axios from 'axios';

const DOMAIN = process.env.REACT_APP_DOMAIN;

const Cards = () => {
  const classes = useStyle();
  const [learnData, setLearnData] = useState([]);

  useEffect(() => {
    Axios
      .get(`${DOMAIN}/learn`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLearnData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <Container>
    <Grid container spacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {
          learnData.map(event => {
            return (
              <Grid item xs={12} md={4}>
              <Card elevation={3} className={classes.card}>
              <Link
                to={event.link}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button
                  className={classes.button}
                  type="submit"
                  startIcon={<VideocamIcon />}
                >
                  {event.name}
                </Button>
              </Link>
              <CardContent>
                <Typography className={classes.text}>
                  {event.content}
                </Typography>
              </CardContent>
            </Card>
            </Grid>
            )
          })
        }
    </Grid>
  </Container>
  );
};

export default Cards;

const useStyle = makeStyles({
  card: {
    background: "linear-gradient(to right, #86FFCF, #812EE9)",
    borderRadius: 10,
    height: 300,
  },
  button: {
    fontSize: 21,
    marginTop: 20,
    color: "white",
    padding: 15,
    backgroundColor: "#1B1B1C",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
      color: "#1B1B1C",
      fontSize: 21,
    },
    borderRadius: 100,
    boxShadow: "5px 6px 6px #1B1B1C",
  },
  text: {
    fontSize: 18,
    color: "black",
  },
});

import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import FadeIn from "react-fade-in";
import Footer from "../About/Footer";
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import SendIcon from "@mui/icons-material/Send";
// import TextField from '@mui/material/TextField';
import Axios from "axios";

const DOMAIN = process.env.REACT_APP_DOMAIN;
const u = makeStyles({
  body: {
    backgroundColor: "black",
    color: "white",
    paddingTop: "100px",
  },
  container: {
    marginRight: "100px",
    marginLeft: "100px",
    "@media only screen and (max-width: 480px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  heading: {
    // fontFamily: "Garet ExtraBold",
    textAlign: "center",
    fontSize: "31px",
    fontWeight: "bold",
  },
  subheading: {
    fontFamily: "MADE Evolve Sans",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    marginBottom: "50px",
    width: "500px",
    marginLeft: "300px",
    "@media only screen and (max-width: 480px)": {
      marginLeft: "0px",
      marginBottom: "30px",
      width: "100%",
    },
  },
  boxes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space=between",
    "@media only screen and (max-width: 480px)": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "10px 10px 10px 10px",
      justifyContent: "center",
    },
  },
  boxesTwo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    "@media only screen and (max-width: 480px)": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "10px 10px 10px 10px",
      justifyContent: "center",
    },
  },
  breakLine: {
    marginTop: "30px",
    width: "102%",
  },
  booking: {
    marginTop: "50px",
    backgroundColor: "#3d3c3d",
    display: "flex",
    width: "900px",
    height: "200px",
    flexDirection: "column",
    textAlign: "center",
    marginLeft: "300px",
    borderRadius: "8px",
    fontSize: "18",
    "@media only screen and (max-width: 480px)": {
      width: "100%",
      height: "200px",
      marginLeft: "0px",
    },
  },
  session: {
    zIndex: "1",
    backgroundColor: "#9A4CDF",
    width: "200px",
    height: "40px",
    color: "black",
    borderRadius: "12px",
    marginTop: "-20px",
    paddingTop: "10px",
    position: "absolute",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "350px",
    "@media only screen and (max-width: 480px)": {
      marginLeft: "145px",
    },
  },
  sessionText: {
    marginTop: "50px",
    wordWrap: "break-word",
    width: "58%",
    marginLeft: "150px",
    "@media only screen and (max-width: 480px)": {
      marginLeft: "30px",
      width: "80%",
    },
  },
  btn1: {
    backgroundColor: "#7affc9",
    width: "40%",
    marginLeft: "250px",
    marginTop: "30px",
    color: "black",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "145px",
    },
  },
  btn2: {
    backgroundColor: "#7affc9",
    width: "10%",
    float: "right",
    borderRadius: "12px",
    color: "black",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "@media only screen and (max-width: 480px)": {
      marginTop: "20px",
      width: "50%",
      alignItems: "center",
    },
  },
  btn3: {
    backgroundColor: "#7affc9",
    width: "40%",
    marginLeft: "180px",
    marginTop: "30px",
    color: "black",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "@media only screen and (max-width: 480px)": {
      marginTop: "2px",
      marginLeft: "100px",
    },
  },
  modalBox: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    color: "#9A4CDF",
    fontSize: "31px",
    fontWeight: "bold",
    backgroundColor: "black",
    width: "30%",
    marginTop: "35vh",
    marginLeft: "75vh",
    height: "40vh",
    // borderRadius: "12px",
    "@media screen and (max-width: 480px)": {
      display: "flex",
      flexDirection: "column",
      width: "80%",
      height: "45vh",
      marginLeft: "50px",
      marginTop: "50%",
    },
  },
  textBox: {
    color: "white",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "initial",
    wordWrap: "break-word",
    objectFit: "contain",
    overflowWrap: "break-word",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      //backgroundColor:'red'
    },
  },
  text: {
    height: "200px",
    width: "400px",
    fontSize: "30px",
    "@media only screen and (max-width: 480px)": {
      width: "300px",
      fontSize: "25px",
    },
  },
  letters: {
    marginTop: "20px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    color: "white",
  },
});
function Startup() {
  const classes = u();
  const [boxOpen, setBoxOpen] = useState(false);
  const [submitProblem, setSubmitProblem] = useState(false);
  const [problem, setProblem] = useState("");
  const [problemList, setProblemList] = useState([]);
  const handleclose = () => {
    setBoxOpen(false);
  };
  useEffect(() => {
    Axios.get(`${DOMAIN}/startupProblem/read`).then((response) => {
      // setProblemList([...problemList,response.data.problem])
      // setProblemList(JSON.parse(JSON.stringify(response.data)));
      console.log(response.data);
      //;
    });
  }, []);

  const handleSubmit = (e) => {
    Axios.post(`${DOMAIN}/startupProblem/create`, { probs: e })
      .then(() => {
        console.log("Created");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <Grid className={classes.heading}>
          <p>Post</p>
          <p style={{ color: "#9A4CDF" }}>Startup Problems</p>
          <Button
            className={classes.btn2}
            onClick={() => {
              setBoxOpen(true);
            }}
          >
            Post a Startup Problem
          </Button>
        </Grid>
        <Grid className={classes.subheading}>
          <p>
            Go Anonymous and post your Startup Problems that are stopping you
            from achieving your milestones
          </p>
        </Grid>

        <Modal
          open={boxOpen}
          onClose={handleclose}
          style={{ backgroundColor: "rgba(255,255,255,0.5)" }}
        >
          <>
            {submitProblem ? (
              <>
                <Grid container className={classes.modalBox}>
                  <p
                    style={{
                      marginTop: "80px",
                      marginBottom: "20px",
                      textAlign: "center",
                      alignItems: "center",
                      fontSize: "30px",
                      justifyContent: "center",
                      "@media screen and (max-width: 480px)": {
                        marginBottom: "5px",
                      },
                    }}
                  >
                    Thank You For Your Response
                  </p>
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      color: "white",
                      fontSize: "20px",
                      marginLeft: "30px",
                      marginRight: "30px",
                      textAlign: "center",
                      "@media screen and (max-width: 480px)": {
                        marginBottom: "5px",
                      },
                    }}
                  >
                    Your Startup Problem will be posted shortly within 10-15
                    minutes
                  </p>
                  <Button
                    variant="contained"
                    className={classes.btn3}
                    onClick={() => {
                      setSubmitProblem(false);
                    }}
                  >
                    Go Back
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid container className={classes.modalBox}>
                  <p
                    style={{
                      marginTop: "20px",
                      marginBottom: "10px",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Post A Startup Problem
                  </p>
                  <Box className={classes.textBox}>
                    <textarea
                      placeholder={"Enter your problem"}
                      maxlength="100"
                      onChange={(e) => setProblem(e.target.value)}
                      className={classes.text}
                      variant="outlined"
                    />
                  </Box>
                  <div className={classes.letters}>
                    <p
                      style={{
                        alignItems: "flex-start",
                        justifyContent: "space-around",
                      }}
                    >
                      100 letters
                    </p>
                    <SendIcon
                      style={{ color: "red", size: "20px" }}
                      onClick={() => {
                        handleSubmit(problem);
                        setSubmitProblem(true);
                      }}
                    />
                  </div>
                </Grid>
              </>
            )}
          </>
        </Modal>

        <Grid className={classes.boxes} style={{ overflow: "hidden" }}>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ff5757",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              {problemList}
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#7affc9",
              "@media only screen and (max-width: 480px)": {
                marginTop: "20px",
                marginRight: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ffbd59",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
                marginRight: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>

              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          className={classes.boxesTwo}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            overflow: "hidden",
          }}
        >
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ffbd59",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ff5757",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.boxes} style={{ overflow: "hidden" }}>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#7affc9",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ff5757",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ffbd59",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <hr className={classes.breakLine} />

        <Grid
          className={classes.boxesTwo}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            overflow: "hidden",
          }}
        >
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ffbd59",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ff5757",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.boxes} style={{ overflow: "hidden" }}>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#7affc9",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ff5757",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 450,
              height: 150,
              borderRadius: 5,
              backgroundColor: "#ffbd59",
              "@media only screen and (max-width: 480px)": {
                marginRight: "20px",
                marginTop: "20px",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div"></Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.booking}>
          <Grid className={classes.session}>Booking Session</Grid>
          <Grid className={classes.sessionText}>
            Understand Your Stage and the Kind of Support Markoknow can give you
            to Kickstart and Succeed at Your Startup Journey
          </Grid>
          <Button
            variant="contained"
            className={classes.btn1}
            onClick={() => {
              console.log("hey");
            }}
          >
            Book A Session
          </Button>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Startup;

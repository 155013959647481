/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import "../../stylesheets/partnership.css";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import { makeStyles, withStyles, Typography, Button } from "@material-ui/core";
//import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { Box } from "@mui/material";
require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
  submitbtn: {
    display: "flex",
    justifyContent: "flex-start",
    /*backgroundColor:'yellow',*/
    width: "15%",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      justifyContent: "flex-start",
      /*backgroundColor:'yellow',*/
      width: "35%",
    },
  },
  applyboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "200px",
  },
  applybox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    width: "30%",
    backgroundColor: "white",
    flexDirection: "column",
    borderRadius: "4px",
  },
  applyboxbtns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  applyboxheading1: {
    fontSize: "22px",
    margin: "5px",
  },
  applyboxheading2: {
    fontSize: "18px",
    margin: "5px",
  },
  applyboxbtn1: {
    backgroundColor: "#90EE90",
  },
  applyboxbtn2: {
    backgroundColor: "#D3D3D3	",
  },
}));

const StyledUploadButton = withStyles({
  label: {
    fontSize: "1.2rem",
    textTransform: "none",
    ["@media only screen and (max-width: 720px)"]: {
      fontSize: "0.8rem",
    },
    ["@media only screen and (max-width: 420px)"]: {
      fontSize: "0.7rem",
    },
    ["@media only screen and (max-width: 360px)"]: {
      fontSize: "0.6rem",
    },
    ["@media only screen and (max-width: 320px)"]: {
      fontSize: "0.55rem",
    },
    ["@media only screen and (max-width: 280px)"]: {
      fontSize: "0.45rem",
    },
  },
})(Button);

function ApplyForm() {
  // let body ="";
  //////////////////////////////////////////
  const [firstName, setFirstName] = useState("");
  const [email, setMail] = useState("");
  const [college, setCollege] = useState("");
  const [contact, setPhno] = useState("");
  const [city, setCity] = useState("");
  const [courseAndYear, setWork] = useState("");
  const [inspire, setAns1] = useState("");
  const [industry, setAns2] = useState("");
  const [build, setAns3] = useState("");
  const [linkdln, setLinkdln] = useState("");
  const classes = useStyles();
  // const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();
  // const location=useLocation();
  const positionID = window.localStorage.getItem("positionID");
  console.log(positionID, "position id");
  const [filled, setFilled] = useState(false);
  const [applyboxopen, setApplyboxopen] = useState(false);
  const [mssgboxopen, setMssgboxopen] = useState(false);
  const id = window.localStorage.getItem("id");

  const getData = () => {
    axios
      .get(`${DOMAIN}/experienceprofile/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const profile = res.data[0];
        //make it data[0] if it doesnt work
        //console.log(res);
        console.log(profile, "ye raha");
        if (profile !== undefined) {
          setFirstName(profile.firstName);
          setCollege(profile.college);
          setMail(profile.email);
          setPhno(profile.contact);
          setWork(profile.courseAndYear);
          setAns1(profile.inspire);
          setAns2(profile.industry);
          setAns3(profile.build);
          setLinkdln(profile.linkdln);
          setCity(profile.city);
          setFile(profile.filePath);
          setFilled(true);
        } else {
          console.log("fill nahi kiya");
        }
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  const Filehandler = (value) => {
    setFile(value);
  };
  const handleSubmit = (e) => {
    //console.log(firstName,college , courseAndYear , city ,  contact , email , linkdln , inspire , industry , build , file);
    e.preventDefault();
    let data = new FormData();
    data.append("firstName", firstName);
    data.append("college", college);
    data.append("courseAndYear", courseAndYear);
    data.append("city", city);
    data.append("contact", contact);
    data.append("email", email);
    data.append("linkdln", linkdln);
    data.append("inspire", inspire);
    data.append("industry", industry);
    data.append("build", build);
    data.append("file", file);
    if (
      firstName &&
      college &&
      courseAndYear &&
      city &&
      contact &&
      email &&
      linkdln &&
      inspire &&
      industry &&
      build &&
      file &&
      !filled
    ) {
      axios
        .post(`${DOMAIN}/experiene/create`, data, {
          headers: { authorization: window.localStorage.getItem("token") },
        })
        .then((res) => {
          console.log(res.status);
          setApplyboxopen(true);
          if (res.status === 422) {
            <h2 id="simple-modal-title">Error Occured</h2>;
          } else {
            <h2 id="simple-modal-title">Thank You</h2>;
          }
          if (res.status === 422) {
            <p className="simple-modal-desc" id="simple-modal-description">
              PLEASE TRY AGAIN!!!
            </p>;
          } else {
            <p className="simple-modal-desc" id="simple-modal-description">
              MARKOKNOW WARMLY ACCEPTS YOUR EXPERIENCE APPLICATION.
            </p>;
          }
          <div className="buttons">
            <Link
              to="/dashboard"
              onClick={handleClose}
              style={{ textDecoration: "none", color: "black" }}
            >
              <button className="btn btn-primary btn-lg">Dashboard</button>
            </Link>
          </div>;
        });
    } else if (
      firstName &&
      college &&
      courseAndYear &&
      city &&
      contact &&
      email &&
      linkdln &&
      inspire &&
      industry &&
      build &&
      file &&
      filled
    ) {
      axios
        .put(`${DOMAIN}/experienceprofile`, data, {
          headers: {
            Authorization: window.localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("update hua");
          setApplyboxopen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please fill all the details");
    }
  };
  const Finalapply = () => {
    axios
      .post(
        `${DOMAIN}/position/apply/${positionID}`,
        {},
        {
          headers: {
            Authorization: window.localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log("application submitted");
        setApplyboxopen(false);
        setMssgboxopen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const afterApply = () => {
    setMssgboxopen(false);
    window.location.href = "/experience";
  };
  const applybox = (
    <Box className={classes.applyboxContainer}>
      <Box className={classes.applybox}>
        <Typography className={classes.applyboxheading1}>
          Your Experience Profile have been updated!
        </Typography>
        <Typography className={classes.applyboxheading2}>
          Are you sure to apply with current Experience Profile ?
        </Typography>
        <Box className={classes.applyboxbtns}>
          <Button
            onClick={() => Finalapply()}
            className={classes.applyboxbtn1}
            style={{ margin: "10px" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setApplyboxopen(false)}
            className={classes.applyboxbtn2}
            style={{ margin: "10px" }}
          >
            No
          </Button>
        </Box>
      </Box>
    </Box>
  );
  const mssbox = (
    <Box className={classes.applyboxContainer}>
      <Box className={classes.applybox}>
        <Typography className={classes.applyboxheading1}>
          Your application has been submitted, thank you
        </Typography>
        <Box className={classes.applyboxbtns}>
          <Button onClick={() => afterApply()} className={classes.applyboxbtn1}>
            Okay
          </Button>
        </Box>
      </Box>
    </Box>
  );
  return (
    <div className="apply_form">
      <h2 className="form_heading">Apply for Experience</h2>
      <p className="partner_para">
        Know the Journey of Entrepreneurship from someone who understand this{" "}
      </p>
      <p className="partner_para">better than you</p>

      {filled ? (
        <Alert severity="info">
          Already you have created Experience, you can edit and apply again.
        </Alert>
      ) : null}

      <form className="organise_form" onSubmit={handleSubmit}>
        {/* {console.log("User", user)} */}
        <div class="partnership_input">
          <input
            type="text"
            name="fullName"
            class="action_input"
            autocomplete="off"
            defaultValue={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="Full Name"
          />
          <input
            type="text"
            name="college"
            class="action_input"
            autocomplete="off"
            defaultValue={college}
            onChange={(e) => setCollege(e.target.value)}
            required
            placeholder="college"
          />
          <input
            type="text"
            name="course"
            class="action_input "
            autocomplete="off"
            defaultValue={courseAndYear}
            onChange={(e) => setWork(e.target.value)}
            placeholder="course and year"
          />
          <input
            type="text"
            name="city"
            class="action_input "
            autocomplete="off"
            defaultValue={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="city"
          />
          <input
            type="contact"
            name="contact"
            class="action_input "
            autocomplete="off"
            defaultValue={contact}
            onChange={(e) => setPhno(e.target.value)}
            placeholder="contact No:"
          />
          <input
            type="email"
            name="mail"
            class="action_input "
            autocomplete="off"
            defaultValue={email}
            onChange={(e) => setMail(e.target.value)}
            placeholder="Email.id"
          />
          <input
            type="text"
            name="linkedin"
            class="action_input "
            autocomplete="off"
            defaultValue={linkdln}
            onChange={(e) => setLinkdln(e.target.value)}
            placeholder="linkdln"
          />
        </div>
        <div class="partnership_input">
          <textarea
            type="text"
            name="inspire"
            class="action_input1"
            autocomplete="off"
            defaultValue={inspire}
            onChange={(e) => setAns1(e.target.value)}
            required
            placeholder="What inspires you in Entrepreneurship? "
          />
          <textarea
            type="text"
            name="industry"
            class="action_input1 "
            autocomplete="off"
            defaultValue={industry}
            onChange={(e) => setAns2(e.target.value)}
            required
            placeholder="What industry and work type you want to assume? "
          />
          <textarea
            type="text"
            name="build"
            class="action_input1 "
            autocomplete="off"
            defaultValue={build}
            onChange={(e) => setAns3(e.target.value)}
            placeholder="How would this help you in building your Startup? "
          />
          {/* <label htmlFor="contained-button-file" className="action_btn1"/> */}
          {/* <StyledUploadButton
              component="span"
              onClick={() => console.log("upload info")}
              className="action_btn1"
              size="small"
            >
              Upload File
            </StyledUploadButton> */}
          {/* <input
              id="contained-button-file"
              type="file"
              style={{ display: "none" }}
            /> */}
          <label htmlFor="contained-button-file" className="file_input">
            <StyledUploadButton
              component="span"
              className="file_input"
              size="small"
            >
              Upload Resume
            </StyledUploadButton>
            <input
              id="contained-button-file"
              onChange={(e) => Filehandler(e.target.files[0])}
              type="file"
              style={{ display: "none" }}
            />
          </label>
        </div>
        <div className={classes.submitbtn}>
          <button
            type="submit"
            value="apply"
            class="action_btn1"
            style={{ width: "100%" }}
          >
            Submit
          </button>
        </div>
      </form>
      <div>
        <Modal open={applyboxopen} onClose={() => setApplyboxopen(false)}>
          {applybox}
        </Modal>
        <Modal open={mssgboxopen} onClose={() => afterApply()}>
          {mssbox}
        </Modal>
      </div>
    </div>
  );
}

export default ApplyForm;

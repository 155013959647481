import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import "../../stylesheets/about.css";
import MemberAbout from "../Dashboard/MemberAbout";
import { Carousel } from "3d-react-carousal";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CardDemoAbout from "../eventList/CardDemoAbout";
import TestimonialCard from "../Testimonials/testimonials";
import TextField from "@material-ui/core/TextField";
import MessagePopup from "../MessagePopup/index";
import Questionaire from "./questionaire";
import PartnerShip from "../partnership/partnerShip";
import UpperBox from "./components/UpperBox";
import Footer from "./Footer";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 450,
    //   textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    ["@media only screen and (max-width: 420px)"]: {
      width: 250,
      marginLeft: 0, // secondary
    },
  },
  certificate: {
    width: "92%",
  },
  buttonBox: {
    padding: "20px",
  },
  toggleButtonBox: {
    root: {
      borderRadius: 50,
      height: "3rem",
      width: "10rem",
      backgroundColor: "#dfe6e9",
      margin: "2%",
      boxShadow: "none",
      textTransform: "none",
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#000",
        color: "#fff",
      },
      "&:focus": {
        boxShadow: "none",
      },
    },
  },
  toggleButtonBoxSelected: {
    backgroundColor: "#000",
    color: "#fff",
  },
  confirmModal: {
    display: "flex",
    position: "absolute",
    width: "20%",
    //   height: "70%",
    backgroundColor: theme.palette.background.paper,
    ["@media only screen and (max-width: 420px)"]: {
      width: 250,
      marginLeft: 0, // secondary
    },
  },
  cropImageModal: {
    // display: 'flex',
    position: "absolute",
    width: "50%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    color: "#000",
    animationDuration: "550ms",
    position: "relative",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function About({ isAuth, setIsAuth }) {
  const [members, setMembers] = useState([]);
  const [members2, setMembers2] = useState([]);
  const [members3, setMembers3] = useState([]);
  let listItems = [];
  const isLogin = isAuth;
  const [values, setValues] = useState({
    name: "",
    designation: "",
    testimonial: "",
    email: "",
    linked: "",
  });
  const [file, setFile] = useState(null);
  const [input_val, setInputVal] = useState(Date());
  const [resp, setResp] = useState("");
  const [msg, setMsg] = useState("");
  const referralId = window.localStorage.getItem("referralId");
  const link = window.location.href + `signup?referrer=` + referralId;
  const [copiedOpen, setCopiedOpen] = useState(false);
  const [preQuestionsOpen, setPreQuestionsOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMsg("");
    setResp("");
  };

  const handleName = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      name: event.target.value,
    }));
  };

  const handleDesignation = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      designation: event.target.value,
    }));
  };

  const handleTestimonial = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      testimonial: event.target.value,
    }));
  };

  const handleEmail = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleLinkedIn = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      linked: event.target.value,
    }));
  };

  useEffect(() => {
    getAllMember();
  }, []);

  const getAllMember = () => {
    axios
      .get(`${DOMAIN}/api/dashboard`)
      .then((res) => {
        setMembers(res.data.result);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };

  useEffect(() => {
    getAllMember2();
  }, []);

  const getAllMember2 = () => {
    axios
      .get(`${DOMAIN}/api/eventAbout`)
      .then((res) => {
        setMembers2(res.data.result);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };
  if (members2) {
    members2
      .reverse()
      .slice(0, 3)
      .map((member) =>
        listItems.push(<CardDemoAbout key={member._id} member={member} />)
      );
  }

  useEffect(() => {
    getAllMember3();
  }, []);

  const getAllMember3 = () => {
    axios
      .get(`${DOMAIN}/api/testimonials`)
      .then((res) => {
        setMembers3(res.data.result);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  };

  const clearset = () => {
    setValues({
      name: "",
      designation: "",
      email: "",
      linked: "",
      testimonial: "",
    });
    setFile(null);
    setInputVal(Date());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", values.name);
    data.append("desig", values.designation);
    data.append("linkedin", values.linked);
    data.append("email", values.email);
    data.append("testimonial", values.testimonial);
    data.append("file", file);
    if (file === null) {
      setMsg("Select image to change profile pic");
    } else {
      axios
        .post(`${DOMAIN}/api/testimonialform`, data)
        .then((response) => {
          setResp(response.data);
          clearset();
          setAlertMessage("your application has been sucessfully submmited");
          setOpenAlert(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const openCopiedModal = () => {
    setCopiedOpen(true);
    setTimeout(() => {
      setCopiedOpen(false);
    }, 900);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <MessagePopup
        open={openAlert}
        handleAlertClose={() => setOpenAlert(false)}
        message={alertMessage}
      />
      {msg.length > 0 && <p className="test_msg">{msg}</p>}
      <form onSubmit={handleSubmit}>
        <div class="testimonial_input">
          <input
            type="text"
            value={values.name}
            onChange={handleName}
            class="test_input "
            placeholder="Name"
          />
          <input
            type="contact"
            value={values.designation}
            onChange={handleDesignation}
            class="test_input "
            placeholder="Designation"
          />
        </div>
        <input
          type="email"
          value={values.email}
          onChange={handleEmail}
          class="test_input1 "
          required
          placeholder="Mail ID"
        />
        <textarea
          rows="3"
          cols="5"
          maxlength="180"
          type="text"
          value={values.testimonial}
          onChange={handleTestimonial}
          class="test_textarea1 "
          required
          placeholder="Testimonial"
        />
        <input
          type="text"
          value={values.linked}
          onChange={handleLinkedIn}
          class="test_input1 "
          placeholder="LinkedIn"
        />
        <br />
        <label className="test_file_input">
          Upload Image:
          <input
            className="test_file_input"
            key={input_val}
            required
            onChange={handleFile}
            type="file"
            size="large"
            accept="image/png, image/gif, image/jpeg"
          />
        </label>
        <br />
        <button
          type="submit"
          value={"organise"}
          onClick={(e) => {
            handleSubmit(e, "value");
          }}
          class="test_btn1"
        >
          Submit
        </button>
      </form>
    </div>
  );

  return (
    <div className="About_page">
      <Box
        boxShadow={4}
        bgcolor="white"
        m={1}
        p={1}
        className="Box_about"
        id="partner-page"
      >
        {/* ////////////////Build Network Portion/////////////////// */}
        <div>
          {isAuth ? (
            <div className="build_box">
              <>
                <h1 className="about_heading">Get Friends onboard.</h1>
                <p className="about_para">
                  Earn 50 Markoknow points on onboarding your friends with you.
                </p>
                <div className="about_buttons">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        disabled={true}
                        value={link}
                        className={clsx(classes.margin, classes.textField)}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <button
                        className="about_btn"
                        onClick={() => {
                          navigator.clipboard.writeText(link);
                          openCopiedModal();
                        }}
                      >
                        COPY
                      </button>
                      <Modal
                        open={copiedOpen}
                        onClose={() => setCopiedOpen(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div style={modalStyle} className={classes.paper}>
                          Link Copied!
                        </div>
                      </Modal>
                    </Grid>
                  </Grid>
                </div>
              </>
            </div>
          ) : (
            <UpperBox setIsAuth={setIsAuth} />
          )}
        </div>
        <Questionaire isAuth={isAuth} preQuestionsOpen={preQuestionsOpen} />
        <div className="event_demo">
          {listItems.length && (
            <Carousel
              className="carousel_event"
              slides={listItems}
              autoplay={false}
            />
          )}
        </div>
        {/* ////////////////Member Portion/////////////////// */}
        <div className="member_demo">
          <div className="view_detail">
            <div className="apply_button">
              <button
                onClick={() => (window.location.href = "/membership")}
                className="test_apply_btn"
              >
                APPLY FOR MEMBERSHIP
              </button>
            </div>
          </div>
          <MobileView>
            <div
              style={{
                marginBottom: "5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                marginTop: "2rem",
              }}
            >
              <div className="row">
                {members &&
                  members
                    .slice(0, 3)
                    .map((member) => (
                      <MemberAbout key={member._id} member={member} />
                    ))}
              </div>
            </div>
          </MobileView>
          <BrowserView>
            <Grid container className="container">
              <Grid item lg={4} md={4} sm={6} xs={12}>
                {members &&
                  members.slice(0, 3).map((member, i) => {
                    if (i % 3 === 0)
                      return <MemberAbout key={member._id} member={member} />;
                  })}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                {members &&
                  members.slice(0, 3).map((member, i) => {
                    if (i % 3 === 1)
                      return <MemberAbout key={member._id} member={member} />;
                  })}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                {members &&
                  members.slice(0, 3).map((member, i) => {
                    if (i % 3 === 2)
                      return <MemberAbout key={member._id} member={member} />;
                  })}
              </Grid>
            </Grid>
          </BrowserView>
          <div className="view_detail">
            <div className="apply_button">
              <button
                onClick={() => (window.location.href = "/dashboard")}
                className="test_apply_btn"
              >
                View More
              </button>
            </div>
          </div>
        </div>
        {/* ////////////////Partnership Portion/////////////////// */}
        <div className="partnership_box">
          <PartnerShip />
        </div>
        {/* ////////////////Testimonial Portion/////////////////// */}
        <div className="testimonials_demo">
          <Grid container className="testimonial_head">
            <Grid item md={9} sm={12} xs={12}>
              <div>
                <h1
                  className="partner_heading"
                  style={{
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  Testimonial.
                </h1>
              </div>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              {isBrowser && (
                <div className="test_apply">
                  <button
                    onClick={handleOpen}
                    className="btn-primary btn-lg test_apply_btn"
                  >
                    Write a Testimonial
                  </button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    {body}
                  </Modal>
                </div>
              )}
            </Grid>
          </Grid>
          <p className="partner_para">
            Let others know your experience at Markoknow
          </p>
          {isMobile && (
            <div className="test_apply">
              <button
                onClick={handleOpen}
                className="btn-primary btn-lg test_apply_btn"
              >
                Write a Testimonial
              </button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {body}
              </Modal>
            </div>
          )}
          <MobileView>
            <div className="container cont1" style={{ marginBottom: "5rem" }}>
              <div className="row">
                {members3 &&
                  members3
                    .slice(0, 3)
                    .map((member, i) => (
                      <TestimonialCard
                        key={member._id}
                        member={member}
                        color1={i}
                      />
                    ))}
              </div>
            </div>
          </MobileView>
          <BrowserView>
            <Grid className="grid_testimonials" container spacing={1}>
              <Grid className="grid_test" item md={4} sm={6} xs={12}>
                {members3 &&
                  members3.slice(0, 3).map((member, i) => {
                    if (i % 3 === 0) {
                      return (
                        <TestimonialCard
                          key={member._id}
                          member={member}
                          color1={i}
                        />
                      );
                    }
                  })}
              </Grid>
              <Grid className="grid_test" item md={4} sm={6} xs={12}>
                {members3 &&
                  members3.slice(0, 3).map((member, i) => {
                    if (i % 3 === 1) {
                      return (
                        <TestimonialCard
                          key={member._id}
                          member={member}
                          color1={i}
                        />
                      );
                    }
                  })}
              </Grid>
              <Grid className="grid_test" item md={4} sm={6} xs={12}>
                {members3 &&
                  members3.slice(0, 3).map((member, i) => {
                    if (i % 3 === 2) {
                      return (
                        <TestimonialCard
                          key={member._id}
                          member={member}
                          color1={i}
                        />
                      );
                    }
                  })}
              </Grid>
            </Grid>
          </BrowserView>
          <div className="view_detail">
            <div className="apply_button">
              <button
                onClick={() => (window.location.href = "/testimonials")}
                className="btn-primary btn-lg test_apply_btn"
              >
                View More
              </button>
            </div>
          </div>
        </div>
        <Footer isAuth={isAuth} />
      </Box>
    </div>
  );
}

export default About;

import React from "react";
// import { useLocation } from "react-router-dom";
import getVideoId from "get-video-id";

function Video({ link }) {
  const { id } = getVideoId(link);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "0px",
        }}
      >
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube-nocookie.com/embed/${id}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </>
  );
}
export default Video;

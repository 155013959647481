import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

function Startup() {
  const classes = useStyle();
  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Card elevation={3} className={classes.card}>
            <Link
              to="/ideation"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                className={classes.button}
                type="submit"
                startIcon={<EmojiObjectsIcon />}
              >
                IDEATION
              </Button>
            </Link>
            <CardContent>
              <Typography className={classes.text}>
                Jot down your thoughts into a planned idea with simple fill-ins
                and share and get friends and mentors suggest and help in
                brainstorming
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={3} className={classes.card}>
            <Link
              to="/cofounderskillsearch"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                type="submit"
                className={classes.button}
                startIcon={<GroupIcon />}
              >
                Co Founder
              </Button>
            </Link>
            <CardContent>
              <Typography className={classes.text}>
                {/* Book Live */}
                {/* <li>Events</li>
                                <li>Sessions</li>
                                <li>Workshops</li>
                                <li>Meetups</li> */}
                Get a co founder who fits your requirement with the perfect
                skillset and mindset that you need to make your journey complete
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={3} className={classes.card}>
            <Link
              to="/wiillcomesoon"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                type="submit"
                className={classes.button}
                startIcon={<PeopleOutlineIcon />}
              >
                HIRE FOUNDING TEAM
              </Button>
            </Link>
            <CardContent>
              <Typography className={classes.text}>
                Inspire as a founder and hire people on the vision with the
                hiring portal for a founding team
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Startup;

const useStyle = makeStyles({
  card: {
    background: "linear-gradient(to right, #86FFCF, #812EE9)",
    borderRadius: 10,
    height: 300,
  },
  button: {
    fontSize: 21,
    marginTop: 20,
    color: "white",
    padding: 15,
    backgroundColor: "#1B1B1C",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
      color: "#1B1B1C",
      fontSize: 21,
    },
    borderRadius: 100,
    boxShadow: "5px 6px 6px #1B1B1C",
  },
  text: {
    fontSize: 18,
    color: "black",
  },
});

import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useHistory } from "react-router-dom";
// import { BASE_URL } from '../../api/client';
// import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "#fff",
  },
  tickIcon: {
    fontSize: "6rem",
    color: "#4CAF50",
    marginBottom: "10px",
  },
  message: {
    color: "#6A58C6",
  },
  button: {
    "&:hover": {
      //   backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function PaymentSuccess() {
  const navigate = useHistory();
  const classes = useStyles();
  const [countdown, setCountdown] = React.useState(3);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 1) {
        setCountdown(countdown - 1);
      } else {
        window.location.replace("https://campus.markoknow.com/dashboard/"+window.localStorage.id+"?url=courses");
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [countdown, navigate]);
  return (
    <div className={classes.container}>
      <CheckCircleOutlineIcon className={classes.tickIcon} />
      <Typography variant="h5" className={classes.message}>
        Payment Successful!
      </Typography>
      <Card
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          bordetStyle: "dotted",
        }}
      >
        <CardContent
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ marginBottom: "10px", fontWeight: "Bold" }}
          >
            Happy Learning !
          </Typography>
          <Typography
            variant="caption"
            style={{ textAlign: "left", fontWeight: "Bold" }}
          >
            A brief on how you can proceed to acquire the knowledge from your
            favourite course
          </Typography>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              paddingLeft: "30px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="caption"
              style={{ textAlign: "left", fontSize: "10px" }}
            >
              1. Please practice along with your instructor, this is a major
              benefit of self paced courses.
            </Typography>
            <Typography
              variant="caption"
              style={{ textAlign: "left", fontSize: "10px" }}
            >
              2. In case of doubts, you can take help from your mentor by
              dropping the message in the chat box in the course page.
            </Typography>
            <Typography
              variant="caption"
              style={{ textAlign: "left", fontSize: "10px" }}
            >
              3. Submit all your assignments and exercises to the mentor and we
              will provide you with feedback.
            </Typography>
            <Typography
              variant="caption"
              style={{ textAlign: "left", fontSize: "10px" }}
            >
              4. At the end of the course you can ask your mentor for the
              certificate.
            </Typography>
            <Typography
              variant="caption"
              style={{ textAlign: "left", fontSize: "10px" }}
            >
              5. You will also avail the Feesback once you have completed the
              course within 3 months of enrollment
            </Typography>
          </div>
          <Typography
            variant="overline"
            style={{
              textAlign: "left",
              fontWeight: "Bold",
              marginBottom: "10px",
            }}
          >
            All The Best For Your Learning Experience
          </Typography>
          <Typography
            variant="caption"
            style={{ color: "red" }}
            className={classes.timer}
          >
            Redirecting to Courses in {countdown} seconds...
          </Typography>

          <Button
            variant="outlined"
            style={{ color: "#6A58C6", borderColor: "#6A58C6", width: "50%" }}
          >
            <a
              href={"https://campus.markoknow.com/dashboard/"+window.localStorage.id+'?url=courses'}
              style={{ color: "#6A58C6" }}
            >
              Continue To Course Page
            </a>
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
export default PaymentSuccess;
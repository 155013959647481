import React from 'react'
import styled,{keyframes} from 'styled-components';

const animationName = keyframes`
0% {opacity: 0;
  transform: translateY(-100px) skewY(10deg) skewX(10deg) ;
  
}
25% {opacity: 1;
  transform: translateY(0px) skewY(0deg) skewX(0deg);
 
}
75% {opacity: 1;
  transform: translateY(0px) skewY(0deg) skewX(0deg);
 
}
`
export default function TextAnimation({ text }) {
  const reactArray = text.split();
  
  return <Wrapper>
  {reactArray.map((item, index) => (
    <span key={index}>{item}</span>
  ))}
</Wrapper>
}

const Wrapper=styled.span`
  display:  inline-block;
  fontFamily:"Josefin Sans, sans-serif";
  span{
    display:  inline-block;
    opacity: 1;
    animation: ${animationName};
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
  
span:nth-child(1) {
  animation-delay: 0.3s;
}
span:nth-child(3) {
  animation-delay: 0.5s;
}
span:nth-child(5) {
  animation-delay: 0.7s;
}
span:nth-child(7) {
  animation-delay: 0.9s;
}
span:nth-child(9) {
  animation-delay: 0.11s;
}
span:nth-child(11) {
  animation-delay: 0.13s;
}
span:nth-child(13) {
  animation-delay: 0.15s;
}
span:nth-child(15) {
  animation-delay: 0.17s;
}
span:nth-child(17) {
  animation-delay: 0.19s;
}
span:nth-child(19) {
  animation-delay: 0.21s;
}
`

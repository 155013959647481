import { Box, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles=makeStyles({
    main:{
        display:"flex",
        height:'8rem',
        textAlign:'center'
    },
    left:{
        backgroundColor:"#806BD1",
        display:"flex",
        flex:3
    },
    mid:{
        backgroundColor:"#806BD1",
        display:"flex",
        flex:6,
        flexDirection:'column',
        paddingLeft:40
    },
    right:{
        backgroundColor:"#806BD1",
        display:"flex",
        flex:3,
        justifyContent:'right'
    },
    innerleft:{
        backgroundColor:"#85A4D0",
        height:'3rem',
        width:'20rem',
        borderRadius:"0px 30px  30px 0px",
        marginTop:30
    },
    text:{
        marginTop:10,
        color:'white'
    },
    innerright:{
        backgroundColor:"#85A4D0",
        height:'5rem',
        width:'30rem',
        borderRadius:"40px 0px  0px 40px",
    },
});

const Header = () => {
    const classes=useStyles();
    return (
            <Box Container className={classes.main}>
                <Box item className={classes.left}>
                    <Box item className={classes.innerleft}/>
                </Box>
                <Box item className={classes.mid}>
                    <Typography variant='h2' className={classes.text}><b>Skills</b></Typography>
                    <Typography variant='h5'className={classes.text}>Select Top Skills that <b>You have</b> as a Co-Founder</Typography>
                </Box>
                <Box item className={classes.right}>
                    <Box item className={classes.innerright}/>
                </Box>
            </Box>
    )
}

export default Header

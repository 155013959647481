import React, { useEffect } from "react";
import "../../stylesheets/partnership.css";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ButtonStyled = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 15,
  fontStyle: "none",
  padding: "2px 2px 2px 2px",
  border: "1px solid",
  lineHeight: 3,
  width: "100%",
  backgroundColor: "#52efda",
  borderColor: "#52efda",
  color: "black",
  "&:hover": {
    backgroundColor: "#8dffcc",
    borderColor: "#8dffcc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#8dffcc",
    borderColor: "#8dffcc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function PartnerShip() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
  }, []);

  return (
    <div style={{ padding: "5%" }}>
      <div style={{ textAlign: "center" }}>
        <h2>Partner with us </h2>
      </div>
      <div style={{ textAlign: "center", marginTop: "3%", marginBottom: "5%" }}>
        <p>
          Be the partner to Markoknow to explore the opportunities for your
          business.
        </p>
      </div>
      <div>
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid
            item
            lg={6}
            md={4}
            sm={12}
            style={{ textAlign: "center", width: "100%" }}
          >
            {/* <NavLink
              to="/partnership/organise"
              style={{ textDecoration: "none" }}
            >
              <ButtonStyled variant="contained">
                <span style={{ fontSize: "1rem" }}>
                  {" "}
                  Organise Events with Markoknow
                </span>
              </ButtonStyled>
            </NavLink> */}
            <NavLink
              to="/partnerwithus"
              style={{ textDecoration: "none" }}
            >
              <ButtonStyled variant="contained">
                <span style={{ fontSize: "1rem" }}>
                  {" "}
                  Partner With Us
                </span>
              </ButtonStyled>
            </NavLink>
          </Grid>
          {/* <Grid
            item
            lg={6}
            md={4}
            sm={12}
            style={{ textAlign: "center", width: "100%" }}
          >
            <NavLink
              to="/partnership/sponser"
              style={{ textDecoration: "none" }}
            >
              <ButtonStyled variant="contained">
                <span style={{ fontSize: "1rem" }}>
                  Sponsor Event at Markoknow
                </span>
              </ButtonStyled>
            </NavLink>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}

export default PartnerShip;

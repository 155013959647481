import { Box, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#806BD1",
    alignItems: "center",
    height: "36.5rem",
  },
  upperbox: {
    display: "flex",
    width: "45rem",
    height: "4rem",
    margin: 10,
    backgroundColor: "white",
  },
  skillitemsbox: {
    display: "flex",
    width: "38rem",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    listStyleType: "none",
  },
  nxtbtn: {
    backgroundColor: "#8AFFCA",
    width: "7rem",
    borderRadius: "0 0 0 0",
    fontSize: 20,
    height: "4rem",
    "&:hover": {
      backgroundColor: "#8AFFCA",
    },
  },
  lowerbox: {
    width: "40rem",
    backgroundColor: "#C8BEE1",
    margin: 5,
  },
  skillbtn: {
    margin: 20,
    alignItems: "center",
    width: "10rem",
    height: "3rem",
    borderRadius: 100,
    backgroundColor: "#FEFEFE",
    "&:hover": {
      backgroundColor: "#FEFEFE",
    },
  },
  skillbtnonclick: {
    margin: 20,
    alignItems: "center",
    width: "10rem",
    height: "3rem",
    borderRadius: 100,
    backgroundColor: "#806BD1",
    "&:hover": {
      backgroundColor: "#806BD1",
    },
  },
  listitm: {
    margin: 10,
  },
});

const Skills = () => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [style1, setStyle1] = useState(classes.skillbtn);
  const [style2, setStyle2] = useState(classes.skillbtn);
  const [style3, setStyle3] = useState(classes.skillbtn);
  const [style4, setStyle4] = useState(classes.skillbtn);
  const [style5, setStyle5] = useState(classes.skillbtn);
  const [style6, setStyle6] = useState(classes.skillbtn);
  const [style7, setStyle7] = useState(classes.skillbtn);
  const [style8, setStyle8] = useState(classes.skillbtn);
  const [style9, setStyle9] = useState(classes.skillbtn);

  const [skill1, setSkill1] = useState("");
  const [skill2, setSkill2] = useState("");

  function handlesubmit(skill) {
    if (skill1 === skill) {
      setSkill1("");
    } else if (skill2 === skill) {
      setSkill2("");
    } else {
      if (skill1 === "") {
        setSkill1(skill);
      } else if (skill2 === "") {
        setSkill2(skill);
      }
    }
  }

  function colorchange(skill) {
    if (skill === "Coding") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle1(classes.skillbtn);
        }
      } else {
        setStyle1(classes.skillbtnonclick);
      }
    } else if (skill === "Graphic Design") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle2(classes.skillbtn);
        }
      } else {
        setStyle2(classes.skillbtnonclick);
      }
    } else if (skill === "Problem Solving") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle3(classes.skillbtn);
        }
      } else {
        setStyle3(classes.skillbtnonclick);
      }
    } else if (skill === "Business Development") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle4(classes.skillbtn);
        }
      } else {
        setStyle4(classes.skillbtnonclick);
      }
    } else if (skill === "Marketing") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle5(classes.skillbtn);
        }
      } else {
        setStyle5(classes.skillbtnonclick);
      }
    } else if (skill === "Operations") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle6(classes.skillbtn);
        }
      } else {
        setStyle6(classes.skillbtnonclick);
      }
    } else if (skill === "Leadership") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle7(classes.skillbtn);
        }
      } else {
        setStyle7(classes.skillbtnonclick);
      }
    } else if (skill === "Communication") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle8(classes.skillbtn);
        }
      } else {
        setStyle8(classes.skillbtnonclick);
      }
    } else if (skill === "Type Own") {
      if (skill1 !== "" && skill2 !== "") {
        if (skill1 === skill || skill2 === skill) {
          setStyle9(classes.skillbtn);
        }
      } else {
        setStyle9(classes.skillbtnonclick);
      }
    }
  }

  return (
    <Box container className={classes.main}>
      <Box container className={classes.upperbox}>
        <Typography className={classes.skillitemsbox}>
          {skill1} , {skill2}
        </Typography>
        <Link to="/sample" style={{ textDecoration: "none" }}>
          <Button className={classes.nxtbtn}>
            <b>Next</b>
          </Button>
        </Link>
      </Box>
      <Box container className={classes.lowerbox}>
        <Box>
          <Button
            disabled={disabled}
            className={style1}
            onClick={() => {
              handlesubmit("Coding");
              colorchange("Coding");
            }}
          >
            Coding
          </Button>
          <Button
            disabled={disabled}
            className={style2}
            onClick={() => {
              handlesubmit("Graphic Design");
              colorchange("Graphic Design");
            }}
          >
            Graphic Design
          </Button>
          <Button
            disabled={disabled}
            className={style3}
            onClick={() => {
              handlesubmit("Problem Solving");
              colorchange("Problem Solving");
            }}
          >
            Problem Solving
          </Button>
        </Box>
        <Box>
          <Button
            disabled={disabled}
            className={style4}
            onClick={() => {
              handlesubmit("Business Development");
              colorchange("Business Development");
            }}
          >
            Business Development
          </Button>
          <Button
            disabled={disabled}
            className={style5}
            onClick={() => {
              handlesubmit("Marketing");
              colorchange("Marketing");
            }}
          >
            Marketing
          </Button>
          <Button
            disabled={disabled}
            className={style6}
            onClick={() => {
              handlesubmit("Operations");
              colorchange("Operations");
            }}
          >
            Operations
          </Button>
        </Box>
        <Box>
          <Button
            disabled={disabled}
            className={style7}
            onClick={() => {
              handlesubmit("Leadership");
              colorchange("Leadership");
            }}
          >
            Leadership
          </Button>
          <Button
            disabled={disabled}
            className={style8}
            onClick={() => {
              handlesubmit("Communication");
              colorchange("Communication");
            }}
          >
            Communication
          </Button>
          <Button
            disabled={disabled}
            className={style9}
            onClick={() => {
              handlesubmit("Type Own");
              colorchange("Type Own");
            }}
          >
            Type Own
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Skills;

/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import "../../stylesheets/partnership.css";
import axios from "axios";
import { Button, withStyles, makeStyles, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
}));

const StyledUploadButton = withStyles({
  label: {
    fontSize: "1.2rem",
    textTransform: "none",
    ["@media only screen and (max-width: 720px)"]: {
      fontSize: "0.8rem",
    },
    ["@media only screen and (max-width: 420px)"]: {
      fontSize: "0.7rem",
    },
    ["@media only screen and (max-width: 360px)"]: {
      fontSize: "0.6rem",
    },
    ["@media only screen and (max-width: 320px)"]: {
      fontSize: "0.55rem",
    },
    ["@media only screen and (max-width: 280px)"]: {
      fontSize: "0.45rem",
    },
  },
})(Button);

function ApplyForm() {
  const [user, setUser] = useState({
    fullName: "",
    startupName: "",
    linkOfVision: "",
    contact: "",
    mail: "",
    linkOfStartup: "",
    whatCanLearn: "",
    positionOpen: "",
    jobDescription: "",
  });

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [resp, setResp] = React.useState("Error Occured!");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const [err1, setErr1] = useState({
    valid: true,
    msg: "",
  });
  const [err2, setErr2] = useState({
    valid: true,
    msg: "",
  });
  const [err3, setErr3] = useState({
    valid: true,
    msg: "",
  });
  const [err4, setErr4] = useState({
    valid: true,
    msg: "",
  });
  const [err5, setErr5] = useState({
    valid: true,
    msg: "",
  });
  const [err6, setErr6] = useState({
    valid: true,
    msg: "",
  });
  const [err7, setErr7] = useState({
    valid: true,
    msg: "",
  });
  const [err8, setErr8] = useState({
    valid: true,
    msg: "",
  });
  const [err9, setErr9] = useState({
    valid: true,
    msg: "",
  });

  function validation() {
    let valid = true;

    //name
    if (!user.fullName.match(/^[a-zA-Z][A-Za-z\s]*$/)) {
      valid = false;
      if (user.fullname === "") {
        setErr1({ ...err1, valid: false });
        setErr1({ ...err1, msg: "Name cannot be empty!" });
      } else {
        setErr1({ ...err1, valid: false });
        setErr1({ ...err1, msg: "Invalid name!" });
      }
    }

    //organization
    if (!user.startupName.match(/^[a-zA-Z0-9][A-Za-z0-9\s]*$/)) {
      valid = false;
      if (user.startupName === "") {
        setErr2({ ...err2, valid: false });
        setErr2({ ...err2, msg: "Cannot be empty!" });
      } else {
        setErr2({ ...err2, valid: false });
        setErr2({ ...err2, msg: "Invalid Input!" });
      }
    }

    //linkofvision
    if (user.linkOfVision === "") {
      setErr3({ ...err3, valid: false });
      setErr3({ ...err3, msg: "Cannot be empty!" });
    }

    //phone number
    if (
      !user.contact.match(
        /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
      )
    ) {
      valid = false;
      if (user.contact === "") {
        setErr4({ ...err4, valid: false });
        setErr4({ ...err4, msg: "Phone number cannot be empty!" });
      } else {
        setErr4({ ...err4, valid: false });
        setErr4({ ...err4, msg: "Invalid Phone Number!" });
      }
    }

    //email
    if (!user.mail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
      valid = false;
      if (user.mail === "") {
        setErr5({ ...err5, valid: false });
        setErr5({ ...err5, msg: "Email cannot be empty!" });
      } else {
        setErr5({ ...err5, valid: false });
        setErr5({ ...err5, msg: "Invalid Email!" });
      }
    }

    //linkofstartup
    if (user.linkOfStartup === "") {
      setErr6({ ...err6, valid: false });
      setErr6({ ...err6, msg: "Cannot be empty!" });
    }

    //whatcanlearn
    if (!user.whatCanLearn.match(/^[a-zA-Z0-9][A-Za-z0-9\s]*$/)) {
      valid = false;
      if (user.whatCanLearn === "") {
        setErr7({ ...err7, valid: false });
        setErr7({ ...err7, msg: "Cannot be empty!" });
      } else {
        setErr7({ ...err7, valid: false });
        setErr7({ ...err7, msg: "Invalid Input!" });
      }
    }

    //positon
    if (!user.positionOpen.match(/^[a-zA-Z0-9][A-Za-z0-9\s]*$/)) {
      valid = false;
      if (user.positionOpen === "") {
        setErr8({ ...err8, valid: false });
        setErr8({ ...err8, msg: "Cannot be empty!" });
      } else {
        setErr8({ ...err8, valid: false });
        setErr8({ ...err8, msg: "Invalid Input!" });
      }
    }

    //jd
    if (user.jobDescription === "") {
      setErr9({ ...err9, valid: false });
      setErr9({ ...err9, msg: "Cannot be empty!" });
    }

    return valid;
  }

  const handleSubmit = (e) => {
    setErr1({ ...err1, valid: true });
    setErr1({ ...err1, msg: "" });
    setErr2({ ...err2, valid: true });
    setErr2({ ...err2, msg: "" });
    setErr3({ ...err3, valid: true });
    setErr3({ ...err3, msg: "" });
    setErr4({ ...err4, valid: true });
    setErr4({ ...err4, msg: "" });
    setErr5({ ...err5, valid: true });
    setErr5({ ...err5, msg: "" });
    setErr6({ ...err6, valid: true });
    setErr6({ ...err6, msg: "" });
    setErr7({ ...err7, valid: true });
    setErr7({ ...err7, msg: "" });
    setErr8({ ...err8, valid: true });
    setErr8({ ...err8, msg: "" });
    setErr9({ ...err9, valid: true });
    setErr8({ ...err9, msg: "" });
    e.preventDefault();
    console.log(user);
    console.log("i am here");
    if (validation()) {
      axios
        .post(`${DOMAIN}/create_position`, user,{
          headers:{
            Authorization:window.localStorage.getItem('token')
          }
        })
        .then((res) => {
          console.log(res);
          handleOpen();
          setResp(res.data);
          setUser({
            fullName: "",
            startupName: "",
            linkOfVision: "",
            contact: "",
            mail: "",
            linkOfStartup: "",
            whatCanLearn: "",
            positionOpen: "",
            jobDescription: "",
          });
        })
        .catch((e) => console.log(e));
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {resp === "success" ? (
        <h2 id="simple-modal-title">Thank You</h2>
      ) : (
        <h2 id="simple-modal-title">Error Occured</h2>
      )}
      {resp === "success" ? (
        <p className="simple-modal-desc" id="simple-modal-description">
          Markoknow warmly accepts your Proposal for hiring aspiring
          Entrepreneurs
        </p>
      ) : (
        <p className="simple-modal-desc" id="simple-modal-description">
          Please Try Again!
        </p>
      )}
      {/* {msg.length > 0 && <p className="simple-modal-desc">{msg}</p>} */}
      <div className="modal_nav_btn">
        <Link
          to="/experience"
          onClick={handleClose}
          style={{ textDecoration: "none", color: "black" }}
        >
          <button className="test_apply_btn">Dashboard</button>
        </Link>
      </div>
    </div>
  );

  return (
    <div className="apply_form">
      <h2 className="form_heading">Hire Aspiring Entrepreneurs</h2>
      <p className="partner_para">
        Make your journey by training others how to be an Entrepreneur
      </p>
      <br />
      <br />
      <form className="organise_form">
        {/* {console.log("User", user)} */}
        <div class="partnership_input">
          <div className="action_input_container">
            <input
              type="text"
              name="fullName"
              class="action_input"
              autocomplete="off"
              value={user.fullName}
              onChange={handleChange}
              required
              placeholder="Full Name"
            />
            <p className="error-code">{err1.msg}</p>
          </div>
          <div className="action_input_container">
            <input
              type="text"
              name="startupName"
              class="action_input"
              autoComplete="off"
              value={user.startupName}
              onChange={handleChange}
              required
              placeholder="Startup Name"
            />
            <p className="error-code">{err2.msg}</p>
          </div>
        </div>
        <div class="partnership_input">
          <div className="action_input_container">
            <input
              type="text"
              name="linkOfVision"
              class="action_input "
              autocomplete="off"
              value={user.linkOfVision}
              onChange={handleChange}
              placeholder="Link of the Founder talking about vision"
            />
            <p className="error-code">{err3.msg}</p>
          </div>
          <div className="action_input_container">
            <input
              type="contact"
              name="contact"
              class="action_input "
              autocomplete="off"
              value={user.contact}
              onChange={handleChange}
              placeholder="Contact No."
            />
            <p className="error-code">{err4.msg}</p>
          </div>
        </div>
        <div class="partnership_input">
          <div className="action_input_container">
            <input
              type="email"
              name="mail"
              class="action_input "
              autocomplete="off"
              value={user.mail}
              onChange={handleChange}
              placeholder="Email Id"
            />
            <p className="error-code">{err5.msg}</p>
          </div>
          <div className="action_input_container">
            <input
              type="text"
              name="linkOfStartup"
              class="action_input "
              autocomplete="off"
              value={user.linkOfStartup}
              onChange={handleChange}
              placeholder="Link of Startup"
            />
            <p className="error-code">{err6.msg}</p>
          </div>
        </div>
        <div class="partnership_input">
          <div className="action_input_container">
            <textarea
              type="text"
              name="whatCanLearn"
              class="action_input1"
              autocomplete="off"
              value={user.whatCanLearn}
              onChange={handleChange}
              required
              placeholder="What can aspiring Entrepreneurs learn from you?"
            />
            <p className="error-code">{err7.msg}</p>
          </div>
          <div className="action_input_container">
            <textarea
              type="text"
              name="positionOpen"
              class="action_input1 "
              autocomplete="off"
              value={user.positionOpen}
              onChange={handleChange}
              required
              placeholder="Position Open"
            />
            <p className="error-code">{err8.msg}</p>
          </div>
        </div>
        <div class="partnership_input">
          <div className="action_input_container">
            <textarea
              type="text"
              name="jobDescription"
              class="action_input1 "
              autocomplete="off"
              value={user.jobDescription}
              onChange={handleChange}
              placeholder="Job Description for the position you would like to hire for?"
            />
            <p className="error-code">{err9.msg}</p>
          </div>
        </div>
        <br />
        <label htmlFor="contained-button-file" className="file_input">
          <StyledUploadButton
            component="span"
            onClick={() => console.log("upload info")}
            className="file_input"
            size="small"
          >
            Upload File
          </StyledUploadButton>
          <input
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
          />
        </label>
        <div style={{ display: "flex", justifyContent: "flex-start", /*backgroundColor:'yellow'*/ width:'35%' }}>
          <button
            type="submit"
            value="apply"
            onClick={handleSubmit}
            class="action_btn1"
            style={{width:'100%'}}
          >
            Submit
          </button>
        </div>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {/* <div className="arrow_detail">
          <HashLink
            className="hash_link arrow_detail_btn"
            smooth
            to={"/experience#experience-page"}
          >
            <ArrowUpwardIcon />
          </HashLink>
        </div> */}
    </div>
  );
}

export default ApplyForm;

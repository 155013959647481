import {
  // Button,
  // Grid,
  Typography,
  Box,
  // TextField,
  Modal,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import LoginwithGoogle from ".././auth/LoginwithGoogle";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'black'
  },
  leftbox: {
    //backgroundColor:'yellow',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#9A4CDF",
    textAlign: "center",
    marginTop: "5vh",
    height: "90vh",
  },
  text: {
    color: "white",
    fontSize: "72px",
    margin: "10px",
    fontFamily: "Josefin Sans, sans-serif",
    "@media screen and (max-width: 480px)": {
      color: "white",
      fontSize: "36px",
      margin: "10px",
    },
  },
  btnbox: {
    display: "flex",
    //backgroundColor:'blue',
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    "@media screen and (max-width: 480px)": {
      display: "flex",
      //backgroundColor:'blue',
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "5px",
    },
  },
  btn: {
    color: "black",
    backgroundColor: "#85FCCD",
    width: "30%",
    height: "60px",
    margin: "5px",
    fontWeight: "bold",
    borderRadius: "30px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
    "@media screen and (max-width: 480px)": {
      color: "black",
      backgroundColor: "#85FCCD",
      width: "30%",
      height: "45px",
      margin: "5px",
      fontWeight: "bold",
      borderRadius: "30px",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "white",
      },
    },
  },
  suggestbtn: {
    backgroundColor: "#52EFDA",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
  },
  inputbox: {
    width: "100%",
    //backgroundColor:'yellow',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    marginTop: "20px",
  },
  input: {
    width: "60%",
    backgroundColor: "white",
  },
  carousel: {
    width: "80%",
    "@media screen and (max-width: 480px)": {
      width: "90%",
    },
  },
  loginboxcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "300px",
  },
  loginbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "150px",
    width: "30%",
    backgroundColor: "#85FCCD",
    flexDirection: "column",
    borderRadius: "4px",
    "@media screen and (max-width: 480px)": {
      height: "150px",
      width: "60%",
    },
  },
  announcement_div: {
    color: "white",
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: "100px",
    top: "100px",
    zindex: "1",
  },
});
function LoginModal({ setIsAuth, open, setOpen }) {
  const classes = useStyles();
  const [PriorityAnswer, setPriorityAnswer] = useState("none");
  const loginBox = (
    <Box className={classes.loginboxcontainer}>
      <Box className={classes.loginbox}>
        <Typography style={{ margin: "5px" }}>Login to continue</Typography>
        <Typography style={{ margin: "5px", textDecoration: "none" }}>
          <Link to={"/signup"} sx={{ textDecoration: "none" }}>
            Login with email
          </Link>
        </Typography>

        <LoginwithGoogle
          PriorityAnswer={PriorityAnswer}
          setIsAuth={setIsAuth}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {loginBox}
      </Modal>
    </>
  );
}

export default LoginModal;

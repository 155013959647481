import { Box, Button, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { ArrowBackIos, MoreHoriz } from "@material-ui/icons";
const DOMAIN = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles({
  main: {
    display: "flex",
    md: "8",
    marginTop: "30px",
  },
  mid: {
    display: "none",
  },
  wrapper: {
    display: "flex",
    marginTop: "10px",
    flexDirection: "column",
    overflow: "hidden",
  },
  mssgbox: {
    paddingBottom: "20px",
    overflowY: "scroll",
    height: "540px",
  },
  sendbox: {
    backgroundColor: "white",
    position: "fixed",
    bottom: 0,
    width: "inherit",
    height: "65px",
    "@media only screen and (max-width: 420px)": {
      backgroundColor: "white",
      position: "fixed",
      bottom: "62px",
      width: "inherit",
    },
  },
  sendform: {
    //backgroundColor:'blue'
  },
  sendinput: {
    width: "100%",
    "@media only screen and (max-width: 420px)": {
      width: "100%",
    },
  },
  sendbtnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sendbtn: {
    backgroundImage: "linear-gradient(to right,#8dffcc,#57F0D8)",
  },
  mymssgs: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "75%",
    marginLeft: "23%",
  },
  mymssg: {
    disply: "flex",
    backgroundColor: "#8dffcc",
    marginTop: "5px",
    borderRadius: "10px",
  },
  mssg1: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    margin: "5px",
  },
  othermssgs: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "75%",
  },
  othersmssg: {
    disply: "flex",
    backgroundColor: "#52efda",
    marginTop: "5px",
    borderRadius: "10px",
    marginLeft: "3%",
    "@media only screen and (max-width: 420px)": {
      disply: "flex",
      backgroundColor: "#52efda",
      marginTop: "5px",
      borderRadius: "10px",
      marginLeft: "8%",
    },
  },
  mssg2: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: "5px",
  },
  profilebox: {
    display: "flex",
    height: "70px",
    width: "80%",
    backgroundColor: "#292826",
    position: "fixed",
    border: "1px solid",
    color: "white",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      height: "70px",
      width: "100%",
      backgroundColor: "#292826",
      border: "1px solid",
      color: "white",
    },
  },
  profileimg: {
    height: "100%",
  },
  profileimgbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
  },
  profiletextbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backarrow: {
    display: "none",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      width: "4rem",
      height: "4rem",
      alignItems: "center",
      justifyContent: "center",
      margin: 8,
      cursor: "pointer",
    },
  },
});

const ChatHome = ({
  conversationId,
  socket,
  // connected,
  conversation,
  setConversation,
  setCheckchat,
  oppuser,
}) => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    const getRecentChat = () => {
      if (!conversationId || !socket) return;
      axios
        .get(`${DOMAIN}/api/message/${conversationId}`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setConversation(res.data);
        });
    };
    getRecentChat();
  }, [conversationId]);

  //send message
  const submitHandler = async (e) => {
    e.preventDefault();

    await socket.emit(
      "send_message",
      {
        message: message,
        conversationId: conversationId,
      },
      async (response) => {
        console.log(response);
        setMessage("");
      }
    );
  };
  const classes = useStyles();

  if (!conversationId)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{ height: "400px", width: "400px" }}
          src="https://harver.com/wp-content/uploads/2020/08/chat@2x.png"
        ></img>
        <p style={{ fontSize: "26px" }}>
          Please select a contact to start chat
        </p>
      </div>
    );

  return (
    <Grid container className={classes.wrapper}>
      <Grid item lg={12} xs={12} sm={12}>
        <Box style={{ display: "flex", position: "fixed" }}>
          <Box className={classes.backarrow}>
            <Link to="/chathome" onClick={() => setCheckchat(false)}>
              <ArrowBackIos />
            </Link>
          </Box>
          <Link
            to={`/profile/${oppuser._id}`}
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.profilebox}>
              <Box className={classes.profileimgbox}>
                <img className={classes.profileimg} src={oppuser.image?.name} />
              </Box>
              <Box className={classes.profiletextbox}>
                <Typography>
                  <p style={{ fontSize: "26px" }}>{oppuser.name}</p>
                </Typography>
              </Box>
            </Box>
          </Link>
        </Box>
      </Grid>
      <Grid item lg={12} xs={12} sm={12}>
        <Box className={classes.mssgbox}>
          {conversation.map((msg) => (
            <Box key={msg._id}>
              {msg.sender === window.localStorage.getItem("id") ? (
                <Box className={classes.mymssgs} key={msg._id}>
                  <Box className={classes.mymssg}>
                    <Box className={classes.mssg1}>{msg.text}</Box>
                  </Box>
                </Box>
              ) : (
                <Box className={classes.othermssgs} key={msg._id}>
                  <Box className={classes.othersmssg}>
                    <Box className={classes.mssg2}>{msg.text}</Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} className={classes.sendbox}>
        <form onSubmit={submitHandler} className={classes.sendform}>
          <Grid container>
            <Grid
              item
              lg={6}
              sm={12}
              xs={8} /*style={{"backgroundColor":'red'}}*/
            >
              <TextField
                id="outlined-basic"
                placeholder="Type here"
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={classes.sendinput}
              />
            </Grid>
            <Grid item lg={1} sm={6} xs={4} className={classes.sendbtnbox}>
              <Button className={classes.sendbtn} type="submit">
                send
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ChatHome;

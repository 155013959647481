import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import axios from "axios";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles({
  main: {
    display: "flex",
    height: "4.5rem",
    width: "100%",
    cursor: "pointer",
    marginTop: 5,
  },
  img: {
    height: "4rem",
    width: "4rem",
    margin: 5,
    borderRadius: 100,
  },
  text: {
    margin: 5,
    paddingLeft: 10,
    width: "13rem",
  },
  time: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "7rem",
    height: "100%",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "blue",
  },
});

const Contact = ({ conv, setConversationId, setOppuser, setCheckchat }) => {
  const [lastMessage, setLastMessage] = useState({});
  var user = conv.members.filter(
    (user) => user._id != window.localStorage.getItem("id")
  );
  //other user
  user = user[0];

  const classes = useStyles();

  const handleSubmit = () => {
    if (!conv) return;
    setConversationId(conv._id);
    setOppuser(user);
    setCheckchat(true);
  };

  useEffect(() => {
    const getlatestMessage = () => {
      axios
        .get(`${DOMAIN}/api/message/latest/${conv._id}`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const str = res.data[0];
          setLastMessage(str);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    getlatestMessage();
  }, []);

  return (
    <Box className={classes.main} onClick={handleSubmit}>
      <Box>
        <img className={classes.img} src={user?.image?.name} />
      </Box>
      <Box>
        <Typography className={classes.text}>
          <b>{user?.name}</b>
        </Typography>

        {conv.latestMessage ? (
          <Typography className={classes.text}>
            {conv.latestMessage.sender == user._id ? user.name : "you"} :
            {conv.latestMessage}
          </Typography>
        ) : (
          <Typography className={classes.text}>
            {user?._id != lastMessage?.user?.id
              ? lastMessage?.sender?.name
              : "you"}
            :{lastMessage?.text}
          </Typography>
        )}
      </Box>
      <Box className={classes.time}>
        <Typography>4:30 PM</Typography>
      </Box>
      <Box className={classes.icon}>
        {conv.online.includes(user._id) === true ? <FiberManualRecord /> : ""}
      </Box>
    </Box>
  );
};

export default Contact;

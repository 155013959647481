import React from "react";
import { makeStyles } from "@material-ui/core";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState } from "react";
import Footer from "../About/Footer";
import first from "./first.png";
import second from "./second.png";
const styles = makeStyles({
  body: {
    height: "70vh",
    backgroundColor: "white",
    "@media only screen and (max-width: 960px)": {
      height: "80vh",
    },
    "@media only screen and (max-width: 480px)": {
      height: "80vh",
    },
  },
  body1: {
    height: "80vh",
    backgroundColor: "white",
    "@media only screen and (max-width: 480px)": {
      height: "110vh",
    },
  },
  heading: {
    marginBottom: "20px",
    disply: "flex",
    color: "black",
    textAlign: "center",
    fontSize: "52px",
    fontWeight: "bold",
    width: "100%",
    // height: '600px',
    "@media only screen and (max-width: 960px)": {
      textAlign: "center",
      marginLeft: "30px",
    },
    "@media only screen and (max-width: 480px)": {
      fontSize: "30px",
      flexDirection: "row",
      marginLeft: "10px",
    },
  },
  subheading: {
    lineHeight: "1",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "200px",
    width: "60%",
    fontSize: "25px",
    marginTop: "30px",
    "@media only screen and (max-width: 480px)": {
      marginTop: "5px",
      marginLeft: "80px",
      marginBottom: "10px",
      width: "70%",
      textAlign: "left",
      fontSize: "20px",
    },
  },
  imgsdiv: {
    marginTop: "30px",
    marginLeft: "100px",
    "@media only screen and (max-width: 480px)": {
      marginLeft: "40px",
    },
  },
  imgs: {
    height: "200px",
    width: "500px",
    "@media only screen and (max-width: 480px)": {
      height: "150px",
      width: "300px",
    },
  },
  btn: {
    padding: "10px 20px 10px 20px",
    marginTop: "40px",
    marginLeft: "0px",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    color: "white",
    backgroundColor: "rgb(37, 30, 79)",
    borderRadius: "30px",
    "&:hover": {
      backgroundColor: "#221857",
      color: "white",
    },
    "@media only screen and (max-width: 960px)": {
      marginLeft: "40px",
      fontSize: "15px",
    },
  },
  listItems: {
    "@media only screen and (max-width: 960px)": {
      marginLeft: "40px",
      fontSize: "15px",
    },
  },
  listOrder: {
    fontSize: "25px",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "80px",
      marginBottom: "10px",
      width: "70%",
      textAlign: "left",
      fontSize: "20px",
    },
  },
});
function LearnerLanding() {
  const classes = styles();
  const [countdown, setCountdown] = useState(100);
  return (
    <>
      <div className={classes.body}>
        <Button className={classes.btn} style={{ float: "right" }}>
          Claim Virtual Startup Kit
        </Button>
        <Grid container>
          <Grid item xs={10} md={6} lg={6}>
            <Grid
              styles={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div className={classes.heading}>Main heading</div>
              <div className={classes.subheading}>
                Subheading is an Important angle for the website and it should
                be smaller font than Heading
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to={"/compatibility"}>
                  <Button
                    className={classes.btn}
                    onClick={() => {
                      setCountdown(countdown - 1);
                    }}
                  >
                    Claim Virtual Startup Kit
                  </Button>
                </Link>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={10} md={6} lg={6}>
            <Grid
              styles={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div className={classes.imgsdiv}>
                <img src={first} className={classes.imgs} />
              </div>
              <div style={{ textAlign: "center" }}>
                <Button className={classes.btn}>
                  <p>
                    Countdown:
                    <br />
                    {countdown} out of 100 left!
                  </p>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.body1}>
        <div className={classes.heading}>
          <p style={{}}>What You Get?</p>
        </div>
        <Grid>
          <Grid container style={{ backgroundColor: "white" }}>
            <Grid item xs={10} md={6} lg={6}>
              <Grid
                styles={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className={classes.imgsdiv}>
                  <img src={second} className={classes.imgs} />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={10} md={6} lg={6}>
              <Grid
                className={classes.listItems}
                styles={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ul className={classes.listOrder}>
                  <li style={{ marginBottom: "20px" }}>
                    Business Model Sheets of 20 Startups
                  </li>
                  <li style={{ marginBottom: "20px" }}>
                    Revenue Model Sheets of 10 Startups
                  </li>
                  <li style={{ marginBottom: "20px" }}>
                    Marketing Plan Sheets for Startups
                  </li>
                  <li style={{ marginBottom: "20px" }}>
                    Social Media Calender for Startups & Influencers
                  </li>
                  <li style={{ marginBottom: "20px" }}>
                    Financial Projections Done at Pitch Deck
                  </li>
                  <li style={{ marginBottom: "20px" }}>
                    Sample Founder's Agreement
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Link to={"/learners"} style={{ backgroundColor: "white" }}>
              <Button
                className={classes.btn}
                style={{ float: "right", marginRight: "100px" }}
              >
                Go To Main Page
              </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}

export default LearnerLanding;

import { Box, Button, Input, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles=makeStyles({
    main:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100%',
        padding:'5px'
    },
    fullbox:{
        backgroundColor:'white',
        marginTop:'20px',
        // border:'2px solid red',
        width:'70%'
    },
    textbox:{
        margin:10,
        display:'flex', 
        alignItems:'center',
        justifyContent:'center',
        marginBottom:'20px',
        fontSize:'1vw'
    },
    inputbox:{
        margin:10,
        height:'170px',
        display:'flex', 
        alignItems:'center',
    },
    input:{
        width:'90%',
        padding:'5px'
    },
    
    '@media screen and (max-width: 480px)':{
        main:{
            backgroundColor:'#806BD1'
        },
        fullbox:{
            width:'100%'
        },
        inputbox:{
            margin:10,
            height:'150px',
            display:'flex', 
            alignItems:'center',
            // border:'2px solid red'
        },
        // input:{
            
        //     height:'200px'
        // },
    }
});

const InputBox = ({setDescription}) => {
    const classes=useStyles();
    return (
        <Box  className={classes.main}>
            <Box className={classes.fullbox}>
                <Box className={classes.textbox}>
                   <Typography variant='h5'>How would you help the start up and the expectation you have for the team ? </Typography>
                </Box>
                <Box className={classes.inputbox} >
                    <TextField 
                    className={classes.input} 
                    onChange={ event=>setDescription(event.target.value)}
                    placeholder='type here' 
                    multiline 
                    rows={8} 
                    InputProps={{
                        disableUnderline: true,
                        maxLength: 5
                    }}
                    />
                </Box>
               
            </Box>
        </Box>
    )
}

export default InputBox

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import {BrowserRouter as Router } from 'react-router-dom';


const theme = createTheme({
  palette: {
    primary: {
      light: "#000",
      main: "#000",
      dark: "#000",
    },
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  // typography: {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: 14,
  //   fontWeightLight: 300, // Work Sans
  //   fontWeightRegular: 400, // Work Sans
  //   fontWeightMedium: 700, // Roboto Condensed
  //   fontFamilySecondary: "'Roboto Condensed', sans-serif",
  // },
});

ReactDOM.render(
  
  <ThemeProvider theme={theme}>
  <Router>
    <App/>
  </Router> 
  </ThemeProvider>,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import "../../stylesheets/watch-now.css";
import React, { useState, useEffect } from "react";
// import { useParams, Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
//import Typography from "@mui/material/Typography";
import { Typography } from "@material-ui/core";
import axios from "axios";
//import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ReadMoreAndLess from "react-read-more-less";

import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import DeleteModal from "../DeleteModal";
import Alert from "../MessagePopup";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

import WatchnowButtonBox from "./WatchnowButtonBox";
import StreakButtons from "../Learn-Now/Components/StreakButtons";
import Modal from "@material-ui/core/Modal";
import { NavLink } from "react-router-dom";
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  padding: "6px 12px",
  // border: "1px solid",
  backgroundColor: "#6a58c6",
  borderColor: "#52efda",
  color: "#FFFFFF",
  fontFamily: "Open Sans",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "27px",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "100%",
  borderRadius: "0 0 0 0.5rem",
  height: "60px",

  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#6a58c6",
    borderColor: "#8dffcc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#6a58c6",
    borderColor: "#8dffcc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Usestyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
  btnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'yellow',
    width: "75%",
    marginLeft: "5%",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      flexDirection: "column",
    },
  },
  btnleftbox: {
    display: "flex",
    flex: "6",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "15%",
    },
  },
  btnrightbox: {
    display: "flex",
    flex: "6",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      marginLeft: "15%",
    },
  },
  btn: {
    backgroundColor: "#8dffcc",
  },
  wrapper: {
    display: "flex",
    // border: "2px solid red",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    //background-image: linear-gradient(to right,#8dffcc,#57F0D8 ),
    flexDirection: "column",
    //backgroundColor:'#6A58c6',
    // backgroundColor: "#221857",
    "@media only screen and (max-width: 420px)": {
      fontSize: "15px",
      textAlign: "center",
    },
  },
  heading1: {
    fontSize: "55px",
    marginTop: "30px",
    width: "75%",
    /* border:1px solid red; */
    // height: "124px",
    padding: " 20px",
    fontWeight: "700",
    lineHeight: "62px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "center",
    color: "#1a202c",
  },
  heading2: {
    fontSize: "1.9rem",
    margin: "5px",
    textAlign: "center",
    color: "#1a202c",
  },
  btnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  subbtnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    margin: "10px",
    color: "#1a202c",
    border: "1px solid black",
  },
  selbtn: {
    margin: "10px",
    backgroundColor: "#6a58c6",
    color: "white",
    fontSize: "15px",
    fontWeight: "500",

    "&:hover": {
      backgroundColor: "#6a58c6",
      color: "white",
    },
  },
}));

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function WatchNow({ isAuth, setIsAuth }) {
  const classes = Usestyles();
  const [data, setData] = useState([]);
  const [requestStart, setRequestStart] = useState(true);
  const [watchnow, setWatchnow] = useState({});
  const [streaks, setStreaks] = useState([]);
  const [completedlist, setCompletedlist] = useState([]);
  //alert
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleAlertClose = () => setOpenAlert(false);

  //delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  //tags
  const [all, setAll] = useState(true);
  const [startups, setStartups] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [finance, setFinance] = useState(false);
  const [funding, setFunding] = useState(false);
  const [founders, setFounders] = useState(false);
  const [techD, setTechandD] = useState(false);
  const [others, setOthers] = useState(false);
  //button styling
  const [allbtnstyle, setAllbtnstyle] = useState(classes.selbtn);
  const [starbtnstyle, setStarbtnstyle] = useState(classes.btn);
  const [marbtnstyle, setMarbtnstyle] = useState(classes.btn);
  const [finbtnstyle, setFinbtnstyle] = useState(classes.btn);
  const [funbtnstyle, setFunbtnstyle] = useState(classes.btn);
  const [foubtnstyle, setFoubtnstyle] = useState(classes.btn);
  const [tecbtnstyle, setTecbtnstyle] = useState(classes.btn);
  const [othbtnstyle, setOthbtnstyle] = useState(classes.btn);
  //tag functions
  const All = () => {
    all ? setAll(false) : setAll(true);
    all ? setAllbtnstyle(classes.btn) : setAllbtnstyle(classes.selbtn);
  };
  const Startups = () => {
    startups ? setStartups(false) : setStartups(true);
    startups ? setStarbtnstyle(classes.btn) : setStarbtnstyle(classes.selbtn);
  };
  const Marketing = () => {
    marketing ? setMarketing(false) : setMarketing(true);
    marketing ? setMarbtnstyle(classes.btn) : setMarbtnstyle(classes.selbtn);
  };
  const Finance = () => {
    finance ? setFinance(false) : setFinance(true);
    finance ? setFinbtnstyle(classes.btn) : setFinbtnstyle(classes.selbtn);
  };
  const Funding = () => {
    funding ? setFunding(false) : setFunding(true);
    funding ? setFunbtnstyle(classes.btn) : setFunbtnstyle(classes.selbtn);
  };
  const Founders = () => {
    founders ? setFounders(false) : setFounders(true);
    founders ? setFoubtnstyle(classes.btn) : setFoubtnstyle(classes.selbtn);
  };
  const Tech = () => {
    techD ? setTechandD(false) : setTechandD(true);
    techD ? setTecbtnstyle(classes.btn) : setTecbtnstyle(classes.selbtn);
  };
  const Others = () => {
    others ? setOthers(false) : setOthers(true);
    others ? setOthbtnstyle(classes.btn) : setOthbtnstyle(classes.selbtn);
  };

  const history = useHistory();
  useEffect(() => {
    axios
      .get(`${DOMAIN}/watchnow`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, [requestStart]);
  //console.log(data);

  //delete handler
  const deleteHandler = () => {
    axios
      .delete(`${DOMAIN}/watchnow/${watchnow._id}`, {
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res) => {
        setAlertMessage("SuccessFully watchnow deleted!");
        setOpenAlert(true);
        setOpenDeleteModal(false);
        setRequestStart(false);
      })
      .catch((err) => {
        if (err) {
          setAlertMessage(err.response?.data.Error);
          setOpenAlert(true);
          setRequestStart(false);
        }
      });
  };

  //get streak video list
  const GetStreakVideos = () => {
    axios
      .get(`${DOMAIN}/watchnow/streaklist`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setStreaks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //console.log(streaks);
  const GetCompletedVideos = () => {
    axios
      .get(`${DOMAIN}/watchnow/completedlist`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCompletedlist(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetCompletedVideos();
    // GetStreakVideos();
  }, []);

  //console.log(completedlist);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const isAuthUser = isAuth;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">You are not Logged In</h2>
      <p className="simple-modal-desc" id="simple-modal-description">
        TO VIEW THIS PAGE, YOU NEED TO BE LOGGED IN TO THE SITE
      </p>
      <div className="buttons">
        <NavLink
          to="/login"
          onClick={handleClose}
          style={{ textDecoration: "none", color: "black" }}
        >
          <button className="btn btn-primary btn-lg">Login</button>
        </NavLink>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (isAuthUser) {
      handleClose();
    } else {
      setTimeout(function () {
        handleOpen();
      }, 2000);
    }
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div
        style={{
          backgroundColor: "white",
          height: "120%",
          paddingBottom: "50px",
        }}
      >
        {/* alert */}
        <Alert
          open={openAlert}
          handleAlertClose={handleAlertClose}
          message={alertMessage}
        />
        {/* alert end */}

        {/* delete modal */}
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          deleteHandler={deleteHandler}
        />
        {/* delete modal end */}
        <Box className={classes.wrapper}>
          <Typography className={classes.heading1}>WATCH NOW</Typography>
          <Typography className={classes.heading2}>
            There is a lot to learn beyond what you know. Learn and explore from
            the videos on diverse topics that are relevant for you to grow
          </Typography>
          <StreakButtons />
          <Box className={classes.btnbox}>
            <Box className={classes.subbtnbox}>
              <Button className={allbtnstyle} onClick={All}>
                All
              </Button>
              <Button className={starbtnstyle} onClick={Startups}>
                Startups
              </Button>
              <Button className={marbtnstyle} onClick={Marketing}>
                Marketing
              </Button>
            </Box>
            <Box className={classes.subbtnbox}>
              <Button className={finbtnstyle} onClick={Finance}>
                Finance
              </Button>
              <Button className={funbtnstyle} onClick={Funding}>
                Funding
              </Button>
              <Button className={foubtnstyle} onClick={Founders}>
                Founders
              </Button>
            </Box>
            <Box className={classes.subbtnbox}>
              <Button className={tecbtnstyle} onClick={Tech}>
                Tech & Design
              </Button>
              <Button className={othbtnstyle} onClick={Others}>
                Others
              </Button>
            </Box>
          </Box>
        </Box>
        <div className="mkw_cards_wrapper">
          {data
            .filter((video) => {
              let tags = video.tags;
              var a = 0;
              var mar = 0;
              var star = 0;
              var fin = 0;
              var fun = 0;
              var fou = 0;
              var tec = 0;
              var oth = 0;
              //console.log(video._id);
              tags.map((tag) => {
                if (tag === "all") a = 1;
                if (tag === "marketing") mar = 1;
                if (tag === "startups") star = 1;
                if (tag === "finance") fin = 1;
                if (tag === "funding") fun = 1;
                if (tag === "founders") fou = 1;
                if (tag === "tech and design") tec = 1;
                if (tag === "others") oth = 1;
              });
              if (all || a) return video;
              if (startups && star) return video;
              if (marketing && mar) return video;
              if (finance && fin) return video;
              if (funding && fun) return video;
              if (founders && fou) return video;
              if (techD && tec) return video;
              if (others && oth) return video;
            })
            .map((evt) => (
              <div key={evt._id}>
                <Card class="mkw_card" sx={{ width: 345 }} key={evt._id}>
                  <CardMedia
                    class="mkw_card-img-top"
                    component="img"
                    src={`${DOMAIN}/watchnow/${evt.poster.name}`}
                    alt="..."
                  />
                  <CardContent>
                    <h4
                      style={{
                        color: "#1A202C",
                        textAlign: "center",
                        // marginTop: "-40px",
                        fontFamily: "Poppins",
                        fontSize: "24px",
                        fontWeight: "800",
                        lineHeight: "36px",
                      }}
                    >
                      {evt.title}
                    </h4>
                    {/* <h5>{evt.title}</h5> */}
                    <div
                      style={{
                        marginTop: "10px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        marginBottom: "1rem",
                      }}
                    >
                      <p
                        style={{
                          padding: "2px 10px",
                          backgroundColor: "#F2F6FF",
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "233px",
                          height: "20px",
                          color: "#3470e4",
                          textTransform: "uppercase",
                          fontFamily: "Open Sans",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {evt.speaker}
                      </p>
                    </div>
                    {/* <Typography variant="body2" color="text.secondary">
                      <b>{evt.speaker}</b>
                    </Typography> */}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#21191b",
                        display: "flex",
                        alignItems: "center",

                        padding: "2px 0px 2px 10px",
                      }}
                    >
                      <ReadMoreAndLess
                        charLimit={50}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                      >
                        {evt.aboutSpeaker}
                      </ReadMoreAndLess>
                    </Typography>
                  </CardContent>
                  <CardActions className="card-actions">
                    <BootstrapButton
                      onClick={() =>
                        history.push("/watch_now/watch", { link: evt.link })
                      }
                      variant="contained"
                    >
                      Watch Now
                    </BootstrapButton>
                    <WatchnowButtonBox
                      Id={evt._id}
                      completedlist={completedlist}
                      streaks={streaks}
                    />
                  </CardActions>
                  {/* <div>
                <IconButton
                  onClick={() => {
                    setWatchnow(evt);
                    setOpenDeleteModal(true);
                  }}
                  style={{ marginRight: "70%" }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  onClick={() => history.push("/watch_now_form", { evt: evt })}
                >
                  <EditIcon />
                </IconButton>
              </div> */}
                </Card>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
export default WatchNow;

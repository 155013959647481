// import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import FadeIn from "react-fade-in";
import { makeStyles } from "@material-ui/core";
import Cards from "../Components/Cards";
import Footer from "../../About/Footer";
import FloatPage from "../../FloatPage";
import TextAnimation from "../../TextAnimation";
// import {Link} from 'react-router-dom';

const useStyles = makeStyles({
  heading: {
    color: "white",

    marginTop: "40px",
    "@media only screen and (max-width: 480px)": {
      color: "white",
      fontSize: 10,
      display: "flex",
      whiteSpace: "nowrap",
      overflowWrap: "break-word",
      fontFamily: "League spartan",
    },
  },
  subheading: {
    color: "white",
    marginTop: "40px",
    fontSize: "30px",
    marginBottom: "70px",
  },
  alex: {
    textAlign: "center",
    backgroundColor: "black",
    height: "auto",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black",
  },
  //   announcement_div: {
  //     color: "white",
  //     backgroundColor: "purple",
  //     textDecoration: "none",
  //     display: "flex",
  //     flexDirection: "row",
  //   },
  close_icon: {
    cursor: "pointer",
  },
  "@media only screen and (max-width: 480px)": {
    testDiv: {
      width: "100%",
      backgroundColor: "red",
    },
    heading: {
      color: "white",
      margin: 15,
    },
    alex: {
      textAlign: "center",
      backgroundColor: "black",
      height: "auto",
      fontFamily: "Curisve",
      // overflowY: "scroll",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "black",
      overflowX: "hidden",
    },
    "@media only screen and (max-width: 480px)": {
      heading: {
        color: "white",
        fontSize: 40,
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        overflowWrap: "break-word",
        fontFamily: "Curisve",
      },
      subheading: {
        color: "white",
        margin: 20,
        fontSize: 18,
      },
      alex: {
        textAlign: "center",
        backgroundColor: "black",
        height: "auto",
      },
    },
    "@media only screen and (min-width: 762px)": {
      announcement_div: {
        display: "none",
      },
    },
  },
});
// const DOMAIN = process.env.REACT_APP_DOMAIN;
const Home = ({ isAuth, setIsAuth }) => {
  const classes = useStyles();
  const id = window.localStorage.getItem("id");

  return (
    <>
      <Box className={classes.container}>
        {isAuth ? (
          <Box className={classes.alex}>
            <FadeIn>
              <Typography variant="h3" className={classes.heading}>
                {/* Learn. */}
                <TextAnimation
                  style={{ fontFamily: "sans-serif" }}
                  text="Your Startup Journey"
                />
              </Typography>
              <Typography variant="h5" className={classes.subheading}>
                Kick start your Startup Journey with Right Knowledge , Right
                People and Right Path
              </Typography>
              <Box>
                <Cards />
              </Box>
            </FadeIn>
          </Box>
        ) : (
          <>
            <FloatPage time={3000} setIsAuth={setIsAuth} isAuth={isAuth} />
            <Box className={classes.alex}>
              <Typography variant="h3" className={classes.heading}>
                {/* Learn. */}
                Your Startup Journey
              </Typography>
              <Typography variant="h5" className={classes.subheading}>
                Create your own learning path and follow it to make your passion
                come to reality
              </Typography>
              <Cards />
            </Box>
          </>
        )}
        <Footer />
      </Box>
    </>
  );
};

export default Home;

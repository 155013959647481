import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./style";

function CofounderSkillSelection({
  skillprimary,
  skillsecondary,
  setSkillPrimary,
  setSkillSecondary,
}) {
  const classes = useStyles();

  function handlesubmit(skill) {
    if (skillprimary === skill) {
      setSkillPrimary("");
    } else if (skillsecondary === skill) {
      setSkillSecondary("");
    } else {
      if (skillprimary === "") {
        setSkillPrimary(skill);
      } else if (skillsecondary === "") {
        setSkillSecondary(skill);
      }
    }
  }

  return (
    <div className={classes.fullpage}>
      <div className={classes.design2}></div>
      <div className={classes.design}></div>
      <center>
        <Typography variant="h6" className={classes.bottomline}>
          Select Top Skills that you have as a Co Founder
        </Typography>
      </center>

      <div className={classes.output}>
        <h3>
          {" "}
          {skillprimary}, {skillsecondary}{" "}
        </h3>
      </div>

      <div className={classes.btnsection}>
        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Coding");
          }}
        >
          Coding
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Graphic Designing");
          }}
        >
          Graphic Designing
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Problem Solving");
          }}
        >
          Problem Solving
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Business Development");
          }}
        >
          Business Development
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Marketing");
          }}
        >
          Marketing
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Operations");
          }}
        >
          Operations
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Leadership");
          }}
        >
          Leadership
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Communication");
          }}
        >
          Communication
        </button>

        <button
          className={classes.btns}
          onClick={function (event) {
            handlesubmit("Type Own");
          }}
        >
          Type Own
        </button>
      </div>
    </div>
  );
}

export default CofounderSkillSelection;

import "../../stylesheets/watch-now.css";
import React from "react";
import { useLocation } from "react-router-dom";

function Video() {
  let { link } = useLocation().state;
  link = link.split("/")[3];
  return (
    <>
      <div className="video">
        <div className="video_container">
          <iframe
            className="video_iframe"
            src={`https://www.youtube-nocookie.com/embed/${link}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  );
}
export default Video;

import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import moment from "moment";
import axios from "axios";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import "../../stylesheets/event.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: 700,
    width: 700,
    backgroundColor: "black",
  },
  media: {
    height: "250px",
    marginLeft: "30px",
    marginRight: "30px",
    ["@media only screen and (max-width: 420px)"]: {
      height: "150px",
      marginLeft: "10px",
      marginRight: "10px", // secondary
    },
  },
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    // eslint-disable-next-line no-useless-computed-key
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
}));
function EntreprenuerEvent({ members }) {
  // console.log("Hello guys",members)

  const classes = useStyles();
  const classses = useStyle();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const id1 = window.localStorage.getItem("id");
  const [link, setLink] = useState("");
  const [mobile, setMobile] = useState("");
  const [payId, setPayId] = useState("");
  const [eventNo, seteventNo] = useState([]);
  var eventNumbers = JSON.parse(window.localStorage.getItem("eventNumbers"));
  let eventlist = [];
  var user_point = window.localStorage.getItem("point");
  // console.log(user_point)
  members.map((p) => eventlist.push(p));
  // console.log("this is list",eventlist)
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
    handleClose();
    window.location.reload(false);
  };

  function handleId2(idx) {
    //   console.log("index : ",idx)
    seteventNo(eventlist[idx]);
  }
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(item) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const data = {
      amount: item.eventfee,
    };

    const result = await axios.post(`${DOMAIN}/api/orderpayment`, data);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    const { amount, id: order_id, currency } = result.data;
    const amt = toString(amount);
    const RAZORPAY_KEY_ID = process.env.RAZORPAY_KEY_ID;

    const options = {
      key: RAZORPAY_KEY_ID,
      amount: amt,
      currency: currency,
      name: "Markoknow",
      description: "Event Booking",
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          event: item.event,
          eventDate: item.eventDate,
          name: window.localStorage.getItem("name"),
          email: window.localStorage.getItem("email"),
          eventId: item._id,
          amount: amount,
          userId: id1,
        };

        const result = await axios.post(`${DOMAIN}/api/paymentsuccess`, data);

        if (result.data.msg === "success") {
          setPayId(result.data.paymentId);
          setLink(result.data.link);
          handleOpen1();
        }
      },
      prefill: {
        name: window.localStorage.getItem("name"),
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const onUsePoint = (item) => {
    const data = {
      event: item.event,
      eventDate: item.eventDate,
      userId: id1,
      credit: item.credit,
      eventNum: item.eventNumber,
      contact: mobile,
    };

    axios
      .post(`${DOMAIN}/api/usepoint/${item._id}`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        window.localStorage.setItem("point", response.data.newPoint);
        window.localStorage.setItem(
          "eventNumbers",
          JSON.stringify(response.data.eventNumber)
        );
        setLink(response.data.link);
        handleOpen1();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const body = (
    <div style={modalStyle} className={classses.paper}>
      <h2 id="simple-modal-title">Fill to receive further update</h2>
      <TextField
        style={{ paddingBottom: "10px", width: "100%" }}
        value={mobile}
        variant="outlined"
        onChange={(e) => setMobile(e.target.value)}
        type="contact"
        label="WhatsApp No."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <WhatsAppIcon />
            </InputAdornment>
          ),
        }}
      />
      <div style={{ margin: "auto" }}>
        <button
          onClick={() => onUsePoint(eventNo)}
          className="test_apply_btn"
          style={{ width: "50%" }}
        >
          Book
        </button>
      </div>
    </div>
  );

  const body1 = (
    <div style={modalStyle} className={classses.paper}>
      <h2 id="simple-modal-title">Event Booked Successfully</h2>
      {payId.length > 0 && (
        <p className="simple-modal-desc" id="simple-modal-description">
          <strong>PaymentID</strong>
          {` `}
          {payId}
        </p>
      )}
      <p className="simple-modal-desc" id="simple-modal-description">
        You will receive further updates on your WhatsApp number.
      </p>
      <div className="buttons">
        <a
          href={link}
          onClick={handleClose1}
          style={{ textDecoration: "none", color: "black" }}
        >
          <button className="btn btn-primary btn-lg">Add to Calender</button>
        </a>
      </div>
    </div>
  );

  return (
    <div className={classes.fullpage}>
      <div className={classes.subpage}>
        <div className={classes.headdiv}>
          <Typography className={classes.head} variant="h3">
            Upcoming Entrepreneurial Events
          </Typography>
        </div>
        <div className={classes.contentdiv}>
          {members.map(
            (item, index) =>
              !item.isImportant && (
                <div className={classes.content} key={index}>
                  <div className={classes.imgdiv}>
                    <img
                      src={`${DOMAIN}/` + item.posterPath}
                      alt="Event_image"
                      className={classes.eventImage}
                    />
                  </div>
                  <div className={classes.description}>
                    <Typography className={classes.eventname}>
                      {item.event}
                    </Typography>
                    <Typography variant="h7" className={classes.speaker}>
                      Speaker:{item.speaker},
                      <ReactReadMoreReadLess
                        charLimit={25}
                        readMoreText={"Read more"}
                        readLessText={"Read Less"}
                      >
                        {item.aboutSpeaker}
                      </ReactReadMoreReadLess>
                    </Typography>
                    <Typography className={classes.time}>
                      {moment(item.eventDate).format("ll")}
                    </Typography>
                    <button
                      className={classes.bookbtn}
                      disabled={
                        eventNumbers?.indexOf(item.eventNumber) > -1 ||
                        (user_point < item.credit ? true : false)
                      }
                      onClick={() => {
                        handleOpen();
                        handleId2(eventlist.indexOf(item));
                      }}
                    >
                      {item.credit > 0 ? (
                        <h3>Use Points({item.credit})</h3>
                      ) : (
                        <h3>Book Free Event</h3>
                      )}
                    </button>
                    <button
                      disabled={item.eventfee === 0 ? true : false}
                      onClick={() => displayRazorpay(item)}
                      className={classes.bookbtn}
                    >
                      <h3>Book Now</h3>
                    </button>
                  </div>
                </div>
              )
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body1}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default EntreprenuerEvent;

import React, { useEffect } from "react";
import axios from "axios";
import ShareIcon from "../../../images/share_icon.png";
import CommentIcon from "../../../images/feedback_icon.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { useParams } from "react-router-dom";
import "../../../stylesheets/questionaire.css";

require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    //   textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    ["@media only screen and (max-width: 420px)"]: {
      width: 250,
      marginLeft: 0, // secondary
    },
  },
}));

export default function SharableIdeation({ isAuth }) {
  const classes = useStyles();
  const [ans, setAns] = React.useState([]);
  const [comments, setComments] = React.useState([]);
  const [postId, setPostId] = React.useState("");
  const [sharableLink, setSharableLink] = React.useState("");
  const [showAddNewComment, setShowAddNewComment] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [newComment, setNewComment] = React.useState("");
  const [newCommentAdded, setNewCommentAdded] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [copiedModalOpen,setCopiedModalOpen] = React.useState(false);

  let params = useParams();

  async function getData() {
    await axios
      .get(`${DOMAIN}/ideation/` + params.uid, {})
      .then((res) => {
        console.log(res.data.result);
        setPostId(params.uid);
        setAns(res.data.result.answers);
        setComments(res.data.result.comments);
        setSharableLink(
          window.location.href
        );
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [newCommentAdded]);

  function linkCopied(){
    setCopiedModalOpen(true);
    setTimeout(()=> {
      setCopiedModalOpen(false);
    },2000)
  }

  async function postComment() {
    if (isAuth) {
      const data = {
        userId: window.localStorage.getItem("id"),
        username: window.localStorage.getItem("name"),
        comment: newComment,
      };
      await axios
        .post(`${DOMAIN}/ideation/comment/` + postId, data, {
          headers: { authorization: window.localStorage.getItem("token") },
        })
        .then((res) => {
          getData();
          setNewComment("");
          setShowAddNewComment(false);
        })
        .catch((e) => console.log(e));
    } else {
      setShowErrorModal(true);
    }
  }

  const errorModal = (
    <div style={modalStyle} className={classes.paper}>
      <div class="question_containers">
        <h1 className="questionaire_questions_heading">
          You need to be logged in to comment!
        </h1>
      </div>
    </div>
  );

  const addNewCommentModal = (
    <div style={modalStyle} className={classes.paper}>
      <div class="question_containers">
        <h1 className="questionaire_questions_heading">Share your thoughts!</h1>
        <textarea
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="test_input1 comment_input"
          placeholder="Add new comment..."
          maxLength="200"
          style={{ marginBottom: "2%" }}
        />
        <button
          type="submit"
          className="test_apply_btn ideating_btn"
          style={{ width: "20%", fontSize: "1.2rem" }}
          onClick={() => postComment()}
        >
          Post
        </button>
      </div>
    </div>
  );

  const copied = (
    <div style={modalStyle} className={classes.paper}>
      <p>Link Copied!</p>
    </div>
  )
  return (
    <div style={{ marginTop: "5%" }}>
      <div className="sample_box">
        <h1 className="question_lines">{ans[10]}</h1>
        <p className="sample_para">
          I <span className="sample_para_answers">{ans[0]}</span> , skilled in{" "}
          <span className="sample_para_answers">{ans[1]}</span> have observed
          that <span className="sample_para_answers">{ans[2]}</span>. This
          impacts <span className="sample_para_answers">{ans[3]}</span> due to
          which they face <span className="sample_para_answers">{ans[4]}</span>.
          I can say this because{" "}
          <span className="sample_para_answers">{ans[5]}</span>.
        </p>
        <p className="sample_para">
          I would like to propose{" "}
          <span className="sample_para_answers">{ans[6]}</span>. To validate my
          idea <span className="sample_para_answers">{ans[7]}</span>.
        </p>
        <p className="sample_para">
          My Idea is dependent on the factor that{" "}
          <span className="sample_para_answers">{ans[8]}</span>. I would need
          someone skilled in{" "}
          <span className="sample_para_answers">{ans[9]}</span> as my
          Co-Founder/team mate.
        </p>
        <div className="sample_box_icons">
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              navigator.clipboard.writeText(sharableLink);
              linkCopied()
            }}
          >
            <img src={ShareIcon} alt="share-icon" className="sample_icons" />
          </button>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => setShowAddNewComment(!showAddNewComment)}
          >
            <img src={CommentIcon} alt="share-icon" className="sample_icons" />
          </button>
        </div>
        <div className="show_comments_box">
          {showComments ? (
            <IconButton onClick={() => setShowComments(false)}>
              <KeyboardArrowUpIcon size={32} />
            </IconButton>
          ) : (
            <IconButton onClick={() => setShowComments(true)}>
              <KeyboardArrowDownIcon size={32} />
            </IconButton>
          )}
        </div>
        <div className="comments_container">
         
          {showAddNewComment ? (
             <div class="question_containers">
             <h1 className="questionaire_questions_heading">
               Share your thoughts!
             </h1>
             <textarea
               type="text"
               value={newComment}
               onChange={(e) => setNewComment(e.target.value)}
               className="test_input1 comment_input"
               placeholder="Add new comment..."
               maxLength="200"
               style={{ marginBottom: "2%" }}
             />
             <button
               type="submit"
               className="test_apply_btn ideating_btn"
               style={{ width: "20%", fontSize: "1.2rem" }}
               onClick={() => postComment()}
             >
               Post
             </button>
           </div>
          ) : (
            null
          )}
          {showComments
            ? comments.map((obj, i) => {
                return (
                  <div className="comment_container" key={obj._id}>
                    <p
                      className="sample_para comment_name"
                      style={{ fontWeight: "bold" }}
                    >
                      {obj.username}
                    </p>
                    <p className="sample_para comment_content">{obj.comment}</p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* <Modal
        open={showAddNewComment}
        onClose={() => setShowAddNewComment(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {addNewCommentModal}
      </Modal> */}
      <Modal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {errorModal}
      </Modal>
      <Modal
        open={copiedModalOpen}
        onClose={() => setCopiedModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {copied}
      </Modal>
    </div>
  );
}


import { BASE_URL } from "./client";


const GET_B1 = `${BASE_URL}/main/b1/6333e954e41dfe695815f596`;
const GET_B2 = `${BASE_URL}/main/b2/63351f355a55005eb8217b0f`;

const GET_B3 = `${BASE_URL}/main/b3/6336684174939e23c02719cd`;

const GET_B4 = `${BASE_URL}/main/b4`;

const GET_B5 = `${BASE_URL}/main/b5/633a69d5456a4779c41404bb`;

const GET_B6 = `${BASE_URL}/main/b6/633e5940b8e574a2303b40e5`;

const GET_B7 = `${BASE_URL}/main/b7/`;
const GET_B8 = `${BASE_URL}/main/b8/`;
const GET_B9 = `${BASE_URL}/main/b9/`;
const POST_CONTACT = BASE_URL + "/api/email/contactus";

export {
  GET_B1,
  GET_B2,
  GET_B3,
  GET_B4,
  GET_B5,
  GET_B6,
  GET_B7,
  GET_B8,
  GET_B9,
  POST_CONTACT,
};

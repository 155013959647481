//import "../../stylesheets/user_profile.css";

import { Link } from "react-router-dom";

function UserProfile() {
  return (
    <div className="App">
        <div>
          <div className="mkw_sidebar " style={{width: '25%', right: 0}}>
            <div class="mkw_sidebar_btn">
            <button href="#" class="mkw_sidebar_button">Notification</button>
            </div>
            <div class="mkw_sidebar_btn">
            <Link to="/experienceproflie" style={{"textDecoration":"none"}}><button href="#" class="mkw_sidebar_button">Experience Profile</button></Link>
            </div>
            <div class="mkw_sidebar_btn">
            <button href="#" class="mkw_sidebar_button">Ideations</button>
            </div>
            <div class="mkw_sidebar_btn">
            <button href="#" class="mkw_sidebar_button">Messages</button>
            </div>
            <div class="mkw_sidebar_btn">
            <button href="#" class="mkw_sidebar_button">My Applications</button>
            </div>
          </div>        
        </div>
        
   <div className="mkw_user_box">
    <div className="mkw_container">
     <div class="mkw_row">
      <div class="mkw_col_sm mkw_profile_img" >
        <div class="mkw_imageContainer">
          <img src="https://ui-avatars.com/api/?name=Janender Sharma&amp;size=1000&amp;bold=true&amp;background=36202d&amp;color=ffffff" class="mkw_profile_pic" alt="profile_pic" />
        </div>
      </div>
      <div class="mkw_col_sm mkw_username">
      <div >
        <h1>Janender Sharma</h1>
        <h5>Business Development Head At Markoknow
          <br/>
          Markoknow Points: 50
        </h5>
      </div>  
    </div>
    <div class="mkw-row mkw_headers">
        <h5>Meetups</h5>
        <h5>Workshops</h5> 
        <h5>Sessions</h5>
        <h5>Experience</h5>
        <h5>Connections</h5>
        <h5>Sources</h5> 
      </div> 
      <div class="mkw_row ">
      <button href="#" class="mkw_sidebar_button mkw_user_profile_btn">Improve Score</button>
      <button href="#" class="mkw_sidebar_button mkw_user_profile_btn">Share Achievement</button>
      </div>
    </div>
  </div>

  </div>

    
    </div>
  );
}

export default UserProfile;


// import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/core";
import ChatContacts from "./ChatContacts";
// import ChatBox from "./ChatBox";
import axios from "axios";
import { Grid } from "@material-ui/core";
import Conversation from "../Components/Conversation/index";
const DOMAIN = process.env.REACT_APP_DOMAIN;

// const useStyles = makeStyles({
//   main: {
//     display: "flex",
//     md: "8",
//     marginTop: "30px",
//   },
//   left: {
//     display: "none",
//   },
//   mid: {
//     flex: 5,
//   },
//   right: {
//     flex: 3,
//   },
//   "@media screen and (max-width: 480px)": {
//     mid: {
//       height: "70vh",
//       backgroundColor: "yellow",
//       marginTop: "0px",
//     },
//     right: {
//       display: "none",
//     },
//     left: {
//       display: "none",
//     },
//   },
// });

const ChatStart = () => {
  // states;
  const [recentChat, setRecentChat] = useState([]);
  const [conversationId, setConversationId] = useState();
  const [oppuser, setOppuser] = useState();
  useEffect(() => {
    const getRecentChat = () => {
      const userId = window.localStorage.getItem("id");
      axios
        .get(`${DOMAIN}/api/conversation/${userId}`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setRecentChat(res.data);
        });
    };
    getRecentChat();
  }, []);

  // const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={12} md={4} lg={4}>
          <ChatContacts
            recentChat={recentChat}
            setConversationId={setConversationId}
            setOppuser={setOppuser}
          />
        </Grid>
        <Grid item xs={8} sm={12} md={8} lg={8}>
          <Conversation conversationId={conversationId} oppuser={oppuser} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatStart;

import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Button, Modal } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

const useStyles = makeStyles({
  allex: {
    display: "flex",
    margin: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    alignItems: "center",
    justifyContent: "center",
    color: "#86FFCF",
    margin: 0,
    padding: 0,
  },
  text: {
    color: "#1a202c",
    margin: 10,
    fontSize: 25,
  },
  box: {
    margin: 0,
    padding: 0,
  },
  streakbtn: {
    height: "1rem",
    width: "1rem",
    backgroundColor: "red",
    borderRadius: 100,
  },
  applyboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "200px",
  },
  applybox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    width: "30%",
    backgroundColor: "white",
    flexDirection: "column",
    borderRadius: "4px",
  },
  applyboxbtns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  viewboxbtn: {
    backgroundColor: "yellow",
  },
});

const commonStyles = {
  bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "2rem",
  height: "2rem",
  borderRadius: 100,
  margin: 0,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const commonbox1Styles = {
  bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "2rem",
  height: "0.3rem",
  margin: 0,
  padding: 0,
};
const commonbox2Styles = {
  bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0,
};

const StreakButtons = () => {
  // react hooks
  const [openbox, setOpenbox] = useState(false);
  const classes = useStyles();
  const [streaks, setStreaks] = useState([]);
  const [length, setlength] = useState(0);
  const [streak, setStreak] = useState();
  const [streakname, setStreakname] = useState("opps, we coudnt find name");
  const [streakid, setStreakid] = useState();
  const [data, setData] = useState([]);
  const [requestStart, setRequestStart] = useState(true);

  const history = useHistory();

  //data of all videos
  useEffect(() => {
    axios
      .get(`${DOMAIN}/watchnow`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
  }, [requestStart]);

  //get list of streak videos
  useEffect(() => {
    axios
      .get(`${DOMAIN}/watchnow/streaklist`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setStreaks(res.data);
        setlength(res.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //console.log(streaks[0]);
  //console.log(length);

  //find requied streak onclicked once
  const Findstreak = (id) => {
    data.map((val) => {
      if (val._id === id) {
        setStreak(val);
        setStreakname(val.title);
      }
    });
  };
  //console.log(streak);

  //remove from streak
  const RemovefromStreak = (val) => {
    axios
      .post(
        `${DOMAIN}/watchnow/streak/remove`,
        { watchnowId: val },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((res) => {
        alert("video removed from streak");
        window.location.reload();
        console.log("hogaya");
      })
      .catch((err) => {
        alert("try again later");
        console.log(err);
      });
  };

  //complete the streak
  const CompleteStreak = (val) => {
    axios
      .post(
        `${DOMAIN}/watchnow/complete`,
        { watchnowId: val },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((res) => {
        alert("video added to completed list");
        window.location.reload();
        console.log("hogaya");
      })
      .catch((err) => {
        alert("try again later");
        console.log(err);
      });
  };
  //pop ups
  const viewbox = (
    <Box className={classes.applyboxContainer}>
      <Box className={classes.applybox}>
        <Typography className={classes.applyboxheading1}>
          {streakname}
        </Typography>
        <Box className={classes.applyboxbtns}>
          <Button
            className={classes.viewboxbtn}
            onClick={() => {
              history.push("/watch_now/watch", { link: streak.link });
            }}
            style={{ margin: "3px" }}
          >
            View
          </Button>
          <Button
            className={classes.viewboxbtn}
            onClick={() => {
              CompleteStreak(streakid);
            }}
            style={{ margin: "3px" }}
          >
            Completed
          </Button>
          <Button
            className={classes.viewboxbtn}
            onClick={() => {
              RemovefromStreak(streakid);
            }}
            style={{ margin: "3px" }}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );
  return (
    <Container className={classes.allex}>
      <Typography className={classes.text}>7 Topic Streak</Typography>
      <Box className={classes.box} sx={{ ...commonStyles, border: 2 }}>
        {length > 0 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[0]);
              Findstreak(streaks[0]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonStyles, border: 2 }}>
        {length > 1 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[1]);
              Findstreak(streaks[1]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonStyles, border: 2 }}>
        {length > 2 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[2]);
              Findstreak(streaks[2]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonStyles, border: 2 }}>
        {length > 3 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[3]);
              Findstreak(streaks[3]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonStyles, border: 2 }}>
        {length > 4 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[4]);
              Findstreak(streaks[4]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonStyles, border: 2 }}>
        {length > 5 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[5]);
              Findstreak(streaks[5]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ ...commonbox2Styles, border: 1 }} />
      <Box sx={{ ...commonStyles, border: 2 }}>
        {length > 6 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            className={classes.streakbtn}
            onClick={() => {
              setStreakid(streaks[6]);
              Findstreak(streaks[6]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Modal
        open={openbox}
        onClose={() => {
          setOpenbox(false);
        }}
      >
        {viewbox}
      </Modal>
    </Container>
  );
};

export default StreakButtons;

import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
// import Carousel from "react-material-ui-carousel";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const u = makeStyles({
  body: {
    backgroundColor: "#6b58cd",
    color: "white",
    paddingTop: "100px",
    height: "110vh",
    "@media only screen and (max-width: 960px)": {
      paddingTop: "10px",
      height: "120vh",
    },
    "@media only screen and (max-width: 480px)": {
      paddingTop: "10px",
      height: "100vh",
    },
  },
  container: {
    marginRight: "100px",
    marginLeft: "100px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "@media only screen and (max-width: 480px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  heading: {
    // fontFamily: "Garet ExtraBold",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "bold",
    backgroundColor: "white",
    color: "black",
    borderRadius: "12px",
    marginBottom: "20px",
    width: "30%",
    marginLeft: "400px",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "150px",
      marginBottom: "20px",
      maxWidth: "40%",
      width: "auto",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "0px",
      fontSize: "25px",
      marginBottom: "20px",
      maxWidth: "50%",
      width: "auto",
    },
  },
  subheading: {
    fontFamily: "MADE Evolve Sans",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "25px",
    marginBottom: "50px",
    marginLeft: "200px",
    width: "60%",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "0px",
      marginBottom: "30px",
      width: "auto",
      maxWidth: "100%",
      fontSize: "20px",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "0px",
      marginBottom: "30px",
      width: "auto",
      maxWidth: "100%",
    },
  },
  question: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    backgroundColor: "white",
    color: "black",
    width: "80%",
    fontSize: "30px",
    height: "150px",
    marginLeft: "100px",
    borderRadius: "15px",
    "@media only screen and (max-width: 960px)": {
      marginLeft: "50px",
      marginRight: "50px",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      borderRadius: "20px",
      textAlign: "center",
    },
    "@media only screen and (max-width: 480px)": {
      marginLeft: "50px",
      marginRight: "50px",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      borderRadius: "20px",
      textAlign: "center",
    },
  },
  options: {
    fontSize: "25px",
    marginTop: "20px",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    width: "600px",
    alignItems: "center",
    textAlign: "center",
    marginLeft: "375px",
    "@media only screen and (max-width: 960px)": {
      marginRight: "10px",
      marginLeft: "50px",
      width: "300px",
    },
    "@media only screen and (max-width: 480px)": {
      marginRight: "10px",
      marginLeft: "50px",
      width: "300px",
    },
  },
  options1: {
    backgroundColor: "#ff5757",
    borderRadius: "5px",
    height: "200px",
    width: "200px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "30px",
    border: "0px",
    marginRight: "50px",
    "&:focus": {
      backgroundColor: "lightgreen",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "lightgreen",
    },
    "@media only screen and (max-width: 960px)": {
      marginRight: "50px",
      fontSize: "20px",
      height: "100px",
      width: "200px",
    },
    "@media only screen and (max-width: 480px)": {
      marginRight: "25px",
      height: "100px",
      width: "200px",
      fontSize: "18px",
    },
  },
  options2: {
    backgroundColor: "#ffbd59",
    border: "0px",
    height: "200px",
    width: "200px",
    textAlign: "center",
    borderRadius: "5px",
    fontSize: "30px",
    fontWeight: "bold",
    "&:focus": {
      backgroundColor: "lightgreen",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "lightgreen",
    },
    "@media only screen and (max-width: 960px)": {
      marginRight: "20px",
      fontSize: "20px",
      height: "100px",
      width: "200px",
    },
    "@media only screen and (max-width: 480px)": {
      marginRight: "20px",
      height: "100px",
      width: "200px",
      fontSize: "18px",
    },
  },
  button: {
    "&:focus": {
      transform: "translateY(-8px)",
      backgroundColor: "lightgreen",
    },
    "&:hover": {
      transform: "translateY(-3px)",
      animation: " rotate 1s linear",
      color: "white",
      backgroundColor: "lightgreen",
    },
    "@media only screen and (max-width: 420px)": {
      height: "120px",
      width: "100%",
      fontSize: "12px",
    },
  },
  submitButton: {
    display: "flex",
    marginLeft: "550px",
    width: "10%",
    "@media only screen and (max-width: 960px)": {
      width: "15%",
      marginBottom: "50px",
      fontSize: "12px",
      marginLeft: "100px",
    },
    "@media only screen and (max-width: 480px)": {
      width: "15%",
      marginBottom: "50px",
      fontSize: "12px",
      marginLeft: "0px",
    },
  },
  report: {
    display: "flex",
    marginLeft: "550px",
    backgroundColor: "cyan",
    border: "0px",
    width: "20%",
    "@media only screen and (max-width: 960px)": {
      width: "15%",
      marginBottom: "100px",
      fontSize: "12px",
    },
  },
  button3: {
    backgroundColor: "white",
    marginTop: "30px",
    height: "50px",
    width: "100px",
    "&:focus": {
      backgroundColor: "lightgreen",
    },
    "&:hover": {
      animation: " rotate 1s linear",
      color: "white",
    },
    "@media only screen and (max-width: 960px)": {
      height: "50px",
      width: "200px",
    },
  },
  marksSet: {
    textAlign: "center",
    marginTop: "50px",
    fontSize: "40px",
    fontWeight: "bold",
    color: "black",
    fontWeight: "bold",
    "@media only screen and (max-width: 960px)": {
      marginTop: "3px",
      fontSize: "20px",
    },
  },
});

function Compatibility() {
  const classes = u();
  const [ques, setQues] = useState([]);
  const [marks, setMarks] = useState("");
  const [current, setCurrent] = useState(0);
  const length = ques.length;
  const nextQuestion = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  // const prevQuestion=()=>{
  //   setCurrent(current===0? length-1: current-1);
  // }
  const [submitted, getSubmitted] = useState(false);

  const [mapState, setMapState] = useState(new Map());
  const getQuestions = () => {
    axios
      .get(`${DOMAIN}/api/compatibilityTest`)
      .then((res) => {
        setQues(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitQuestions = (e) => {
    console.log(e);
    e = Object.fromEntries(e);
    axios
      .post(
        `${DOMAIN}/api/compatibilityTest`,
        { ans: e },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setMarks(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
    getSubmitted(true);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      <div className={classes.body}>
        <div className={classes.container}>
          <div className={classes.heading}>Compatibility Test</div>

          <Grid className={classes.subheading}>
            <p>
              Take the test to understand yourself as an Entrepreneur and
              understand your strengths and weaknesses and how suitable you are
              to start a startup
            </p>
          </Grid>

          <div>
            {ques.length &&
              ques.map((item, index) => (
                <>
                  {index == current && (
                    <Grid container>
                      <div className={classes.question} key={index}>
                        {item.question}
                      </div>
                      <div className={classes.options}>
                        <button
                          className={classes.options1}
                          onClick={() => {
                            setMapState(
                              (map) => new Map(map.set(item._id, item.option1))
                            );

                            nextQuestion();
                          }}
                        >
                          {item.option1}
                        </button>
                        <button
                          className={classes.options2}
                          onClick={() => {
                            setMapState(
                              (map) => new Map(map.set(item._id, item.option2))
                            );
                            nextQuestion();
                          }}
                        >
                          {item.option2}
                        </button>
                      </div>
                    </Grid>
                  )}
                </>
              ))}
          </div>

          <div className={classes.submitButton}>
            <Button
              className={classes.button3}
              onClick={() => {
                submitQuestions(mapState);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        {submitted ? (
          <>
            <div
              className={classes.heading}
              style={{
                marginTop: "30px",
                marginLeft: "450px",
              }}
            >
              Congratulations ! You are {marks} Suitable to be an Entrepreneur
            </div>
            <Button className={classes.report}>View Detailed Report</Button>
          </>
        ) : null}
      </div>
    </>
  );
}

export default Compatibility;

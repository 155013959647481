import React from "react";
import { useState } from "react";
//import { css } from '@emotion/react'
import SkillSearchpopup from "./SkillSearchpopup";
import MessagePopup from "../../MessagePopup/index";
import axios from "axios";
import { useStyles } from "./style";
// import Backdrop from '@material-ui/core/Backdrop'
// import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from "react-router-dom";
const DOMAIN = process.env.REACT_APP_DOMAIN;

function SkillSearch() {
  const classes = useStyles();
  const [skill1, setSkill1] = useState("");
  const [skill2, setSkill2] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPopup, setOPenPopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useHistory();

  const onclickHandler = () => {
    if (skill1 === "" || skill2 === "") {
      setAlertMessage("please select two skills !");
      setOpenAlert(true);
      return;
    }
    setOPenPopup(true);
    setLoading(!loading);
    setOpen(!open);
    axios
      .post(`${DOMAIN}/cofounder`, { primary: skill1, secondary: skill2 })
      .then((res) => {
        const data = res.data;
        console.log(data.length);
        if (data.length === 0) {
          history.push("/co_founder_create_profile");
        } else history.push("/co_founder_match", { data: res.data });
        setLoading(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
        setLoading(true);
      });
  };

  function handlesubmit(skill) {
    if (skill1 === skill) {
      setSkill1("");
    } else if (skill2 === skill) {
      setSkill2("");
    } else {
      if (skill1 === "") {
        setSkill1(skill);
      } else if (skill2 === "") {
        setSkill2(skill);
      }
    }
  }

  if (loading)
    return (
      <SkillSearchpopup
        openPopup={openPopup}
        setOPenPopup={setOPenPopup}
      ></SkillSearchpopup>
    );
  return (
    <div className={classes.fullpage}>
      <MessagePopup
        open={openAlert}
        handleAlertClose={() => setOpenAlert(false)}
        message={alertMessage}
      />
      <div className={classes.innerdiv}>
        <h2 className={classes.heading}>Co Founders Match</h2>
        <p>
          Get the best matching co Founder profile to connect and try your
          startup
        </p>
        <div className={classes.inputdiv}>
          <h2 className={classes.heading2}>Co Founder Skills you Require</h2>
          <div className={classes.input}>
            <h3>
              {" "}
              {skill1} {skill2}{" "}
            </h3>
          </div>

          <button className={classes.btn} onClick={onclickHandler}>
            Search
          </button>
        </div>

        <div className={classes.btnsection}>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Business Development");
            }}
          >
            Business Development
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Marketing");
            }}
          >
            Marketing
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Coding");
            }}
          >
            Coding
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Finance");
            }}
          >
            Finance
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("UI/UX");
            }}
          >
            UI/UX
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Project Management");
            }}
          >
            Project Management
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Leadership");
            }}
          >
            LeaderShip
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Operations");
            }}
          >
            Operations
          </button>
          <button
            className={classes.btns}
            onClick={function (event) {
              handlesubmit("Type Own");
            }}
          >
            Type Own
          </button>
        </div>
      </div>
    </div>
  );
}
export default SkillSearch;

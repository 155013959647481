import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Axios from "axios";
const DOMAIN = process.env.REACT_APP_DOMAIN;
function NotificationBar() {
  const [notif, setNotif] = React.useState(true);
  const [body, setBody] = useState("");
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  useEffect(() => {
    Axios.get(`${DOMAIN}/announcement/read/MARKOKNOW`).then((response) => {
      setBody(response.data.body);
      setLink(response.data.link);
      setTitle(response.data.title);
    });
  }, []);
  return (
    <>
      {notif ? (
        <>
          <div
            style={{
              backgroundColor: "#251e4f",
              marginBottom: "0px",
              paddingBottom: "8px",
              paddingTop: "8px",
              textAlign: "center",
            }}
          >
            <div>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    color: "yellow",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                >
                  {title}
                </span>
                {"  "}
                <span
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "600",
                  }}
                >
                  {body}
                </span>
              </a>
              <CloseIcon
                style={{ float: "right" }}
                onClick={() => {
                  setNotif(false);
                }}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default NotificationBar;

const {BASE_URL} = require('./client');

const GET_COURSE = BASE_URL +'/program/courses/';
const GET_PROMOCODES = BASE_URL+ "/api/paymentcheckout/promocode";
const PP = BASE_URL + '/api/payments';
const USER_REFFERAL = BASE_URL + "/user/";
const CART_REMOVE_SINGLE = BASE_URL + '/api/cartremove/single/';


module.exports = {
    GET_COURSE,
    GET_PROMOCODES,
    PP,
    USER_REFFERAL,
    CART_REMOVE_SINGLE
}
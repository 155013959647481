import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
// import BlogPage from "./blogPage";
import { CircularProgress } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
const DOMAIN = process.env.REACT_APP_DOMAIN;
// import { useNavigate } from "react-router-dom";

// import {useNavigate} from 'react-router-dom@6.0.0-alpha.2';

const Blogs = () => {
  const classes = u();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // ALl Data request
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${DOMAIN}/blogs`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={classes.body}>
      {loading ? (
        <CircularProgress style={loader} size={45} />
      ) : (
        <div className={classes.container}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800">
              InSights
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
            textAlign="center"
          >
            <Typography variant="h6">
              Go across with diving deep into the stories and journey's of
              various startups from bootstrapped till funded
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {blogs.map((blog) => {
              return (
                <Box className={classes.box}>
                  <Box className={classes.imgBox}>
                    <img
                      src={`${DOMAIN}/${blog.imagePath}`}
                      alt="..."
                      className={classes.img}
                    ></img>
                  </Box>
                  <Box className={classes.textBox}>
                    <Typography className={classes.heading}>
                      {blog.title}
                    </Typography>
                    <Typography className={classes.subhead}>
                      {blog.subTitle}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="end"
                      marginTop="1rem"
                      position="sticky"
                    >
                      <Link
                        to={`blog/${blog._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "15px",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backdropFilter: "blur(10px)",
                            backgroundColor: "#3498db",
                          }}
                        >
                          Read More
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </div>
      )}
    </div>
  );
};

const u = makeStyles({
  box: {
    width: "77vw",
    backgroundColor: "#6A58c6",
    padding: "10px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    // border: "1px solid red",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  textBox: {
    display: "block",
    width: "60%",
    fontFamily:
      "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
    fontSize: "20px",
    color: "#EDEDF1",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  imgBox: {
    objectFit: "cover",
    width: "40%",
    height: "25vh",
    marginTop: "auto",
    marginRight: "1rem",
    marginBottom: "auto",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    backgroundColor: "#221857",
    color: "white",
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    minHeight: "100vh",
    // border: "0px solid blue",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    backgroundColor: "#221857",
    alignItems: "center",
    wordWrap: "break-word",
    borderRadius: "12px",
    whiteSpace: "initial",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      padding: ".5rem",
      fontSize: "16px",
      borderRadius: "8px",
    },
  },
  subhead: {
    wordWrap: "break-word",
    paddingTop: "10px",
  },
});

const loader = {
  // backgroundColor: 'rgba(52, 52, 52, 0.8)',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
};

export default Blogs;

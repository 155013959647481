import React from 'react';
import Footer from '../About/Footer'

const RefundPolicy = () => {
    const styles = {
        container: {
          padding: '40px',
          borderRadius: '4px',
        },
        title: {
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '10px',
          textAlign: 'center',
        },
      };
  return (
    <>
    <div className="Maincontainer" style={{background:'#fff'}}>
    <div style={styles.container}>
    <h1 style={{textAlign:'center'}}>Refund and Cancellation Policy</h1>
        
        <p>This Refund and Cancellation Policy ("Policy") is applicable to all users ("User" or "You") of Markoknow Private Limited ( markoknow.com ), owned and operated by Markoknow Private Limited ("Company" or "We" or "Us"), a Private Limited Company registered under the laws of India. By using the Website, you agree to adhere to the terms outlined in this Policy.</p>

        <h2>1. Refund Eligibility and Period:</h2>
        <p>Refund requests for courses purchased through the Website will be considered only if the request is made within 48 hours from the time of purchase.</p>

        <h2>2. Refund Process:</h2>
        <p>To request a refund, you must contact our customer support team through the contact details provided on the Website within the stipulated 48-hour period. Or apply for a refund as per the process informed on the website. The decision to grant a refund lies solely with the management of the Company. Our decision is final and binding.</p>

        <h2>3. Non-Refundable Situations:</h2>
        <p>Refunds will not be granted if the 48-hour refund eligibility period has expired. Refunds will not be provided for courses that have been fully completed, or certificate generated.</p>

        <h2>4. Course Modifications:</h2>
        <p>The Company reserves the right to modify any course offered on the Website, including changes to course content, instructors, and schedules to benefit the students with more elaborate courses. In such cases, Users will be given the option to continue with the modified course, and this won’t make the user eligible for a refund.</p>

        <h2>5. Validity: </h2>
        <p>The courses purchased on the website would be valid for usage as mentioned on the particular course, bundle, package etc. Incase that is not mentioned over there, there would be validity of 1 yr for that particular course, bundle, package etc.</p>

        <h2>6. Termination of Services:</h2>
        <p>The Company reserves the right to terminate or suspend access to the Website and its services at its discretion. In the event of such termination, Users will be provided with applicable refund options as determined by the Company.</p>

        <h2>7. Maximum Refund Amount:</h2>
        <p>In all cases, the refund amount shall not exceed the actual amount paid by the User for the purchased course. Regardless of the circumstances surrounding the refund request, the maximum refund shall be limited to the course purchase price. Any other costs incurred by the User, such as transaction fees, currency conversion charges, or any third-party charges, will not be included in the refund amount and will not be reimbursed by the Company. By agreeing to this Policy, you acknowledge and agree to the limitation on refund amounts as specified in Clause 6.</p>

        <h2>8. Compliance with Indian Laws:</h2>
        <p>This Policy is governed by the laws of India, and any disputes arising from or in connection with this Policy shall be subject to the exclusive jurisdiction of the courts of Gurugram, Haryana, India.</p>

        <h2>9. Contact Information:</h2>
        <p>For any questions, concerns, or refund requests, please contact our customer support team at <a href="mailto:hello@markoknow.com">hello@markoknow.com</a>.</p>

        <p>By using the Website, you acknowledge and agree to the terms and conditions outlined in this Policy. The Company reserves the right to modify or update this Policy from time to time without prior notice. It is your responsibility to review this Policy periodically for any changes.</p>
    </div>
  </div>
  <Footer/>
  </>
  )
}

export default RefundPolicy;
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";
import "../../stylesheets/about.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
// import PeopleIcon from "@mui/icons-material/People";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Button } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import Avatar from "@mui/material/Avatar";
import EmailIcon from "@mui/icons-material/Email";
// import Email from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
const Footer = ({ isAuth }) => {
  const isLogin = isAuth;
  return (
    <div className="footer_about">
      <BrowserView>
        <div className="community">
          <div className="text">
            Connect with a growing community of learners
          </div>
          <Link to={`/community`}>
            {" "}
            <div className="whatsapp">Join our Community</div>
          </Link>
        </div>
        <hr className="solid1" />
        <div className="follow">
          <div className="follow_text">Follow us- </div>
          <div className="follow_icons">
            <a
              target="_blank"
              href="https://www.instagram.com/markoknow/"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",

                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/markoknow/"
              rel="noopener noreferrer"
            >
              <LinkedInIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",

                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://mobile.twitter.com/markoknow1"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",

                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/markoknow1/"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",
                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@markoknow"
              rel="noopener noreferrer"
            >
              <YouTubeIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",
                  transition: "transform 0.2s",
                }}
                className="hover-effect"
              />
            </a>
          </div>
        </div>
        <hr className="solid2" />
        <Grid container>
          <Grid className="footer_grid" item md={4} sm={12} xs={12}>
            <h1 className="footer_head1">About</h1>
            <p className="footer_para">
              Markoknow is a 25,000 + community members of learners, founders
              and investors together engaging and interacting. Making
              Opportunities to make "Entrepreneurship for all".
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1">Navigate</h1>
            <p className="footer_para">
              <Link to={`/member`} className="footer_link">
                Membership
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/partnerwithus`} className="footer_link">
                Partner
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="https://calendly.com/markoknow/30min"
                target="_blank"
                className="footer_link"
              >
                Mentorship
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/cofounderskillsearch`} className="footer_link">
                Co Founder
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/feedback`} className="footer_link">
                Feedback
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="https://www.linkedin.com/company/markoknow/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer_link"
              >
                Career
              </a>
            </p>
            <p className="footer_para">
              <a
                href="https://buddy.markoknow.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer_link"
              >
                Courses
              </a>
            </p>
            {/* <p className="footer_para">
              <Link to={`/fund`} className="footer_link">
                Fundings
              </Link>
            </p> */}
            <p className="footer_para">
              <Link to={`/blog`} className="footer_link">
                Blogs
              </Link>
            </p>
            {isLogin ? (
              <p className="footer_para">
                <Link
                  to={`/profile/` + window.localStorage.getItem("id")}
                  className="footer_link"
                >
                  Profile
                </Link>
              </p>
            ) : (
              <p className="footer_para">
                <Link to={`/watch_now`} className="footer_link">
                  Watch Now
                </Link>
              </p>
            )}
          </Grid>
          <Grid className="footer_grid" item md={1} sm={12} xs={12}>
            <h1 className="footer_head1">Quick Link</h1>
            <p className="footer_para">
              <Link to={`/privacy`} className="footer_link">
                Privacy Policy
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/terms_conditions`} className="footer_link">
                Terms And Conditions
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/refund&cancellationpolicy`} className="footer_link">
                Refund & Cancellation Policy
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/about`} className="footer_link">
                About us
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/contact`} className="footer_link">
                Contact us
              </Link>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1">Contact Us</h1>
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar style={{ background: "#6A58c6" }}>
                <CallIcon style={{ color: "#f0f0f0" }} />
              </Avatar>
              +91-9310148556
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{
                  background: "#6A58c6",
                }}
              >
                <EmailIcon style={{ color: "#fff" }} />
              </Avatar>
              <a href="mailto:hello@markoknow.com" className="footer_link">
                hello@markoknow.com
              </a>
            </div>
            <p style={{ marginLeft: "2rem" }}>
              ( Usually responds within 24 hrs. )
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // border: "1px solid red",
                marginTop: "0.4rem",
              }}
            >
              <Avatar
                style={{
                  background: "#6A58c6",
                }}
              >
                <BusinessIcon style={{ color: "#fff" }} />
              </Avatar>
              <p>
                Forum, Cyber City, DLF Forum, DLF Tower 10th Rd, Phase III,
                Gurugram, Haryana 122002
              </p>
            </div>
          </Grid>
          <Grid
            className="footer_grid"
            item
            md={3}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "200px",
                textAlign: "center",
                fontSize: "1.8rem",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#ffffff",
              }}
            >
              Get Started
              <p
                style={{
                  color: "#ffdb66",
                  fontSize: "1rem",
                  lineHeight: "28px",
                  fontWeight: "600",
                }}
              >
                Join the Program
              </p>
            </div>
            <div>
              <Link to={`/signup`}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    padding: "13px",
                    borderRadius: "12px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    lineHeight: "24px",
                    fontSize: "16px",
                    backgroundColor: "#432eb0",
                    textTransform: "capitalize",
                  }}
                >
                  Get started
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <hr className="solid3" />
        <p style={{ color: "#fff", textAlign: "center" }}>
          © 2023 Markoknow - All Rights Reserved
        </p>
      </BrowserView>
      <MobileView>
        <div className="community_mobile">
          <div className="text_mobile">
            Connect with a growing community of learners
          </div>
          <Link to={`/community`}>
            <div className="whatsapp_mobile">Join our Community</div>
          </Link>
        </div>
        <hr className="solid1_mobile" />

        <Grid container>
          <Grid className="footer_grid" item md={6} sm={12} xs={12}>
            <h1 className="footer_head1">About</h1>
            <p className="footer_para">
              Markoknow is a 25,000 + community members of learners, founders
              and investors together engaging and interacting. Making
              Opportunities to make "Entrepreneurship for all".
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1">Navigate</h1>
            <p className="footer_para">
              <Link to={`/member`} className="footer_link">
                Membership
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/partnerwithus`} className="footer_link">
                Partner
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="https://calendly.com/markoknow/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="footer_link"
              >
                Mentorship
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/cofounderskillsearch`} className="footer_link">
                Co Founder
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/feedback`} className="footer_link">
                Feedback
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="https://www.linkedin.com/company/markoknow/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer_link"
              >
                Career
              </a>
            </p>
            <p className="footer_para">
              <a
                href="https://buddy.markoknow.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer_link"
              >
                Courses
              </a>
            </p>
            {/* <p className="footer_para">
              <Link to={`/fund`} className="footer_link">
                Fundings
              </Link>
            </p> */}
            <p className="footer_para">
              <Link to={`/blog`} className="footer_link">
                Blogs
              </Link>
            </p>
            {isLogin ? (
              <p className="footer_para">
                <Link
                  to={`/profile/` + window.localStorage.getItem("id")}
                  className="footer_link"
                >
                  Profile
                </Link>
              </p>
            ) : (
              <p className="footer_para">
                <Link to={`/watch_now`} className="footer_link">
                  Watch Now
                </Link>
              </p>
            )}
          </Grid>
          <Grid className="footer_grid" item md={1} sm={12} xs={12}>
            <h1 className="footer_head1">Quick Link</h1>
            <p className="footer_para">
              <Link to={`/privacy`} className="footer_link">
                Privacy Policy
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/terms_conditions`} className="footer_link">
                Terms And Conditions
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/refund&cancellationpolicy`} className="footer_link">
                Refund & Cancellation Policy
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/about`} className="footer_link">
                About us
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/contact`} className="footer_link">
                Contact us
              </Link>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={3} sm={12} xs={12}>
            <h1 className="footer_head1">Contact Us</h1>
            <a href="mailto:hello@markoknow.com">hello@markoknow.com</a>
            <p>( Usually responds within 24 hrs. )</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "0.4rem",
              }}
            >
              <Avatar
                style={{
                  background: "#6A58c6",
                }}
              >
                <BusinessIcon style={{ color: "#fff" }} />
              </Avatar>
              <p>
                Forum, Cyber City, DLF Forum, DLF Tower 10th Rd, Phase III,
                Gurugram, Haryana 122002
              </p>
            </div>
          </Grid>
          <Grid
            className="footer_grid"
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                width: "200px",
                textAlign: "center",
                fontSize: "1.8rem",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#ffffff",
              }}
            >
              Get Started
              <p
                style={{
                  color: "#ffdb66",
                  fontSize: "1rem",
                  lineHeight: "28px",
                  fontWeight: "600",
                }}
              >
                Join the Program
              </p>
            </div>
            <div
              style={{
                width: "200px",
              }}
            >
              <Link to={`/signup`}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    padding: "1rem",
                    borderRadius: "12px",
                    fontFamily: "Open Sans",
                    fontWeight: "500",
                    lineHeight: "24px",
                    fontSize: "1.3rem",
                    backgroundColor: "#432eb0",
                    textTransform: "capitalize",
                    marginTop: "8px",
                  }}
                >
                  Get started
                </Button>
              </Link>
            </div>
          </Grid>
          <hr className="solid2_mobile" />
          <div className="follow_mobile">
            <div style={{ padding: "0.7rem" }}>
              <a
                target="_blank"
                href="https://www.instagram.com/markoknow/"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/markoknow/"
                rel="noopener noreferrer"
              >
                <LinkedInIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://mobile.twitter.com/markoknow1"
                rel="noopener noreferrer"
              >
                <TwitterIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/markoknow1/"
                rel="noopener noreferrer"
              >
                <FacebookIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCXY7oCF6cZ0DjqHhTxiKCJQ"
                rel="noopener noreferrer"
              >
                <YouTubeIcon
                  className="follow_icons_mobile"
                  style={{
                    marginRight: "0rem",
                  }}
                />
              </a>
            </div>
          </div>
        </Grid>
      </MobileView>
    </div>
  );
};

export default Footer;

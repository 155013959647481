/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
// import { Box } from "@material-ui/core";
// import { isBrowser, isMobile } from "react-device-detect";
import "../../stylesheets/partnership.css";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import axios from "axios";
// import { HashLink } from "react-router-hash-link";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
require("dotenv").config();
const DOMAIN = process.env.REACT_APP_DOMAIN;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
}));

function OrganiseForm() {
  const [fullname, setFullName] = useState("");
  const [org, setOrg] = useState("");
  const [desig, setDesig] = useState("");
  const [contact, setContact] = useState("");
  const [mail, setMail] = useState("");
  const [about1, setAbout1] = useState("");
  const [about2, setAbout2] = useState("");
  const [input_val, setInputVal] = useState(Date());
  const [file, setFile] = useState(null);
  const [resp, setResp] = useState("");
  const [msg, setMsg] = useState("");

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [err1, setErr1] = useState({
    valid: true,
    msg: "",
  });
  const [err2, setErr2] = useState({
    valid: true,
    msg: "",
  });
  const [err3, setErr3] = useState({
    valid: true,
    msg: "",
  });
  const [err4, setErr4] = useState({
    valid: true,
    msg: "",
  });
  const [err5, setErr5] = useState({
    valid: true,
    msg: "",
  });
  const [err6, setErr6] = useState({
    valid: true,
    msg: "",
  });

  function validation() {
    let valid = true;

    //name
    if (!fullname.match(/^[a-zA-Z][A-Za-z\s]*$/)) {
      valid = false;
      if (fullname === "") {
        setErr1({ ...err1, valid: false });
        setErr1({ ...err1, msg: "Name cannot be empty!" });
      } else {
        setErr1({ ...err1, valid: false });
        setErr1({ ...err1, msg: "Invalid name!" });
      }
    }

    //organization
    if (!org.match(/^[a-zA-Z0-9][A-Za-z0-9\s]*$/)) {
      valid = false;
      if (org === "") {
        setErr2({ ...err2, valid: false });
        setErr2({ ...err2, msg: "Organization cannot be empty!" });
      } else {
        setErr2({ ...err2, valid: false });
        setErr2({ ...err2, msg: "Invalid Organization Name!" });
      }
    }

    //designation
    if (!desig.match(/^[a-zA-Z0-9][A-Za-z0-9\s]*$/)) {
      valid = false;
      if (desig === "") {
        setErr3({ ...err3, valid: false });
        setErr3({ ...err3, msg: "Designation cannot be empty!" });
      } else {
        setErr3({ ...err3, valid: false });
        setErr3({ ...err3, msg: "Invalid Designation!" });
      }
    }

    //phone number
    if (
      !contact.match(
        /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
      )
    ) {
      valid = false;
      if (contact === "") {
        setErr4({ ...err4, valid: false });
        setErr4({ ...err4, msg: "Phone number cannot be empty!" });
      } else {
        setErr4({ ...err4, valid: false });
        setErr4({ ...err4, msg: "Invalid Phone Number!" });
      }
    }

    //email
    if (!mail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
      valid = false;
      if (mail === "") {
        setErr5({ ...err5, valid: false });
        setErr5({ ...err5, msg: "Email cannot be empty!" });
      } else {
        setErr5({ ...err5, valid: false });
        setErr5({ ...err5, msg: "Invalid Email!" });
      }
    }

    //why
    if (!about1.match(/^[a-zA-Z0-9][A-Za-z0-9\s]*$/)) {
      valid = false;
      if (about1 === "") {
        setErr6({ ...err6, valid: false });
        setErr6({ ...err6, msg: "Event description cannot be empty!" });
      } else {
        setErr6({ ...err6, valid: false });
        setErr6({ ...err6, msg: "Invalid Event Description!" });
      }
    }

    return valid;
  }

  const clearset = () => {
    setFullName("");
    setOrg("");
    setDesig("");
    setContact("");
    setMail("");
    setAbout1("");
    setAbout2("");
    setInputVal(Date());
    setFile(null);
  };

  const onSubmit = (e) => {
    setErr1({ ...err1, valid: true });
    setErr1({ ...err1, msg: "" });
    setErr2({ ...err2, valid: true });
    setErr2({ ...err2, msg: "" });
    setErr3({ ...err3, valid: true });
    setErr3({ ...err3, msg: "" });
    setErr4({ ...err4, valid: true });
    setErr4({ ...err4, msg: "" });
    setErr5({ ...err5, valid: true });
    setErr5({ ...err5, msg: "" });
    setErr6({ ...err6, valid: true });
    setErr6({ ...err6, msg: "" });
    e.preventDefault();
    if (validation()) {
      console.log(e.target.value);
      const data = new FormData();
      // if (file === null) {
      //   setMsg("Attach descripted doc to submit application");
      //   handleOpen();
      // } else {
      data.append("FullName", fullname);
      data.append("Org", org);
      data.append("Desig", desig);
      data.append("Contact", contact);
      data.append("Mail", mail);
      data.append("About1", about1);
      data.append("About2", about2);
      data.append("file", file);
      data.append("FormType", e.target.value);
      axios
        .post(`${DOMAIN}/api/partnershipform`, data)
        .then((response) => {
          setResp(response.data);
          clearset();
          handleOpen();
        })
        .catch((err) => {
          console.log(err);
          handleOpen();
        });
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {resp === "success" ? (
        <h2 id="simple-modal-title">Thank You</h2>
      ) : (
        <h2 id="simple-modal-title">Error Occured</h2>
      )}
      {resp === "success" ? (
        <p className="simple-modal-desc" id="simple-modal-description">
          MARKOKNOW WARMLY ACCEPTS YOUR PROPOSAL FOR PARTNERING WITH US. YOU
          WOULD SOON GET A REVERT ON THE MAIL PROVIDED.
        </p>
      ) : (
        <p className="simple-modal-desc" id="simple-modal-description">
          PLEASE TRY AGAIN!!!
        </p>
      )}
      {msg.length > 0 && <p className="simple-modal-desc">{msg}</p>}
      <div className="buttons">
        <Link
          to="/dashboard"
          onClick={handleClose}
          style={{ textDecoration: "none", color: "black" }}
        >
          <button className="btn btn-primary btn-lg">Dashboard</button>
        </Link>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div className="apply_form">
        <h2 style={{ textAlign: "center" }}>Organise with Markoknow</h2>
        <form className="organise_form" onSubmit={onSubmit}>
          <div class="partnership_input">
            <div className="action_input_container">
              <input
                type="text"
                value={fullname}
                onChange={(e) => setFullName(e.target.value)}
                class="action_input"
                required
                placeholder="Full Name"
              />
              <p className="error-code">{err1.msg}</p>
            </div>
            <div className="action_input_container">
              <input
                type="text"
                value={org}
                onChange={(e) => setOrg(e.target.value)}
                class="action_input"
                required
                placeholder="Organisation"
              />
              <p className="error-code">{err2.msg}</p>
            </div>
          </div>
          <div class="partnership_input">
            <div className="action_input_container">
              <input
                type="text"
                value={desig}
                onChange={(e) => setDesig(e.target.value)}
                class="action_input"
                placeholder="Designation"
              />
              <p className="error-code">{err3.msg}</p>
            </div>
            <div className="action_input_container">
              <input
                type="contact"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                class="action_input"
                placeholder="Contact No."
              />
              <p className="error-code">{err4.msg}</p>
            </div>
          </div>
          <div class="partnership_input" style={{ flexDirection: "column" }}>
            <div className="action_input_container" style={{ flex: 1 }}>
              <input
                type="email"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                class="action_input"
                required
                placeholder="Mail ID"
              />
              <p className="error-code">{err5.msg}</p>
            </div>
            <div className="action_input_container" style={{ flex: 1 }}>
              <input
                type="text"
                value={about1}
                onChange={(e) => setAbout1(e.target.value)}
                class="action_input1"
                required
                placeholder="What event you want to Organise with use?"
              />
              <p className="error-code">{err6.msg}</p>
            </div>
            <div className="action_input_container" style={{ flex: 1 }}>
              <input
                type="text"
                value={about2}
                onChange={(e) => setAbout2(e.target.value)}
                class="action_input1"
                placeholder="Anything more"
              />
            </div>
          </div>
          <br />
          <label className="file_input">
            Upload Document:
            <input
              className="file_input"
              key={input_val}
              required
              onChange={handleFile}
              type="file"
              size="large"
            />
          </label>
          <button
            type="submit"
            value={"organise"}
            onClick={(e) => {
              onSubmit(e, "value");
            }}
            class="action_btn1"
          >
            Submit
          </button>
        </form>
        {/* <div className="arrow_detail">
          <HashLink
            className="hash_link arrow_detail_btn"
            smooth
            to={"/partnership#partner-page"}
          >
            <ArrowUpwardIcon />
          </HashLink>
        </div> */}
      </div>
    </>
  );
}

export default OrganiseForm;

import { Box } from "@material-ui/core";
import React from "react";
// import Header from '../Components/Header'
import InputBox from "../Components/InputBox";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  main: {
    height: "50vh",
    backgroundColor: "#806BD1",
    padding: 0,
    // border:'1px solid red',
    width: "100%",
  },
  "@media screen and (max-width: 480px)": {
    main: {
      backgroundColor: "#806BD1",

      padding: 0,
    },
  },
});

const CoFounderProfile = ({ setDescription }) => {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      {/* <Header/> */}
      <InputBox setDescription={setDescription} />
    </Box>
  );
};

export default CoFounderProfile;

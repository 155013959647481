import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../../stylesheets/about.css";
// import { Link } from "react-router-dom";
import { Button, makeStyles, Grid } from "@material-ui/core";
// import PeopleIcon from "@mui/icons-material/People";
// import PrintComponent from "./PrintComponent";
import Footer from "../About/Footer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import community from "./community.png";
import "./UserCertificate.css";
require("dotenv").config();

const DOMAIN = process.env.REACT_APP_DOMAIN;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  container: {
    height: "100vh",
    textAlign: "center",
    paddingTop: "50px",
    color: "white",
    backgroundColor: "#221857",
    "@media screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      height: "280px",
      flexDirection: "column",
    },
  },
  body: {
    textAlign: "center",
    marginTop: "5%",
    backgroundColor: "white",
    marginBottom: "50px",
    height: "50vh",
    color: "black",
    "@media screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      height: "50vh",
      flexDirection: "column",
    },
  },
  body_1: {
    textAlign: "center",
    height: "40vh",
    paddingTop: "50px",
    backgroundColor: "#6A58c6",
    "@media screen and (max-width: 760px)": {
      height: "70vh",
    },
    "@media screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "50px",
      height: "50vh",
    },
  },
  canvasbox: {
    height: "100%",
    width: "100%",
    backgroundColor: "yellow",
    "@media screen and (max-width: 420px)": {
      height: "100%",
      width: "100%",
    },
  },
  heading: {
    lineHeight: "60px",
    marginBottom: "5px",
    disply: "flex",
    color: "#D2EFF1",
    // fontFamily: "Garet ExtraBold",
    textAlign: "center",
    fontSize: "52px",
    fontWeight: "bold",
    width: "100%",
    // height: '600px',
    "@media only screen and (max-width: 480px)": {
      fontSize: "30px",
      marginBottom: "20px",
      flexDirection: "row",
    },
  },
  heading_main: {
    disply: "flex",
    color: "transparent",
    backgroundClip: "text",
    webkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundImage:
      "linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);",
    // fontFamily: "Garet ExtraBold",
    textAlign: "center",
    fontSize: "52px",
    fontWeight: "bold",
    width: "100%",
    // height: '600px',
    "@media only screen and (max-width: 480px)": {
      fontSize: "30px",
      marginBottom: "20px",
      flexDirection: "row",
    },
  },
  subheading: {
    lineHeight: "2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    marginTop: "20px",
    "@media only screen and (max-width: 480px)": {
      marginTop: "5px",
      marginLeft: "0px",
      marginBottom: "10px",
      width: "100%",
      textAlign: "none",
      fontSize: "15px",
    },
  },
  btn: {
    backgroundColor: "#221857",
    color: "white",
    width: "150px",
    borderRadius: "10px",
    textDecoration: "none",
    border: "none",
    padding: "12px",
    marginTop: "-20px",
  },
  btn_1: {
    textDecoration: "none",
    backgroundColor: "white",
    border: "5px solid #221857",
    fontFamily: "sans-serif",
    fontSize: "18px",
    color: "black",
    marginRight: "10px",
    padding: "10px 10px 10px 10px",
    borderRadius: "30px",
  },
  community_img: {
    width: "300px",
    height: "200px",
    "@media only screen and (max-width: 480px)": {
      width: "100px",
      height: "100px",
    },
  },
});

export default function Generator() {
  const [name, setName] = useState();
  const [pic, setPic] = useState();
  const [requestStart, setRequestStart] = useState(false);
  const classes = useStyles();
  let params = useParams();
  const canvasRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      axios
        .get(`${DOMAIN}/get_certificate/` + params.uid, {})
        .then(async (res) => {
          await setName(res.data.name);
          await setPic(res.data.image.name);
          setRequestStart(true);
        })
        .catch((e) => console.log(e));
    };
    getData();
  }, []);

  // const [communityLink, setCommunityLink] = useState("");

  // useEffect(() => {
  //   axios
  //     .get(`${DOMAIN}/updatelinks/joinCommunity/62dbcbb660c17e3670dd99e4`)
  //     .then((resp) => {
  //       setCommunityLink(resp.data.link);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // console.log(communityLink);
  //console.log("Use effect exited");
  /*console.log(name);
    console.log(pic);*/
  //const fullname=window.localStorage.getItem('name');
  //console.log(fullname);
  const userimg = `${DOMAIN}/` + `${pic}`;
  const peru = window.localStorage.getItem("certiname");
  const photo = window.localStorage.getItem("certipic");

  const putimage = async () => {
    const canvas = canvasRef.current;
    const ctx = await canvas?.getContext("2d");
    if (!ctx) return;
    let imgUrl = (await `${DOMAIN}/`) + "imageUploads/certificate-template.png";
    const image = await new Image();
    const pfp = await new Image();
    pfp.src = await userimg;
    image.crossOrigin = await "Anonymous";
    image.src = await imgUrl;
    image.useCORS = await true;
    image.onload = async function () {
      await ctx?.drawImage(image, 0, 0, 2000, 1414);
      ctx.font = await "100px Norwester";
      ctx.fillStyle = await "#fff";
      ctx.textAlign = await "center";
      await ctx.fillText(name, canvas.width / 2, 840);
      await ctx.beginPath();
      await ctx.arc(1000, 450, 200, 0, Math.PI * 2, false);
      await ctx.clip();
      await ctx.drawImage(pfp, 800, 250, 400, 400);
    };
    image.onerror = function (err) {
      console.log("err", err);
    };

    //console.log(image);
  };

  // const [printComp, setprintComp] = useState(false);

  useEffect(() => {
    putimage();
  }, [requestStart]);

  const [name1, setName1] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const MakeAllFalse = () => {
    setName1("");
    setEmail("");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name1: name1,
      email: email,
    };
    console.log(data);

    axios
      .post(`${DOMAIN}/startupShort/create`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        MakeAllFalse();
        setOpen(true);
      })
      .catch((err) => {});
  };

  return (
    // <>
    //   <div>
    //     <Button
    //       onClick={() => {
    //         setprintComp(!printComp);
    //       }}
    //     >
    //       Download
    //     </Button>
    //   </div>
    //   {printComp ? (
    //     <>
    //       <PrintComponent />
    //     </>
    //   ) : null}
    //   {/* join community */}
    //   <div style={{ textAlign: "center" }}>
    //     <a target="_blank" href={communityLink} rel="noopener noreferrer">
    //       <PeopleIcon style={{ fontSize: "5rem", color: "#5865F2" }} />
    //       <div style={{ textDecoration: "none", fontWeight: "bold" }}>
    //         JOIN COMMUNITY
    //       </div>
    //     </a>
    //   </div>
    // </>
    <>
      <div className={classes.container}>
        <div className="heading_main">Our Community</div>
        <div className={classes.subheading}>
          Join Our Amazing 25,000 community and Network and Grow to run your
          startup Successfully
        </div>
        <div className={classes.body}>
          <div>
            <div className={classes.heading} style={{ color: "black" }}>
              Join Our Whatsapp
            </div>
            <Grid container spacing={0} style={{ marginBottom: "30px" }}>
              <Grid item xs={12} lg={6} md={8}>
                <img src={community} className={classes.community_img} />
              </Grid>
              <Grid item xs={12} lg={4} md={8}>
                <ul style={{ marginTop: "75px" }}>
                  <li>Already 25,000 plus people as part of our community</li>
                  <li>Events and Amazing Sessions Broadcasted</li>
                  <li>Spam Free Groups with relevant information posted</li>
                </ul>
              </Grid>
            </Grid>
            <div>
              <Button variant="contained" className={classes.btn}>
                Join Whatsapp Community
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.body_1}>
          <div className={classes.heading}>
            <p style={{ textAlign: "center" }}>
              Subscribe to Markoknow Startup Shorts
            </p>
            <div
              className={classes.subheading}
              style={{ width: "100%", textAlign: "center" }}
            >
              To subscribe to a small crisp weekly short of Markoknow Startup
              updates,
              <br /> sign up now
            </div>
          </div>
          <div>
            <input
              type="text"
              className={classes.btn_1}
              placeholder="Name"
              value={name1}
              onChange={(e) => setName1(e.target.value)}
            />
            <input
              type="text"
              className={classes.btn_1}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <Link to={"/login"} style={{ textDecoration: "none" }}> */}
            <Button className={classes.btn} onClick={handleSubmit}>
              Submit
            </Button>
            {/* </Link> */}
          </div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Thank you for Subscribe 🎉
            </Alert>
          </Snackbar>
        </div>
        <Footer />
      </div>
    </>
  );
}

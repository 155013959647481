import React from "react";
import { useStyles } from "./style";
import { useState } from "react";
import axios from "axios";
import { Typography } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MessagePopup from "../../MessagePopup";
import { useHistory } from "react-router-dom";
const DOMAIN = process.env.REACT_APP_DOMAIN;
function PartnerWithUs() {
  const classes = useStyles();
  const [fullname, setFullname] = useState("");
  const [org, setOrg] = useState("");
  const [designation, setDesignation] = useState("");
  const [contact, setContact] = useState("");
  const [mail, setMail] = useState("");
  const [partner, setPartner] = useState("");
  const [details, setDetails] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [file, setFile] = useState(null);
  const history = useHistory();
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  
    const data = new FormData();
    data.append("fullName", fullname);
    data.append("organization", org);
    data.append("designation", designation);
    data.append("contactNo", contact);
    data.append("mailId", mail);
    data.append("MarkoKnowPartner", partner);
    data.append("details", details);
    data.append("linkedinId", linkedIn);
    if (file) {
      data.append("file", file);
    }
  
  
    axios
      .post(`${DOMAIN}/api/postPartner`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((res) => {
        alert("Message Sent");
        console.log(data);
        history.push("/");
      })
      .catch((err) => {
        if (err) {
          alert("Internal Server Error!");
          console.error(err);
        }
      });
  };
  
  
  
  // console.log(fullname)
  // console.log(org)
  // console.log(designation)
  // console.log(contact)
  // console.log(mail)
  // console.log(partner)

  return (
    <div className={classes.fullpage}>
      <div className={classes.centerdiv}>
        <MessagePopup
        />
        <Typography variant="h3" className={classes.head}>
          Partner with us.
        </Typography>
        <Typography variant="h6" className={classes.bottomline}>
          Be the Partner with Markoknow by sponsoring our events.
        </Typography>
        <div className={classes.formdiv}>
          <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.rowinput}>
              <div className={classes.inputcontainer}>
                <input
                  type="text"
                  onChange={(event) => setFullname(event.target.value)}
                  class={classes.input_box}
                  required
                  placeholder="Full Name"
                />
              </div>
              <div className={classes.inputcontainer}>
                <input
                  type="text"
                  onChange={(event) => setOrg(event.target.value)}
                  class={classes.input_box}
                  required
                  placeholder="Organisation"
                />
              </div>
            </div>

            <div className={classes.rowinput}>
              <div className={classes.inputcontainer}>
                <input
                  type="text"
                  onChange={(event) => setDesignation(event.target.value)}
                  class={classes.input_box}
                  required
                  placeholder="Designation"
                />
              </div>
              <div className={classes.inputcontainer}>
                <input
                  type="text"
                  onChange={(event) => setContact(event.target.value)}
                  class={classes.input_box}
                  required
                  placeholder="Contact No."
                />
              </div>
            </div>

            <div className={classes.rowinput}>
              <input
                type="text"
                onChange={(event) => setMail(event.target.value)}
                class={classes.input_box2}
                required
                placeholder="Mail ID"
              />
            </div>

            <div className={classes.rowinput}>
              <div className={classes.input_box3}>
                <h8 className={classes.dropdown_label}>MarkoKnow Partner</h8>
                <Select
                  value={partner}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(event) => setPartner(event.target.value)}
                  className={classes.dropdown}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Markoknow Mentor"}>
                    Markoknow Mentor
                  </MenuItem>
                  <MenuItem value={"Markoknow Student Partner"}>
                    Markoknow Student Partner
                  </MenuItem>
                  <MenuItem value={"Markoknow Workshop Partner"}>
                    Markoknow Workshop Partner
                  </MenuItem>
                  <MenuItem value={"Markoknow Service Partner"}>
                    Markoknow Service Partner
                  </MenuItem>
                  <MenuItem value={"Markoknow Investor Partner"}>
                    Markoknow Investor Partner
                  </MenuItem>
                </Select>
              </div>
            </div>

            <div className={classes.rowinput}>
              <input
                type="text"
                onChange={(event) => setDetails(event.target.value)}
                class={classes.input_box2}
                required
                placeholder="Details"
              />
            </div>

            <div className={classes.rowinput}>
              <input
                type="text"
                onChange={(event) => setLinkedIn(event.target.value)}
                class={classes.input_box2}
                required
                placeholder="LinkedIn ID"
              />
            </div>

            <Typography
              style={{
                textAlign: "left",
                marginLeft: "20px",
                fontSize: "20px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Upload Proposal or Doccument
            </Typography>
            {/* <label for="file">Choose file to upload</label> */}
            <div className={classes.rowinput}>
              <input
                type="file"
                class={classes.input_file}
                required
                placeholder="Upload Proposal or Doccument"
                size="large"
                name="file"
                onChange={handleFile}
              />
            </div>
            <div className={classes.rowinput}>
              <button type="submit" className={classes.submitbtn}>
                Submit
              </button>
            </div>
          </form>{" "}
          {/*form ends*/}
        </div>{" "}
        {/* formdiv ends */}
      </div>
      {/* centerdiv ends */}
    </div>
  );
}

export default PartnerWithUs;

import React from "react";
import { Typography } from "@material-ui/core";
// import { useState } from 'react'
// import { Link } from 'react-router-dom'
import { useStyles } from "./style";
import ProfileCard from "./profilecard";

function CofounderMatch({ location }) {
  const classes = useStyles();
  console.log(location.state.data);
  return (
    <div className={classes.fullpage}>
      <div className={classes.design2}></div>
      <Typography variant="h2" className={classes.heading}>
        Co Founder's Match
      </Typography>
      <div className={classes.design}></div>

      <Typography variant="h6" className={classes.bottomline}>
        Get the best matching Co Founder profile to connect and try your
        Startup.
      </Typography>
      <ProfileCard data={location.state.data} />
    </div>
  );
}

export default CofounderMatch;

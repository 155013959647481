import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , height:'70vh'}}>
      <CircularProgress style={{ color: '#fff' }} />
    </div>
  );
};

export default Loader;
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles({
    fullpage : {
        // margin: '0px',
        backgroundColor : '#806BD0',
        height :'50vh',
        width:'100%',
        overflow:'hidden',
        // position : 'fixed'
    },
    design : { 
        display: 'inline',
        float: 'right',
        width: '400px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '60px',
        position: 'absolute',
        top : '0px',
        right: '0px',
        borderRadius: '60px 0px 0px 60px'
    },
    design2 : { 
        display: 'inline',
        float: 'right',
        width: '200px',
        backgroundColor: '#85A4D0',
        border : 'none',
        height: '40px',
        position: 'absolute',
        top: '30px',
        borderRadius: '0px 20px 20px 0px'
    },
    heading: {
        textAlign:'center',
        color:'white',
        fontSize:'3vw',
        fontWeight:'bold',
        fontStyle:'roboto',
        marginTop: '50px',
        // padding: '15px',
    },
    bottomline:{
        color: 'white',
        textAlign: 'center',
        padding : '10px',
    },
    innerdiv : {
        width : '80%',
        height : '200px',
        justifyContent : 'center',
        alignItems : 'center',
        display : 'flex',
        margin : 'auto',
    },
    curvediv : { 
        height : '100px',
        width : '40%',
        border : 'none',
        backgroundColor : 'white',
        borderRadius : '62px',
        margin : '20px',
        fontSize : '1.5vw',
        fontWeight : 'bold',
        textAlign : 'center',
        justifyContent :'center',
        alignItems : 'center',
        padding : '10px',
        cursor : 'pointer',
        outline : 'none',
        transition : '0.5s',
        '&:hover' :  {
            backgroundColor:'black',
            color : 'white'
        }
    },
    nextbtn : {
        width : '55%',
        height : '60px',
        backgroundColor : 'cyan',
        textAlign : 'center',
        border : 'none',
        fontSize :'2.0vw',
        fontWeight :'bold',
        cursor : 'pointer'
    },
    '@media(min-width: 1000px) and (max-width: 1200px)': {
        fullpage: {
            overflow: 'scroll',
            marginTop: '0px',
        },
        design: {
            width: '20%',
            height: '40px',
            opacity : '0.5'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.5',
        },
        curvediv : {
            borderRadius : '20px'
        },
    },

    '@media(min-width: 700px) and (max-width: 1000px)' : {
        fullpage :{
            overflow: 'scroll',
            marginTop: '0px'
        },
        design: {
            width: '25%',
            height: '40px',
            opacity : '0.5'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.5',
        },
        curvediv : {
            borderRadius : '20px',
            fontSize : '3vw'
        }
    },

    '@media(max-width: 700px)' : {
        fullpage :{
            overflow: 'scroll',
            marginTop: '0'
        },
        design: {
            width: '8%',
            height: '40px',
            opacity : '0.2'
        },
        design2: {
            width: '10%',
            height: '30px',
            opacity : '0.2',
        },
        innerdiv : {
            width : '90%'
        },
        curvediv : {
            borderRadius : '30px',
            width : '90%',
            margin : '0',
            marginLeft: '10px',
            fontSize : '3.5vw'
        },
        nextbtn : {
            fontSize : '4vw'
        },
        heading : {
            marginTop: '20px',
            fontSize : '8vw'
        },
        bottomline : {
            fontSize : '4vw'
        }
    }
})

export {useStyles}